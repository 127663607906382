import React, {useEffect, useRef, useState} from 'react'
import {Input, Icon, Spin, Tooltip, Drawer, Avatar, TableComp} from '../../../components/Elements/appUtils'
import {
    InputBox
} from '../../../components/_utils/appUtils'
import {campusIntakesListFxn, canadaOnshoreManagerFxn} from '../actions'
import {connect, useDispatch} from 'react-redux'
import Styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
import {UniversityEligibility} from '../../university/drawers/universityEligibility'
import {getSingleCourse} from '../../course/actions/courseAction'
import AddApplications from '../../applications/views/add'
import OnshoreAddStudent from '../../onShoreCanada/drawers/onshoreAddStudent'
import HelpComponent from '../components/helpComponent'
import RequestACallBack from '../requestCallBack/requestACallBack'
import {CanadaOnshoreListDetails} from '../../university/drawers/CanadaOnshoreListDetails'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const antIcon = <Icon type='loading' style={{fontSize: 40}} spin/>
let initState = {
    totalCourses: 0,
    currentPage: 1,
    count: 50,
    status: 'Open',
    isLoading: false
}
let iniStuState = {
    visible: false
}

const useDebouncedApiCall = (apiCall, delay) => {
    const [debouncedApiCall, setDebouncedApiCall] = useState(null)

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedApiCall(apiCall)
            }, delay)

            return () => {
                clearTimeout(handler)
            }
        },
        [apiCall, delay]
    )

    return debouncedApiCall
}

let ManagerBoxComponent = props => {
    let {user, events, currentUser} = props
    return (
        <>
            <div className={'marketingUserBox managerBoxCanada'}>
                <div className={'jcsb'}>
                    <div className={'managerHead'}>
            <span style={{marginLeft: 'auto', fontSize: 14}}>
              DESIGNATED MANAGER
            </span>
                    </div>
                    {currentUser.userType == 'agent' ? (
                        <a
                            className={'help_btn btn btn-default roundNew sm aic'}
                            onClick={events.showMessage}>
                            <Icon type={'mail'}/> Message
                        </a>
                    ) : null}
                </div>
                <div className={'managerBody'}>
                    <div className={'center'} style={{paddingRight: 10}}>
                        <Avatar
                            size={60}
                            style={{
                                border: `3px solid #ad2125`,
                                marginLeft: 15,
                                marginRight: 15,
                                left: 0
                            }}
                            src={
                                user && user.logo && user.logo.url
                                    ? user.logo.url
                                    : '../dist/img/user.png'
                            }
                        />
                    </div>
                    <div className={'jcsb'}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: 5,
                                fontSize: 12,
                                color: '#585858'
                            }}>
                            <div>
                                <div className={'mt5'}>
                                    <Icon type={'user'}/>
                                    &nbsp;
                                    {user.name}
                                </div>
                                <Icon type={'mail'}/>
                                &nbsp; {user.email}
                                <br/>
                                <Avatar size={15} src={'../dist/img/whatsapp.png'}/>
                                &nbsp;
                                {user.countryCode} {user.mobile}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

let OnshoreCanadaProgramsComponent = props => {
    let {countryName, currentUser} = props
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    let [countryId, setCountryId] = useState(props.countryId)
    let tableRef = useRef()
    let [stuState, setStuState] = useState(iniStuState)
    let [managerData, setManagerData] = useState({})

    let [eligDrawer, setEligDrawer] = useState({
        universityId: '',
        universityName: '',
        visible: false
    })
    let [canadaOnshoreList, setCanadaOnshoreList] = useState(false)
    let [courseState, setCourseState] = useState({
        visible: false,
        selectedCourse: {}
    })

    let [helpVisible, setHelpVisible] = useState(false)
    let [RequestACallVisible, setRequestACallModal] = useState(false)

    let loadCanadaOnshoreManager = async () => {
        let {data, success} = await canadaOnshoreManagerFxn()
        setManagerData(data)
    }

    useEffect(() => {
            loadCanadaOnshoreManager()
        }, [countryId]
    )

    let events = {
        _updateState: data => {
            setState(prevState => {
                return {
                    ...prevState,
                    ...data,
                    currentPage: 1
                }
            })
        },
        refreshTable: () => {
            if (tableRef && tableRef.current && tableRef.current.reload) {
                tableRef.current.reload()
            }
        },
        hideEligDrawer: () => {
            setEligDrawer({
                universityId: '',
                universityName: '',
                visible: false
            })
        },
        addApplicationDrawer: async obj => {
            let {data} = await dispatch(getSingleCourse(obj._id))
            let selectedCourse = {
                _id: data._id,
                tuitionFee: data.tuitionFee,
                courseUniversity: data.courseUniversity._id,
                courseName: data.courseName,
                courseDuration: data.courseDuration,
                courseLevel: data.courseLevel,
                universityCountry: data.universityCountry._id,
                universityState: data.universityState._id,
                universityCity: data.universityCity,
                commission: 0,
                applicationFee:
                    data.courseUniversity && data.courseUniversity.applicationFee
                        ? data.courseUniversity.applicationFee
                        : 0,
                universityName: data.courseUniversity.universityName,
                countryName: data.universityCountry.countryName,
                courseId: data._id,
                campusIntakes: obj.campusIntakes,
                campusId: obj.campusIntakes.campusId,
                month: obj.campusIntakes.month,
                year: obj.campusIntakes.year
            }
            setCourseState({
                visible: true,
                selectedCourse: selectedCourse
            })
        },
        hideStudentDrawer: () => {
            setCourseState({
                visible: false,
                selectedCourse: {}
            })
        },
        showMessage: () => {
            setHelpVisible(true)
        },
        showRequestACall: () => {
            setRequestACallModal(true)
        },
        showListDrawer: () => {
            setCanadaOnshoreList(true)
        },
        hideListDrawer: () => {
            setStuState(prevState => {
                return {
                    ...prevState,
                    visible: false
                }
            })
        }
    }

    let stuEvents = {
        showAddStuDrawer: () => {
            setStuState(prevState => {
                return {
                    ...prevState,
                    visible: true
                }
            })
        },
        hideAddStuDrawer: () => {
            setStuState(prevState => {
                return {
                    ...prevState,
                    visible: false
                }
            })
        }
    }

    /*  const debouncedApiCall = useDebouncedApiCall(() => {
        // Make the API call here
        events.refreshTable()
      }, 1500)*/

    useEffect(() => {
        events._updateState({isLoading: true})
        const timer = setTimeout(() => {
            events.refreshTable()
        }, 1000)
        return () => clearTimeout(timer)
    }, [state.courseName, state.universityName, state.campusName, state.intake, state.status])

    let apiRequest = params => {
        return new Promise(async resolve => {
            let obj = {
                courseName: state.courseName,
                universityName: state.universityName,
                campusName: state.campusName,
                intake: state.intake,
                status: state.status,
                countryId: countryId
                // page: state.currentPage
            }
            params = {...params, ...obj}
            let resp1 = await campusIntakesListFxn({
                ...params,
                regExFilters: [
                    'courseName',
                    'courseDuration',
                    'courseLevel',
                    'tuitionFee',
                    'universityName'
                ]
            })
            resolve(resp1)

            setTimeout(() => {
                events._updateState({
                    totalCourses: resp1.total ? resp1.total : 0,
                    currentPage: params.page,
                    isLoading: false
                })
            }, 100)
        })
    }

    useEffect(() => {
    }, [
        state.courseName,
        state.universityName,
        state.campusName,
        state.intake,
        state.status
    ])

    let columns = [
        {
            key: '_id',
            title: '#',
            dataIndex: '_id',
            render: (item, record, index) => {
                return <>{state.count * (state.currentPage - 1) + (index + 1)}</>
            }
        },
        {
            key: 'courseName',
            title: 'Program',
            dataIndex: 'courseName'
        },
        {
            key: 'universityName',
            title: 'University',
            dataIndex: 'universityName',
            render: (item, record) => {
                let {campusIntakes} = record
                return (
                    <>
                        {item}
                        {campusIntakes && campusIntakes.updateDate ? (
                            <div className={'mt5'}>
                                <label
                                    className={'label label-default label-xs'}
                                    style={{fontSize: 11}}>
                                    Last Update : {moment(campusIntakes.updateDate).fromNow()}
                                </label>
                            </div>
                        ) : null}
                    </>
                )
            }
        },
        {
            key: 'campusIntakes.campusName',
            title: 'Campus',
            dataIndex: 'campusIntakes.campusName'
        },
        {
            key: 'campusIntakes',
            title: 'Program',
            dataIndex: 'campusIntakes',
            render: item => {
                return (
                    <>
                        {item.month ? item.month : ''}
                        {item.year ? `, ${item.year}` : ''}
                    </>
                )
            }
        },
        {
            key: 'campusIntakes.status',
            title: 'status',
            dataIndex: 'campusIntakes.status',
            render: item => {
                return <div style={{textTransform: 'uppercase'}}>{item}</div>
            }
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'action',
            render: (item, record) => {
                let {courseUniversity, universityName} = record
                return (
                    <div style={{width: 150}}>
                        <div>
                            <Tooltip title={'View Eligibility'}>
                                <a
                                    className={'btn btn-default btn-xs'}
                                    onClick={() => {
                                        setEligDrawer({
                                            universityId: courseUniversity,
                                            universityName: universityName,
                                            visible: true
                                        })
                                    }}>
                                    <i className={'fa fa-eye'}/> Eligibility
                                </a>
                            </Tooltip>
                            {/* <Tooltip title={'View List'}>
                <a className={'btn btn-default btn-xs'}
                   onClick={() => {
                     setCanadaOnshoreList({

                       visible: true
                     })
                   }}>
                  <i className={'fa fa-eye'}></i> List
                </a>
              </Tooltip> */}
                        </div>
                        <div className={'mt5'}>
                            {user.userType !== 'student' && (
                                <Tooltip title={'Add Application'}>
                                    <a
                                        className={'btn btn-default btn-xs'}
                                        onClick={() => events.addApplicationDrawer(record)}>
                                        <i className={'fa fa-plus'}/> Add Application
                                    </a>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>
                                {currentUser.userType != 'student' ? (
                                    <a
                                        className={'btn btn-default roundNew'}
                                        onClick={stuEvents.showAddStuDrawer}>
                                        <img
                                            src='/dist/icons/plus.png'
                                            alt='Canada Onshore'
                                            style={{marginRight: 10, width: 15, height: 20}}
                                        />
                                        Add student in Canada onshore
                                    </a>
                                ) : null}

                                <div className={'mt5'}>
                                    {user.userType === 'agent' && (
                                        <a className={'btn btn-default roundNew'}
                                           onClick={events.showListDrawer}>
                                            <LazyLoadImage
                                                src='/images/commission.png'
                                                alt=''
                                                style={{marginRight: 10, width: 20, height: 20}}
                                            />
                                            Canada Onshore Commissions
                                        </a>
                                    )}
                                </div>
                            </h5>

                            <div className='search-box-table' style={{padding: 0}}>
                                {managerData && managerData._id ? (
                                    <ManagerBoxComponent
                                        events={events}
                                        currentUser={currentUser}
                                        user={managerData}
                                    />
                                ) : null}
                            </div>
                            <div className='sort-box-table mark-btn alignRight'>
                                Inside Canada - Open / Close Courses
                                {currentUser.userType == 'agent' ? (
                                    <a
                                        className={'btn btn-default roundNew mt10 w-50'}
                                        onClick={events.showRequestACall}>
                                        <Icon type={'phone'}/>
                                        &nbsp; Request A Call Back
                                    </a>
                                ) : null}
                            </div>

                        </div>


                        <div className={`card-body table-responsive ${Styles.table}`}>
                            <table className={'table table-bordered'}>
                                <tr>
                                    <th>#</th>
                                    <th>Program</th>
                                    <th>University</th>
                                    <th>Campus</th>
                                    <th>Intake</th>
                                    <th>Status</th>
                                    <th>
                                        <div style={{width: 150}}>Action</div>
                                    </th>
                                </tr>
                                <tr className={Styles.bgRow}>
                                    <td/>
                                    <td>
                                        <InputBox
                                            customClass={Styles.single_column}
                                            title={'Search by course name'}>
                                            <Input
                                                value={state.courseName}
                                                placeholder={'Course Name'}
                                                onChange={({target}) => {
                                                    events._updateState({courseName: target.value})
                                                }}
                                            />
                                        </InputBox>
                                    </td>
                                    <td>
                                        <InputBox
                                            customClass={Styles.single_column}
                                            title={'Search by university name'}>
                                            <Input
                                                value={state.universityName}
                                                placeholder={'University Name'}
                                                onChange={({target}) => {
                                                    events._updateState({universityName: target.value})
                                                }}
                                            />
                                        </InputBox>
                                    </td>
                                    <td>
                                        <InputBox
                                            customClass={Styles.single_column}
                                            title={'Search by campus'}>
                                            <Input
                                                value={state.campusName}
                                                placeholder={'Campus'}
                                                onChange={({target}) => {
                                                    events._updateState({campusName: target.value})
                                                }}
                                            />
                                        </InputBox>
                                    </td>
                                    <td>
                                        <InputBox
                                            customClass={Styles.single_column}
                                            title={'Search by intake'}>
                                            <Input
                                                value={state.intake}
                                                placeholder={'Intake'}
                                                onChange={({target}) => {
                                                    events._updateState({intake: target.value})
                                                }}
                                            />
                                        </InputBox>
                                    </td>
                                    <td>
                                        <InputBox
                                            customClass={Styles.single_column}
                                            title={'Search by status'}>
                                            <select
                                                value={state.status}
                                                className={'ant-input'}
                                                onChange={({target}) => {
                                                    events._updateState({status: target.value})
                                                }}>
                                                <option value={'Open'}>Open</option>
                                                <option value={'Close'}>Close</option>
                                            </select>
                                        </InputBox>
                                    </td>
                                    <td style={{width: 200}}/>
                                </tr>
                            </table>

                            {state.isLoading ? (
                                <div className={Styles.loader}>
                                    <Spin indicator={antIcon}/>
                                    <br/>
                                    &nbsp;&nbsp;<h3>Loading</h3>
                                </div>
                            ) : null}

                            {/*key={state.totalCourses}*/}

                            <div className={Styles.hideHead} key={state.totalCourses}>
                                <TableComp
                                    rowKey={(item, index) => {
                                        return item._id
                                    }}
                                    bordered={true}
                                    apiRequest={apiRequest}
                                    ref={tableRef}
                                    columns={columns}
                                    pagination={{
                                        position: 'top',
                                        defaultPageSize: 20,
                                        pageSizeOptions: ['20', '50', '100']
                                        // position: "bottom"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {eligDrawer.visible ? (
                <UniversityEligibility
                    visible={eligDrawer.visible}
                    onClose={() => events.hideEligDrawer()}
                    universityId={eligDrawer.universityId}
                />
            ) : null}
            {/* {canadaOnshoreList.visible ?
        <CanadaOnshoreListDetails visible={canadaOnshoreList.visible}
        onClose={() => events.hideListDrawer()}



        />
        : null} */}
            {canadaOnshoreList ? (
                <CanadaOnshoreListDetails
                    visible={canadaOnshoreList}
                    onClose={() => {
                        setCanadaOnshoreList(false)
                    }}
                />
            ) : null}

            {courseState.visible ? (
                <AddApplications
                    visible={courseState.visible}
                    showAddStudent={false}
                    selectedCourse={courseState.selectedCourse}
                    closeStudentDrawer={events.hideStudentDrawer}
                />
            ) : null}

            {stuState.visible ? (
                <Drawer
                    visible={stuState.visible}
                    title={`Apply in ${countryName}`}
                    width={window.innerWidth > 1800 ? 1500 : '60%'}
                    closable={false}
                    onClose={stuEvents.hideAddStuDrawer}>
                    <OnshoreAddStudent callBackFxn={() => {
                    }}/>
                </Drawer>
            ) : null}

            {helpVisible ? (
                <HelpComponent
                    visible={helpVisible}
                    manager={managerData}
                    currentUser={currentUser}
                    onClose={() => {
                        setHelpVisible(false)
                    }}
                />
            ) : null}

            {RequestACallVisible ? (
                <RequestACallBack
                    visible={RequestACallVisible}
                    manager={managerData}
                    currentUser={currentUser}
                    onClose={() => {
                        setRequestACallModal(false)
                    }}
                />
            ) : null}
        </>
    )
}

export default OnshoreCanadaProgramsComponent
