export default (
    state = {
        loader: false,
        student: {},
        allStudents: [],
        totalStudents: 0,
        balance: 0,
        withdrawals: [],
    },
    action
) => {
    switch (action.type) {
        case 'STUDENT_SHOW_LOADER':
            return {...state, loader: true};
        case 'STUDENT_HIDE_LOADER':
            return {...state, loader: false};
        case 'LIST_STUDENT':
            return {...state, student: action.payload.data};
        case 'ADD_STUDENT':
            return {...state, addedCity: action.payload};
        case 'LIST_ALL_STUDENT':
            return {
                ...state,
                allStudents: action.payload.data,
                totalStudents: action.payload.total,
            };
        case 'EMPTY_STUDENT_LIST':
            return {...state, allStudents: [], totalStudents: 0};
        case 'SET_STUDENT_BALANCE':
            // console.log(actions.payload , "----------------------")
            return {
                ...state,
                balance: action.payload,
            };
        case 'LIST_WITHDRAWALS':
            console.log(action.payload.data, "-----------list-----------")
            return {
                ...state,
                withdrawals: action.payload.data,
            };
        default:
            return state;
    }
};
