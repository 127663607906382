import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Select,
    Button,
    Col,
    Row,
    TextArea
} from '../../../../components/Elements/appUtils'
import _ from 'lodash'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../../modules/actions'
import {connect} from 'react-redux'
import {
    InputBox, CounsellorTypeList
} from '../../../../components/_utils/appUtils'
import GetEachFormFields from '../../../../components/_utils/appFormUtils'
import {getUser, listAllUsers, updateUser} from '../../actions/user'
import {
    CountryCodeWithFlag,
} from '../../../../components/_utils/countryUtil'
import {listAllCountries} from "../../../countries/actions/countries";


class EditCounsellor extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            allBranch: [],
            universityList: [],
            countryList: [],
            stateList: [],
            mainBranchArr: [],
            logo: {},
            uploadKey: moment(),
            agentList: [],
            agents: [],
            assignStateCountry: [],
            countryCode: '',
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        this.getUserData()
        this.loadCountry()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await listAllCountries(countryFilter)(dispatch)
        this.setState({
            countryList: data
        })
    }

    // async loadManagers(countryId) {
    //     let {dispatch} = this.props
    //     let {data} = await listAllUsers({userType: 'branchManager', countryId})(dispatch)
    //     this.setState({
    //         mainBranchArr: data
    //     })
    //     this.setState({
    //         mainBranchArr: data
    //     })
    // }

    // Edit Counselling
    async getUserData() {
        let {
            dispatch,
            form: {setFieldsValue}
        } = this.props
        let {pathname} = window.location
        let params = pathname.split('/').pop()
        if (params && params.length) {
            let resp = await dispatch(getUser(params))
            if (resp) {
                this.setState({
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    agents: resp.agents,
                    email: resp.email,
                    createdByUserIdName: resp.createdByUser
                        ? resp.createdByUser.name
                        : '',
                    countryCode: resp.countryISOCode
                })
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    email: resp.email,
                    userType: resp.userType,
                    address: resp.address,
                    universities: resp.universities,
                    // branchMangerId: resp.branchMangerId,
                    postName: resp.postName,
                    counsellorType: resp.counsellorType,
                    countryArr: resp.countryArr
                })
                // if (resp.countryId && resp.countryId._id) {
                //     this.loadManagers(resp.countryId._id)
                // }

                console.log(resp.countryArr, 'arr', resp.countryId, 'id')
            }
        }
    }

    handleSubmit = e => {
        let {address, logo} = this.state
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.address = address
                let fd = new FormData()
                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }

                fd.append(this.state.countryKey, this.state.countryArr);

                let data = await dispatch(updateUser(fd, this.state.userId))
            }
        })
    }


    render() {
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }
        const counsellorTypeValue = getFieldValue('counsellorType');

        let countryMode = 'default';
        if (counsellorTypeValue === 'Counsellor') {
            countryMode = 'multiple';
        }


        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    span: 3,
                    customField: (
                        <div style={{marginTop: '3px'}}>
                            <CountryCodeWithFlag
                                countryCode={this.state.countryCode}
                                chooseCode={val => {
                                    this.setState({
                                        countryCode: val
                                    })
                                }}
                            />
                        </div>
                    )
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    span: 5,
                    type: 'number',
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Post Name',
                    key: 'postName',
                    required: true
                },
                // {
                //   label: 'Country',
                //   key: 'countryId',
                //   // key: 'country',
                //   type: 'select',
                //   // mode: 'multiple',
                //   span: 8,
                //   required: true,
                //   showSearch: true,
                //   allowClear: true,
                //   options: this.state.countryList,
                //   valueAccessor: x => x.countryName,
                //   keyAccessor: x => x._id,
                //   onChange: async v => {
                //       setFieldsValue({
                //           countryId: v,
                //           branchMangerId: ""
                //       })
                //       this.loadManagers(v)
                //   }
                // },
                // {
                //   label: 'Branch Manger',
                //   key: 'branchMangerId',
                //   required: true,
                //   type: 'select',
                //   valueAccessor: x => x.name,
                //   keyAccessor: x => x._id,
                //   options: this.state.mainBranchArr,
                //   onChange: v => {
                //     setFieldsValue({
                //       branchMangerId: v
                //     })
                //   }
                // },
                {
                    label: 'Counsellor Type',
                    key: 'counsellorType',
                    type: 'select',
                    required: true,
                    span: 8,
                    showSearch: true,
                    allowClear: true,
                    options: CounsellorTypeList,
                    valueAccessor: x => x.counsellorType,
                    keyAccessor: x => x._id,
                    onChange: async v => {
                        setFieldsValue({
                            counsellorType: v
                            // branchMangerId: ""
                        });
                        // this.loadManagers(v);
                        this.setState({counsellorType: v})
                    }
                },


                {
                    label: 'Country',
                    key: 'countryArr',
                    type: 'select',
                    mode: 'multiple',
                    span: 8,
                    required: true,
                    showSearch: true,
                    allowClear: true,
                    options: this.state.countryList,
                    valueAccessor: x => x.countryName,
                    keyAccessor: x => x._id,
                    onChange: async v => {
                        setFieldsValue({
                            countryArr: v
                        });
                    }
                },


                {
                    key: 'logo',
                    customField: (
                        <InputBox title={'Logo'} className={'logoCls'}>
                            <Input
                                type={'file'}
                                className={'form-control'}
                                key={this.state.uploadKey}
                                name={'logo'}
                                id={'logo'}
                                onChange={e => {
                                    this.setState({
                                        logo:
                                            e.target.files && e.target.files.length
                                                ? e.target.files[0]
                                                : null
                                    })
                                }}
                            />
                        </InputBox>
                    )
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea
                                rows={4}
                                className={'form-control'}
                                value={this.state.address}
                                onChange={e => {
                                    this.setState({address: e.target.value})
                                }}
                            />
                        </InputBox>
                    )
                }
            ]
        }

        return (
            <div className="row">
                <div className="col-lg-12">
                    <Form onSubmit={this.handleSubmit}>
                        <div className="form-box mt-4">
                            <div className="d-flex align-items-center mb-3 heading-form">
                                <h5>Edit Counselling</h5>
                            </div>

                            <div className="card unizportal">
                                <Row gutter={16} className={'rowWrap'}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                {item.customField
                                                    ? !item.hidden && (
                                                    <Col span={item.span ? item.span : 8}>
                                                        {item.customField}
                                                    </Col>
                                                )
                                                    : !item.hidden && (
                                                    <Col
                                                        span={item.span ? item.span : 8}
                                                        key={key}
                                                        style={
                                                            item.extraBox1 ? {paddingBottom: 1} : {}
                                                        }>
                                                        <InputBox
                                                            title={item.label1 ? item.label1 : ''}
                                                            extra={item.extraBox1}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}
                                                            />
                                                        </InputBox>
                                                    </Col>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </Row>


                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={this.props.loading}
                                        className={'btn'}>
                                        SAVE
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedEditCounsellor = Form.create()(EditCounsellor)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedEditCounsellor)
