export default (
    state = {
        loader: false,
        branch: {},
        allBranch: [],
        totalBranch: 0,
    },
    action
) => {
    switch (action.type) {
        case 'BRANCH_SHOW_LOADER':
            return {...state, loader: true};
        case 'BRANCH_HIDE_LOADER':
            return {...state, loader: false};
        case 'BRANCH_CARD_SHOW_LOADER':
            return {...state, cardLoader: true};
        case 'BRANCH_CARD_HIDE_LOADER':
            return {...state, cardLoader: false};
        case 'LIST_BRANCH':
            return {...state, branch: action.payload.data};
        case 'ADD_BRANCH':
            return {...state, addedCampus: action.payload};
        case 'LIST_ALL_BRANCH':
            return {...state, allBranch: action.payload.data, totalBranch: action.payload.total}
        default:
            return state
    }
}
