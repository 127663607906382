import React from 'react'
import {
    Form, Icon, Input, Button, Checkbox, notification, Col, Row
} from '../../components/Elements/appUtils'

import { showLoader, hideLoader, showPageLoad, hidePageLoad } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import { InputBox } from '../../components/_utils/appUtils'
import { checkCertificationToken, uploadCertificate } from '../users/actions/user'

class UploadCertificateForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      certificate: {},
      certificateToken: '',
      profileKey: moment()
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
  }


  async checkIsLoggedIn() {
    const { dispatch } = this.props
    let { pathname } = window.location
    let token = pathname.split('/').pop()
    let resp = await dispatch(checkCertificationToken({ certificateToken: token }))
    if (resp && resp.success) {
      this.setState({ certificateToken: token })
    } else {
      alert(resp.message)
      dispatch(push('/login'))
    }
  }

  handleSubmit = async () => {
    let { certificate, certificateToken } = this.state
    const { dispatch } = this.props
    if (!certificate || (certificate && !certificate.name)) {
      notification.warning({
        message: 'Required',
        description: 'Please choose certificate.'
      })
      return
    }
    let fd = new FormData()
    let obj = { certificateToken }
    fd.append('obj', JSON.stringify(obj))
    if (certificate && certificate.name) {
      fd.append('certificate', certificate)
    }
    let resp = await dispatch(uploadCertificate(fd))
    if (resp && resp.success) {
      alert(resp.message)
      this.setState({
        profileKey: moment(),
        certificate: '',
        certificateToken: ''
      })
      window.location.reload()
      // dispatch(push('/login'))
    }
  }


  chooseProfileDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ certificate: files[0] })
    }
  }


  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { loading, dispatch } = this.props
    const { state } = this

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }
    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=" w-100 navbar-fix">

            <div className="container">
              <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                  <a onClick={() => dispatch(push('/home'))}>
                    <img src="../dist/img/AdminLTELogo.png" alt=""/>
                  </a>
                </h5>
                <nav className="my-2 my-md-0 d-flex align-items-center newNav">
                  <a className="p-2 text-dark" onClick={() => dispatch(push('/home'))}>Home</a>
                  <a className="p-2 text-dark" onClick={() => dispatch(push('/login'))}>Login</a>
                </nav>
              </div>
            </div>

          </header>
          <div className="login-form w-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mr-auto">
                  <div className="login-box w-100">
                    <div>
                      <h5>Upload Certificate</h5>
                      <Form>
                        <InputBox title={`Certificate`}>
                          <Input type={'file'} name={'certificate'} id={'certificate'}
                                 // accept="image/x-png,image/jpeg,,image/jpg"
                                 key={state.profileKey}
                                 onChange={(e) => {
                                   this.chooseProfileDocument(e)
                                 }}/>
                        </InputBox>
                        <Form.Item className={'mt20'}>
                          <Button type="primary" onClick={() => this.handleSubmit()}>
                            Upload Certificate
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedUploadCertificateForm = Form.create()(UploadCertificateForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  search: global.search,
  pathname: global.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedUploadCertificateForm)
