import React, {useState, useEffect} from 'react'
import {notification, Input, Card, Drawer, Button} from '../../../components/Elements/appUtils'
import {InputBox} from '../../../components/_utils/appUtils'
import {updatePassword} from '../actions/user'

const ChangePasswordByAdmin = (props) => {
    let {userData, visible, onClose} = props
    let [password, setPassword] = useState('')
    let [confirmPassword, setConfirmPassword] = useState('')

    const handleSubmit = async () => {
        if (!password) {
            return notification.warn({message: 'Enter password.'})
        }
        if (password.length < 6) {
            return notification.warn({message: 'Enter minimum 6 digit password.'})
        }
        if (!confirmPassword) {
            return notification.warn({message: 'Enter confirm password.'})
        }
        if (password !== confirmPassword) {
            return notification.warn({message: 'Password not match.'})
        }
        let resp = await updatePassword({userId: userData._id, password})
        if (resp.success) {
            notification.success({message: 'Password changed successfully'})
            onClose()
        } else {
            notification.error({message: 'Password not changed'})
        }

    }
    return (
        <Drawer
            title={`Change Password for ${userData.name}`}
            visible={visible}
            placement="right"
            closable={true}
            width={'40%'}
            onClose={onClose}
        >
            <Card>
                <InputBox title={'New Password'}>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} placeholder={'New Password'}
                           className='form-control'
                    />
                </InputBox>

                <InputBox title={'Confirm Password'}>
                    <Input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                           placeholder={'Confirm Password'} className='form-control'/>
                </InputBox>
                <InputBox>
                    <Button type="primary" htmlType="submit" className="btn" style={{padding: '8px'}}
                            onClick={handleSubmit}>Submit</Button>
                </InputBox>
            </Card>

        </Drawer>
    )
}

export default ChangePasswordByAdmin
