export default (
    state = {
        loader: false,
        state: {},
        allStates: [],
        totalStates: 0,
        indiaStates: []
    },
    action
) => {
    switch (action.type) {
        case 'STATE_SHOWLOADER':
            return {...state, loader: true}
        case 'STATE_HIDELOADER':
            return {...state, loader: false}
        case 'LIST_STATE':
            return {...state, state: action.payload.data}
        case 'ADD_STATE':
            return {...state, addedState: action.payload}
        case 'LIST_ALL_STATE':
            return {...state, allStates: action.payload.data, totalStates: action.payload.total}
        case 'EMPTY_STATE_LIST':
            return {...state, allStates: [], totalStates: 0}
        case 'INDIA_ALL_STATE':
            return {...state, indiaStates: action.payload}
        default:
            return state
    }
}
