import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet'
import {Navigate, Outlet} from "react-router-dom";


const links = [
    {
        key: 'help',
        title: 'HELP',
        href: ''
    }
]

const copyright = (
    <Fragment>
        {/*Copyright <Icon type="copyright"/> 2018*/}
    </Fragment>
)

class UserLayout extends React.PureComponent {

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <link href="../dist/css/customCss.css" rel="stylesheet"/>
                    <link href="../dist/css/adminlte.css" rel="stylesheet"/>
                </Helmet>
                <Outlet/>
            </React.Fragment>
        )
    }
}

export default UserLayout
