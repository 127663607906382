import React, {useEffect, useState} from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    notification,
    Row,
    Modal,
    Tooltip,
    Icon
} from '../../../components/Elements/appUtils'
import {InputBox} from '../../../components/_utils/appUtils'
import {agentAddBankAccountFxn, listAllAccount, addAccountSendOtpFxn} from '../../bankAccount/actions/bankAccount'
import {useDispatch} from 'react-redux'
import {TableComp} from '../../../components/Elements/appUtils'
import moment from 'moment'
import EditAgentBankAccount from './editAgentBankAccounts'

let initState = {
    bankName: '',
    accountNo: '',
    ifscCode: '',
    swiftCode: '',
    address: ''
}
const AddBankAccount = (props) => {
    let [state, setState] = useState(initState)
    let [otp, setOtp] = useState('')
    let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
    let [bankList, setBankList] = useState([])
    let [cancelledCheque, setCancelledCheque] = useState({})
    let [refreshKey, setRefreshKey] = useState(moment())
    let [editState, setEditState] = useState({
        visible: false,
        accountData: {}
    })

    let {onClose, visible} = props
    let dispatch = useDispatch()
    useEffect(() => {
        loadBankList()
    }, [])
    const loadBankList = async () => {
        let resp = await dispatch(listAllAccount({}))
        let {data, success} = resp
        if (success) {
            setBankList(data)
        }
    }
    const handleChange = (data) => {
        setState(prevData => {
            return {
                ...prevData,
                ...data
            }
        })
    }

    let columns = [
        {
            title: 'Sr.',
            key: '#',
            dataIndex: '#',
            render: (item, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Bank Name',
            key: 'bankName',
            dataIndex: 'bankName',
            render: (item, record) => {
                let {accounts} = record
                return accounts.bankName
            }
        },
        {
            title: 'Account No',
            key: 'accountNo',
            dataIndex: 'accountNo',
            render: (item, record) => {
                let {accounts} = record
                return accounts.accountNo
            }
        },
        {
            title: 'IFSC',
            key: 'ifscCode',
            dataIndex: 'ifscCode',
            render: (item, record) => {
                let {accounts} = record
                return accounts.ifscCode
            }
        },
        {
            title: 'Swift Code',
            key: 'swiftCode',
            dataIndex: 'swiftCode',
            render: (item, record) => {
                let {accounts} = record
                return accounts.swiftCode
            }
        },
        {
            title: 'Cancelled Cheque',
            key: 'cancelledCheque',
            dataIndex: 'cancelledCheque',
            width: 180,
            render: (item, record) => {
                let {accounts} = record
                return (
                    <>
                        {accounts.cancelledCheque && accounts.cancelledCheque.path ? <>
                            <a className={'btn btn-default btn-xs'} href={accounts.cancelledCheque.path}
                               target={'_blank'}>Download</a>
                        </> : null}
                    </>
                )
            }
        },
        {
            title: 'Address',
            key: 'address',
            dataIndex: 'address',
            render: (item, record) => {
                let {accounts} = record
                return accounts.address
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (item, record) => {
                let {accounts} = record
                return (
                    <>
                        <Tooltip title={'Edit Account'}>
                            <a className={'btn btn-default btn-xs'} onClick={() => {
                                editEvent.showEditDrawer(accounts)
                            }}>
                                Edit
                            </a>
                        </Tooltip> &nbsp;
                        {/*<Tooltip title={'Delete Account'}>
              <a className={'btn btn-default btn-xs'}>
                <Icon type={'delete'} />
              </a>
            </Tooltip>*/}
                    </>
                )
            }
        }
    ]

    let chooseDocument = (e) => {
        let {files} = e.target
        if (files && files.length) {
            setCancelledCheque(files[0])
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!state.bankName) {
            notification.warning({message: 'Enter bank name'})
            return
        }
        if (!state.accountNo) {
            notification.warning({message: 'Enter account number'})
            return
        }
        if (!state.ifscCode) {
            notification.warning({message: 'Enter IFSC code'})
            return
        }
        if (!state.swiftCode) {
            notification.warning({message: 'Enter Swift code'})
            return
        }
        if (!cancelledCheque || (cancelledCheque && !cancelledCheque.name)) {
            notification.warning({message: 'Choose Cancelled Cheque.'})
            return
        }
        if (!state.address) {
            notification.warning({message: 'Enter bank address'})
            return
        }

        let resp = await dispatch(addAccountSendOtpFxn(state))
        if (resp && resp.success) {
            setVisibleOtpScreen(true)
        }
    }
    const handleSubmitAddAccount = async (e) => {
        e.preventDefault()
        if (!state.bankName) {
            notification.warning({message: 'Enter bank name'})
            return
        }
        if (!state.accountNo) {
            notification.warning({message: 'Enter account number'})
            return
        }
        if (!state.ifscCode) {
            notification.warning({message: 'Enter IFSC code'})
            return
        }
        if (!state.swiftCode) {
            notification.warning({message: 'Enter Swift code'})
            return
        }
        if (!cancelledCheque || (cancelledCheque && !cancelledCheque.name)) {
            notification.warning({message: 'Choose Cancelled Cheque.'})
            return
        }
        if (!state.address) {
            notification.warning({message: 'Enter bank address'})
            return
        }

        if (!otp) {
            notification.warning({message: 'Please enter otp'})
            return
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify({...state, otp}))
        if (cancelledCheque && cancelledCheque.name) {
            fd.append('cancelledCheque', cancelledCheque)
        }

        let resp = await dispatch(agentAddBankAccountFxn(fd))
        if (resp && resp.success) {
            handleChange(initState)
            setVisibleOtpScreen(false)
            setOtp('')
            setRefreshKey(moment())
            loadBankList()
        }
    }

    const editEvent = {
        showEditDrawer: (data) => {
            setEditState((prevState) => {
                return {
                    visible: true,
                    accountData: data
                }
            })
        },
        hideEditDrawer: () => {
            setEditState((prevState) => {
                return {
                    visible: false,
                    accountData: {}
                }
            })
        }
    }


    return (
        <Drawer
            visible={visible}
            title={'Add Bank Account'}
            onClose={onClose} width={'60%'}>
            <div className='card unizportal'>
                <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Bank Name'}>
                                    <Input onChange={({target}) => {
                                        handleChange({bankName: target.value})
                                    }} value={state.bankName} className={'form-control'} name='bankName'
                                           placeholder='Please enter bank name'/>
                                </InputBox>

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Account Number'}>
                                    <Input onChange={({target}) => {
                                        handleChange({accountNo: target.value})
                                    }} value={state.accountNo} className={'form-control'}
                                           name='accountNo' placeholder='Please enter Account Number'/>
                                </InputBox>

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'IFSC Code'}>
                                    <Input onChange={({target}) => {
                                        handleChange({ifscCode: target.value})
                                    }} value={state.ifscCode} placeholder='Please enter IFSC Code'
                                           name='ifscCode' className={'form-control'}/>
                                </InputBox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <InputBox title={'Swift Code'}>
                                    <Input onChange={({target}) => {
                                        handleChange({swiftCode: target.value})
                                    }} value={state.swiftCode} placeholder='Please enter Swift Code'
                                           name='swiftCode' className={'form-control'}/>
                                </InputBox>
                            </Form.Item>

                        </Col>
                        <Col span={12} key={refreshKey}>
                            <Form.Item>
                                <InputBox title={'Cancelled Cheque'}>
                                    <Input type={'file'}
                                           onChange={(e) => {
                                               chooseDocument(e)
                                           }} placeholder='Please choose Cancelled Cheque'
                                           name='cancelledCheque' className={'form-control'}/>
                                </InputBox>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item><InputBox title={'Address'}>
                                <Input.TextArea rows={4} onChange={({target}) => {
                                    handleChange({address: target.value})
                                }} value={state.address} placeholder='please enter address' name='address'
                                                className={'form-control'}/>
                            </InputBox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type='primary' htmlType='submit' className={'btn'}>
                        Add
                    </Button>
                </Form>
            </div>
            <div className='card unizportal'>
                <TableComp dataSource={bankList} columns={columns} pagination={false}/>
            </div>
            <Modal
                visible={visibleOtpScreen}
                onCancel={() => {
                    setVisibleOtpScreen(false)
                }
                }
                title={'Enter OTP'}
                footer={false}
            >
                <div style={{textAlign: 'center', marginBottom: 10}}>
                    <h6> A text message with a
                        4-digit verification code has been sent to your phone number.
                    </h6>
                    <InputBox title={'Enter OTP'}>
                        <input
                            className={'form-control mt10'}
                            type={'password'}
                            minLength={4}
                            maxLength={4}
                            value={otp} onChange={({target}) => {
                            setOtp(target.value)
                        }}/>
                    </InputBox>
                    <Button className={'btn btn-success mt10'} onClick={handleSubmitAddAccount}>
                        Submit OTP
                    </Button>

                </div>
            </Modal>

            {editState.visible ?
                <EditAgentBankAccount
                    onClose={editEvent.hideEditDrawer}
                    onSubmit={() => {
                        editEvent.hideEditDrawer()
                        loadBankList()
                    }}
                    {...editState}
                /> : null}

        </Drawer>
    )
}
export default AddBankAccount
