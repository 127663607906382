import React from "react"
import menu from "../../routes"
import {useNavigate} from "react-router-dom";

const SingleMenu = (props) => {
    let {menu, events, pathname} = props;
    return !menu.dontShowOnMenu ? (
        <>
            <li className="nav-item">
                <a onClick={() => events.openLink(menu.path)}
                   className={`nav-link${pathname == menu.path ? ' active' : ""}`}>
                    <p>{menu.name}</p>
                </a>
            </li>
        </>
    ) : null;
};
const NestedMenu = (props) => {
    let {menu, events} = props;
    let menuKey = `ui-${menu.key}`;
    return (
        !menu.dontShowOnMenu ? <>
            <>
                <li className="nav-item" id="dropdown" key={menuKey}>
                    <a href={`#${menu.key}`} className="nav-link" data-toggle="collapse">
                        <div className={'jcsb'}>
                            <p>{menu.name}</p>
                            <i className={'fas fa-chevron-down'}/>
                        </div>
                    </a>

                    <div id={menu.key} className="panel-collapse collapse">
                        <ul className="ml20">
                            {menu.children.map((child) => {
                                return !child.dontShowOnMenu ? (
                                    events.checkAuth(child) ? <div key={child.key}>
                                        <>
                                            {child.type == 'link' ?
                                                <li className="nav-item" key={child.key}>
                                                    <a href={child.path} className="nav-link"
                                                       onClick={() => events.openLink(child.path)}>
                                                        <div> {child.name}</div>
                                                    </a>
                                                </li> :
                                                <li className="nav-item" key={child.key}>
                                                    <a className="nav-link" onClick={() => events.openLink(child.path)}>
                                                        <div> {child.name}</div>
                                                    </a>
                                                </li>}
                                        </>
                                    </div> : null
                                ) : null;
                            })}


                            {/*         <li className="nav-item">
                             <a href="#" className="nav-link">
                                 <p>One</p>
                             </a>
                         </li>
                         <li className="nav-item">
                             <a href="#" className="nav-link">
                                 <p>Two</p>
                             </a>
                         </li>
                         <li className="nav-item">
                             <a href="#" className="nav-link">
                                 <p>Three</p>
                             </a>
                         </li>*/}
                        </ul>
                    </div>
                </li>


            </>
        </> : null
    );
};

const SideMenu = (props) => {
    let {currentUser: user} = props;
    let {pathname} = window.location;

    const navigate = useNavigate();

    let events = {
        openLink: (path) => {
            navigate(path);
        },
        checkAuth: (data) => {
            return data && data.authority && data.authority.length
                ? data.authority.includes(user.userType)
                    ? true
                    : false
                : true;
        },
    };
    return (
        <>
            <aside className="main-sidebar sidebar-light-primary">
                {/* Brand Logo */}
                <a href="index.html" className="brand-link">
                    <img
                        src="dist/img/AdminLTELogo.png"
                        alt="Logo"
                        className="brand-image img-circle elevation-3"
                    />
                </a>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar Menu */}
                    <nav className="mt-4">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            {menu.map((item) => {
                                let {children = []} = item;
                                let checkM = ((children && children.length ? (
                                    <NestedMenu menu={item} events={events} key={item.key} pathname={pathname}/>
                                ) : (
                                    <SingleMenu menu={item} events={events} key={item.key} pathname={pathname}/>
                                )));
                                return events.checkAuth(item) ? checkM : null;
                            })}


                            <hr className="divide"/>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Help</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Setting</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Support</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Library</p>
                                </a>
                            </li>

                        </ul>
                        <div className="box-side">
                            <img
                                src="dist/img/share-friend.png"
                                alt=""
                                style={{
                                    width: "100%",
                                    margin: "30px auto 0",
                                    display: "block",
                                    padding: "0 45px"
                                }}
                            />
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href="">
                                            <img src="dist/img/google-plus.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src="dist/img/facebook.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src="dist/img/twitter.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src="dist/img/linkedin.svg" alt=""/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            <li className="nav-header">
                                support <small>helpful links</small>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Recruiters Manual</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Tutorials</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Study in Canada Guide</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Study in the UK Guide</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>News Center</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Product Updates</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <p>Our Blog</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

        </>
    )
}
export default SideMenu