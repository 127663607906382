import React, {useEffect, useState} from 'react'
import '../Webinar/webinar.css'
import {webEventsListUrlFxn} from './action'
import {displayTime} from '../../../components/_utils/appUtils'
import moment from 'moment'
import {Tooltip} from '../../../components/Elements/appUtils'
import _ from 'lodash'
import {push} from 'connected-react-router'
import {useDispatch} from 'react-redux'

const WebinarComponent = (props) => {
    let dispatch = useDispatch()
    let [eventList, setEventList] = useState([])

    useEffect(() => {
        eventListFxn()
    }, [])

    const eventListFxn = async () => {
        let obj = {
            sortField: 'date',
            sortOrder: 'descend',
            results: 100000,
            status: true
        }
        let {data} = await webEventsListUrlFxn(obj)

        /*  let findOpen = _.filter(data, (item) => {
              let stringDate = moment(item.date).format('YYYY-MM-DD')
              let currentDate = moment().format('YYYY-MM-DD')
              return moment(stringDate).isSameOrAfter(currentDate);
          })*/
        let findOpen = _.filter(data, (item) => {
            return item.registration
        })
        let findClose = _.filter(data, (item) => {
            return !item.registration
        })
        if (findOpen && findOpen.length) {
            findOpen = findOpen.reverse()
        }
        let newData = [...findOpen, ...findClose]
        setEventList(newData)
    }
    const events = {
        singleWebinarOpen: (event) => {
            dispatch(push(`/singleWebinar?_id=${event._id}`))
        },
        galleryOpen: (event) => {
            dispatch(push(`/webinar-gallery/${event._id}`))
        }
    }
    return (
        <React.Fragment>


            <section className='banner blog-banner'>

                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-12'>
                            <div className='content text-center'>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='img alignCenter'>
                                <img src='/assets/image/gallery-banner.png' alt='' className='w-50'/>
                            </div>


                        </div>
                    </div>
                </div>

            </section>

            <section className='innovation gap-62 blog-innovation webinar-info'>
                <div className='container'>
                    <div className='head'>
                        <h2 className='w-100 text-center'>Check our Upcoming Webinars & Personal Meets</h2>
                    </div>
                    <div className='row'>

                        <div className='col-lg-12 col-md-4'>
                            {eventList && eventList.length ? eventList.map((item) => {
                                return (
                                    <>
                                        <div className='product-teaser event-teaser'>
                                            <div className='event-image'>
                                                <div class='field field-field-image field-label-hidden field-item'>


                                                    <img loading='lazy' src={item &&
                                                    item.chiefGuestProfile && item.chiefGuestProfile.path ? item.chiefGuestProfile.path
                                                        : null} alt='Event' className='event-image-webinar'
                                                    />

                                                </div>

                                                <div className='text-webinar'>
                                                    <p>{item.chiefGuest}<br/>
                                                        {item.designation}</p>
                                                </div>
                                            </div>
                                            <div className='event-content-wrap'>
                                                <div className='event-content-container'>
                                                    <div className='event-content'>
                                                        <div className='event-title'>
                                                            <a onClick={() => {
                                                                events.singleWebinarOpen(item)
                                                            }}>
                                                                <div
                                                                    class='field field-title field-label-hidden field-item'>
                                                                    {item.name}.
                                                                </div>
                                                            </a>
                                                            <div class='event-price'>
                                                                <div
                                                                    class='product--variation-field--variation_price__20 field field-price field-label-hidden field-item'>
                                                                    <Tooltip title={item.eventType}>
                                                                        {item.eventType == 'Zoom' ?
                                                                            <img src='/assets/image/zoom.png'
                                                                                 height='40px'
                                                                                 width='40px'/> :
                                                                            <img
                                                                                src='/assets/image/video-conference.png'
                                                                                height='40px' width='40px'/>}
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='event-meta'>

                                                            <div className='event-date-wrap'>
                                                                <div className='event-date'>
                                                                    <span
                                                                        className='event-date-day'>{moment(item.date).format('Do')}</span>
                                                                    <span className='event-date-month'>
                                                                       {moment(item.date).format('MMMM')}
                                                               </span>
                                                                    <span className='event-date-year'>
                                                             {moment(item.date).format('YYYY')}
                                                      </span>

                                                                </div>
                                                            </div>
                                                            <div className='event-time'>
                                                                <i class='fa fa-clock-o' aria-hidden='true'></i>
                                                                <span> {displayTime(item.time)}</span>
                                                            </div>
                                                            <div className='event-venue-wrap'>
                                                                {item.location ?
                                                                    <span> <i class='fa fa-map-marker'
                                                                              aria-hidden='true'></i> </span>
                                                                    : null}
                                                                <div className='event-location'>
                                                                    <div
                                                                        class='field field-field-event-location field-label-hidden field-item'>
                                                                        {item.location}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='event-button'>
                                                {item.registration == false || item.status == false ?
                                                    <>
                                                        <a onClick={() => {
                                                            events.singleWebinarOpen(item)
                                                        }}
                                                           style={{
                                                               backgroundColor: '#d9534f',
                                                               color: 'white',
                                                               marginBottom: 0
                                                           }}
                                                           className='button'>
                                                            Registration Closed
                                                        </a>
                                                        {item._id == '66b08fd2c261ab02ab5a8629' ?
                                                            <div className={'alignCenter'}>Capacity Full</div>
                                                            : null}
                                                    </> :
                                                    <a className='button' onClick={() => {
                                                        events.singleWebinarOpen(item)
                                                    }}>Registration
                                                        Open</a>
                                                }
                                                <div style={{textAlign: 'right', padding: '10px 20px'}}>
                                                    <a
                                                        onClick={() => {
                                                            events.galleryOpen(item)
                                                        }}
                                                        style={{color: '#0872bc', textDecoration: 'underline'}}
                                                        target={'_blank'}>
                                                        View event progress
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }) : null}
                        </div>

                    </div>

                </div>

            </section>


        </React.Fragment>
    )
}
export default WebinarComponent
