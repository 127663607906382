import {notification} from '../../../components/Elements/appUtils'
import {getToken, customAxios as axios} from '../../../request'
import {addCampusUrl, listAllCampusUrl, listCampusUrl, CourseCampus} from '../api/campus'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'


export const addCampus = (valData) => async (dispatch) => {
    dispatch({type: 'CAMPUS_SHOW_LOADER'})
    let {data} = await axios.post(addCampusUrl(), valData, getToken())
    dispatch({type: 'CAMPUS_HIDE_LOADER'})
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const listAllCampus = (filters) => async (dispatch) => {
    dispatch({type: 'CAMPUS_SHOW_LOADER'})
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listAllCampusUrl(), config)
    // console.log("data from backend is all", data);
    dispatch({type: 'CAMPUS_HIDE_LOADER'})

    dispatch({type: 'LIST_ALL_CAMPUS', payload: data.data})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data

}


export const getCampus = id => async (dispatch) => {
    dispatch({type: 'CAMPUS_SHOW_LOADER'})
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listCampusUrl(id), config)
    dispatch({type: 'CAMPUS_HIDE_LOADER'})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        dispatch({type: 'LIST_CAMPUS', payload: data})
    }
    return data.data
}


export const updateCampus = (valData, id) => async (dispatch) => {
    dispatch({type: 'CAMPUS_SHOW_LOADER'})
    // console.log("campus dat ais", valData)
    let {data} = await axios.put(listCampusUrl(id), valData, getToken())
    dispatch({type: 'CAMPUS_HIDE_LOADER'})
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}


export const listCourseCampus = (filters) => async (dispatch) => {
    let {data} = await axios.post(CourseCampus(), filters, getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data

}


export const deleteCampus = (id) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.delete(listCampusUrl(id), getToken())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    dispatch(hidePageLoad())
    return data
}