import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Select,
    Button,
    Icon, Col, Row, Tooltip, Drawer, notification, Card
} from '../../../components/Elements/appUtils'
import _ from 'lodash'
import moment from 'moment'
import {hideLoader, hidePageLoad} from '../../../modules/actions'
import {connect} from 'react-redux'
import {listAllCountries} from '../../countries/actions/countries'
import {
    InputBox,
    departmentObj, CountryOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {updateUser, listAllUsers, getUser} from '../actions/user'
import {CountryCodeWithFlag, LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'




const {TextArea} = Input

class EditUser extends PureComponent {

    events = {
        selectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let {universityList} = this.state
            let universities = []
            _.each(universityList, (item) => {
                if (item && item._id) {
                    universities.push(item._id)
                }
            })
            setFieldsValue({
                universities: universities
            })
        },
        deSelectAllUni: () => {
            const {form: {setFieldsValue}} = this.props
            let universities = []
            setFieldsValue({
                universities: universities
            })
        },
        chooseCountry: (name) => {
            const {setFieldsValue} = this.props.form
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                setFieldsValue({
                    stateName: undefined,
                    cityName: undefined
                })
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: '',
                    cityName: '',
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            const {setFieldsValue} = this.props.form
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            setFieldsValue({
                cityName: undefined
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: '',
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        },
        assignUserFnx: (id) => {
            let user = _.find(this.state.usersForAgent, (item) => {
                return item._id === id
            })
            this.setState({
                createdByUserIdName: user.name,
                createdByUser: {
                    name: user.name,
                    userId: user._id,
                    time: new Date()
                }
            })
        },
        assignAgentUser: (id) => {
            let user = _.find(this.state.usersForAgent, (item) => {
                return item._id === id
            })
            this.setState({
                createdByUserIdName: user.name,
                createdByUser: {
                    name: user.name,
                    userId: user._id,
                    time: new Date()
                }
            })
        },
        assignManagerAgent: (id) => {
            let user = _.find(this.state.marketingManagerForAgent, (item) => {
                return item._id === id
            })
            this.setState({
                displayAgentManagers: user.name,
                selectedMarketingManager: id
            })
        }
    }

    agentEvents = {
        fetchAgent: async (name) => {
            let {dispatch} = this.props
            let obj = {
                results: 100,
                userType: 'agent',
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'companyName'],
                name: name,
                block: false
            }
            let {data} = await dispatch(listAllUsers(obj))
            this.setState({
                agentList: data
            })
        },
        removeAgent: (index) => {
            let cloneAgent = _.clone(this.state.agents)
            cloneAgent.splice(index, 1)
            this.setState({
                agents: cloneAgent
            })
        },
        chooseAgent: (data) => {
            let {agents, agentList} = this.state
            let findUser = _.find(agents, (item) => {
                return item._id && item._id.toString() == data
            })
            if (findUser) {
                this.setState({
                    agentName: '',
                    agentList: []
                })
                return notification.warning({
                    message: 'Agent already exists.'
                })
            } else {
                let findAgent = _.find(agentList, (item) => {
                    return item._id && item._id.toString() == data
                })
                if (findAgent) {
                    let cloneAgents = _.clone(agents)
                    cloneAgents.push(findAgent)
                    this.setState({
                        agentName: '',
                        agentList: [],
                        agents: cloneAgents
                    })
                }
            }
        }
    }

    conditionalFxn = {
        countryFieldFxn: () => {
            const {
                form: {getFieldValue}
            } = this.props
            return (getFieldValue('userType') && ((getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment) && getFieldValue('department')) || getFieldValue('userType') == 'branchManager'))
        },
        branchManagerFieldFxn: () => {
            const {
                form: {getFieldValue}
            } = this.props
            return ((getFieldValue('userType') && getFieldValue('department')) && (getFieldValue('userType') == 'branchUser' && (getFieldValue('department') !== departmentObj.visaApproved && getFieldValue('department') !== departmentObj.warningDepartment)))
        },
        approvedAgentFieldFxn: () => {
            const {
                form: {getFieldValue}
            } = this.props
            return (getFieldValue('userType') && (getFieldValue('userType') == 'admin' || getFieldValue('userType') == 'branchManager' || getFieldValue('userType') == 'branchUser'))
        },
        universitySelectFieldFxn: () => {
            const {
                form: {getFieldValue}
            } = this.props
            return (getFieldValue('userType') &&
                ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
            )
        },
        universitySelectFieldFxnRequired: () => {
            const {
                form: {getFieldValue}
            } = this.props
            return (getFieldValue('userType') &&
                ((getFieldValue('userType') == 'branchUser' && getFieldValue('department') && (getFieldValue('department').indexOf('Visa') < 0 && getFieldValue('department') !== departmentObj.warningDepartment)) || (getFieldValue('userType') == 'branchManager' && getFieldValue('branchManagerType') == 'universityWise'))
            )
        }

    }

    constructor(props) {
        super(props)
        this.state = {
            allBranch: [],
            universityList: [],
            countryList: [],
            mainBranchArr: [],
            logo: {},
            uploadKey: moment(),
            oldLogo: {},
            agentList: [],
            agents: [],
            userData: {},
            usersForAgent: [],
            marketingUsersForAgent: [],
            marketingManagerForAgent: [],
            displayAgentUsers: [],
            displayAgentManagers: [],
            createdByUserIdName: '',
            createdByUser: {},
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            selectedMarketingManager: [],
            selectedMarketingUser: [],
            countryCode: ''
        }
    }

    componentDidMount() {

        this.loadCountry()
        this.getUserData()
        let {dispatch} = this.props
        dispatch({type: 'USER_HIDELOADER'})
        dispatch(hideLoader())
        dispatch(hidePageLoad())
    }

    async loadUserData() {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.setState({userData: user})
    }


    handleSubmit = e => {
        const {dispatch, form, onClose, reloadTable} = this.props
        let {getFieldValue} = form
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {
                    address,
                    logo,
                    createdByUser,
                    countryCode
                } = this.state
                if (!countryCode) {
                    notification.error({
                        message: 'Please choose County Code'
                    })
                    return
                }
                if (createdByUser && createdByUser.userId) {
                    valData.createdByUser = createdByUser
                }
                valData.address = address
                valData.countryISOCode = countryCode
                let fd = new FormData()

                fd.append('obj', JSON.stringify(valData))
                if (logo && logo.name) {
                    fd.append('logo', logo)
                }
                let data = await dispatch(updateUser(fd, this.state.userId))
                if (data && !data.error) {
                    this.setState({
                        address: '',
                        uploadKey: moment(),
                        logo: {}
                    })
                    onClose()
                    if (reloadTable) {
                        reloadTable()
                    }
                }
            }
        })
    }

    async loadCountry() {
        let {dispatch} = this.props
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend',
            active: [true, false]
        }
        let {data} = await dispatch(listAllCountries(countryFilter))
        this.setState({
            countryList: data
        })
    }


    async getUserData() {
        let {pathname, dispatch, form: {setFieldsValue}, userData} = this.props
        if (userData && userData._id) {
            let resp = await dispatch(getUser(userData._id))
            if (resp) {
                this.setState({
                    userId: resp._id,
                    oldLogo: resp.logo,
                    address: resp.address,
                    agents: resp.agents,
                    email: resp.email,
                    countryCode: resp.countryISOCode ? resp.countryISOCode : '',
                    createdByUserIdName: resp.createdByUser ? resp.createdByUser.name : ''
                })
                setFieldsValue({
                    name: resp.name,
                    mobile: resp.mobile,
                    email: resp.email,
                    userType: resp.userType,
                    address: resp.address,
                    postName: resp.postName,
                    countryRight: resp.countryRight
                })
                setTimeout(() => {
                    setFieldsValue({
                        countryArr: resp.countryArr && resp.countryArr.length ? resp.countryArr : []
                    })
                }, 1000)
            }
        }
    }

    render() {

        const {submitting, dispatch, visible, onClose} = this.props
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue}
        } = this.props

        const {oldLogo, agentName, agentList, agents, userData} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        let inputTypes = {
            fields: [
                {
                    label: 'Contact Person Name',
                    key: 'name',
                    required: true
                },
                {
                    key: 'countryCode',
                    span: 3,
                    customField: (
                        <div style={{marginTop: 5}}>
                            <CountryCodeWithFlag countryCode={this.state.countryCode} chooseCode={(val) => {
                                this.setState({
                                    countryCode: val
                                })
                            }}/>
                        </div>
                    )
                },
                {
                    label: 'Mobile no',
                    key: 'mobile',
                    span: 5,
                    required: true
                },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Post Name',
                    key: 'postName'
                },
                {
                    key: 'countryRight',
                    label: 'Country',
                    type: 'select',
                    required: true,
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    options: CountryOptions,
                    onChange: x => {
                        setFieldsValue({
                            countryRight: x
                        })
                    }
                },
                {
                    key: 'countryArr',
                    label: '',
                    label1: '* Select Country',
                    type: 'select',
                    extraBox1: (
                        <React.Fragment>
                            <a onClick={() => this.events.selectAllUni()}>Select All</a>{' '}
                            <a onClick={() => this.events.deSelectAllUni()}>De-select All</a>
                            {getFieldValue('universities') && getFieldValue('universities').length ?
                                <a className={'selectedUni'}>{getFieldValue('universities').length}</a>
                                : null}
                        </React.Fragment>
                    ),
                    required: true,
                    showSearch: true,
                    options: this.state.countryList,
                    hidden: !(getFieldValue('countryRight') == 'Selected'),
                    mode: 'multiple',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.countryName}`,
                    onChange: x => {
                        setFieldsValue({
                            countryArr: x
                        })
                    }
                },
                {
                    key: 'logo',
                    customField: (
                        <InputBox title={'Profile Picture'} className={'logoCls'}>
                            <Input type={'file'} className={'form-control'} key={this.state.uploadKey} name={'logo'}
                                   id={'logo'}
                                   onChange={(e) => {
                                       this.setState({
                                           logo: e.target.files && e.target.files.length ? e.target.files[0] : null
                                       })
                                   }}/>
                        </InputBox>
                    )
                },
                {
                    key: 'address',
                    span: 24,
                    customField: (
                        <InputBox title={'Address'}>
                            <TextArea rows={4} className={'form-control'} value={this.state.address} onChange={(e) => {
                                this.setState({address: e.target.value})
                            }}>
                            </TextArea>
                        </InputBox>
                    )
                }
            ]
        }
        return (
            <Drawer
                title={'Update User'}
                visible={visible}
                placement='right'
                closable={true}
                onClose={onClose}
                width={'80%'}>
                <div className='form-box'>
                    <div className='card unizportal'>
                        <Form onSubmit={this.handleSubmit}>

                            <Row gutter={16} className={'wrapBox'}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item.customField ? !item.hidden && <Col span={item.span ? item.span : 8}>
                                                {item.customField}
                                            </Col> : !item.hidden &&
                                                <Col span={item.span ? item.span : 8} key={key}
                                                     style={item.extraBox1 ? {paddingBottom: 1} : {}}>
                                                    <InputBox title={item.label1 ? item.label1 : ''}
                                                              extra={item.extraBox1}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </InputBox>
                                                </Col>}
                                        </React.Fragment>
                                    )
                                })}

                                <Col span={24}>

                                </Col>

                                {agents && agents.length ? <Col span={24}>
                                    <Card title={'Agents'} size={'small'}>
                                        <div className={'guestEmailBox'}>
                                            <ul>
                                                {agents.map((item, index) => {
                                                    return (
                                                        <li className={'emailTag'} key={index}>
                                                            {item.name} ({item.companyName})
                                                            <Icon type={'close'}
                                                                  onClick={() => this.agentEvents.removeAgent(index)}
                                                                  className={'emailIcon'}/>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Card>
                                </Col> : null}
                            </Row>


                            <Form.Item>
                                <Button type='primary' htmlType='submit' loading={this.props.loading} className={'btn'}>
                                    UPDATE
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>

            </Drawer>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    pathname: router.location.pathname,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedEditUser = Form.create()(EditUser)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedEditUser)
