import React from 'react'
import GicListComponent from './components/gicListComponent'

const GicList = () => {
    return (
        <>
            <GicListComponent title={'Gic Request List'} pathName={'gic.gicRequested'}/>
        </>
    )
}

export default GicList
