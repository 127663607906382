export default (
    state = {
        reloadFilters: false,
        courseName: null
    },
    action
) => {
    switch (action.type) {
        case 'START_LOAD_FILTER':
            return {...state, reloadFilters: true, courseName: action.courseName, universityName: action.universityName}
        case 'STOP_LOAD_FILTER':
            return {...state, reloadFilters: false, courseName: null, universityName: null}
        default:
            return state
    }
}
