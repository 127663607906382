export default (
    state = {
        loader: false,
        country: {},
        allCountries: [],
        totalCountries: 0,
    },
    action
) => {
    switch (action.type) {
        case 'COUNTRY_SHOWLOADER':
            return {...state, loader: true};
        case 'COUNTRY_HIDELOADER':
            return {...state, loader: false};
        case 'LIST_COUNTRY':
            return {...state, country: action.payload.data};
        case 'ADD_COUNTRY':
            return {...state, addedCountry: action.payload};
        case 'LIST_ALL_COUNTRY':
            return {...state, allCountries: action.payload.data, totalCountries: action.payload.total}
        default:
            return state
    }
}
  