import {apiUrl} from '../../../settings'

export const getUserReportUrl = () => {
    return apiUrl + '/userReports'
}
export const getReportApplicationListUrl = () => {
    return apiUrl + '/reportingApplicationList'
}
export const getMeetingsCountUrl = () => {
    return apiUrl + '/meetingCount'
}
export const getMeetingsByIdUrl = () => {
    return apiUrl + '/meetingsById'
}
export const marketingManagerReportsUrl = () => {
    return apiUrl + '/marketingManagerReports'
}

export const marketingManagerReportingApplicationUrl = () => {
    return apiUrl + '/marketingManagerReportingApplicationList'
}

export const directUniversityMarketingManagerReportsUrl = () => {
    return apiUrl + '/directUniversityMarketingManagerReports'
}
export const directUniversityReportingApplicationUrl = () => {
    return apiUrl + '/directUniversityReportingApplication'
}

export const directUniversityRecordsCountUrl = () => {
    return apiUrl + '/directUniversityRecordsCount'
}
export const canadaUniversityRecordsCountUrl = () => {
    return apiUrl + '/canadaUniversityRecordsCount'
}

export const ukUniversityRecordsCountUrl = () => {
    return apiUrl + '/ukUniversityRecordsCount'
}

export const directUniversityReportCountForMarketingUrl = () => {
    return apiUrl + '/directUniversityReportCountForMarketing'
}

export const ukBloomsburyRecordCountUrl = () => {
    return apiUrl + '/ukBloomsburyRecordCount'
}
