import React, {useState, useEffect, useRef} from 'react'
import {
    Row,
    Col, Tooltip,
} from '../../../components/Elements/appUtils'
import {useDispatch} from 'react-redux'
import {TableComp} from '../../../components/Elements/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {
    newFormatDisplayDate, InputBox, onshoreReferenceCodes, newStringDate
} from '../../../components/_utils/appUtils'
import {studentRegistrationForCanadaFxn} from "../actions/student";
import ViewApplication from "../../applications/views/viewApplication";
import {AllowComponentUserWise} from "../../WebComponent/allowComponentRightsWise";

const initialState = {
    studentObj: {},
    viewCourseDrawer: false
}

const OnShoreStudentList = (props) => {
    const [params, setParams] = useState({})
    const [totalStudent, setTotalStudent] = useState(0)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [state, setState] = useState(initialState)
    let [tableSearch, setTableSearch] = useState(false)

    const tableRef = useRef()
    const dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let respa = await getParamsForStudentList()
            params = {...params, ...respa}
            params.status = 'Active'

            if (params.name) {
                params.name = [params.name]
            } else {
                params.name = []
            }
            if (params.email) {
                params.email = [params.email]
            } else {
                params.email = []
            }
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            setParams({...params})
            let resp = await dispatch(studentRegistrationForCanadaFxn({
                ...params,
                regExFilters: ['name', 'address', 'email', 'mobile', 'countryName', 'stateName', 'cityName', 'universityName']
            }))
            setTableSearch(false)
            setTotalStudent(resp.total)
            resolve(resp)
        })
    }

    useEffect(() => {
        setFieldByParams()
    }, [])


    const setFieldByParams = async () => {
        let resp = await getParamsForStudentList()
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.email) {
            setEmail(resp.email)
        }

    }


    const getParamsForStudentList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let name = searchParams.get('name')
            let email = searchParams.get('email')

            let obj = {}
            if (name) {
                obj.name = name
            }

            if (email) {
                obj.email = email
            }


            resolve(obj)
        })
    }

    const events = {
        reloadTable: () => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        },
        searchData: async () => {
            let obj = {}
            if (name) {
                obj.name = name
            }
            if (email) {
                obj.email = email
            }

            dispatch(
                getUrlPushWrapper('pendingOnShoreApplications', {
                    ...obj
                }))
            setTableSearch(true)
            setTimeout(() => {
                events.reloadTable()
            }, 200)
        },

        enterName: (name) => {
            setName(name)
        },
        enterEmail: (name) => {
            setEmail(name)
        },
        clearFxn: async () => {
            setName('')
            setEmail('')
            dispatch(
                getUrlPushWrapper('pendingOnShoreApplications'))
            setTableSearch(true)
            setTimeout(() => {
                events.reloadTable()
            }, 200)
        },
        goBack: () => {
            dispatch(getUrlPushWrapper('dashboard'))
        },
        viewCourse: (record) => {
            setState({
                ...state,
                viewCourseDrawer: true,
                studentObj: record
            })
        },
        viewCourseClose: () => {
            setState({
                ...state,
                viewCourseDrawer: false,
                studentObj: {}
            })

        },
    }


    const columns = [

        {
            title: 'ID',
            dataIndex: 'studentId',
            width: 70,
            searchTextName: 'studentId'
        },

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (item, record) => {
                let {profileImage} = record
                return (
                    <div>
                        {item}
                    </div>
                )
            }
        },

        {
            title: 'Email',
            dataIndex: 'email',
            searchTextName: 'email',
            width: 180,
            render: (item, record) => {
                return (
                    <div style={{width: 180}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'DOB',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 100,
            render: (val) => {
                return (
                    <div>
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Refer By',
            dataIndex: 'onshoreReferBy',
            key: 'onshoreReferBy',
            width: 100,
            render: (item) => {
                return item && onshoreReferenceCodes[item] ? onshoreReferenceCodes[item] : null
            }
        },
        {
            title: 'Academic',
            dataIndex: 'academicDocument',
            key: 'academicDocument',
            width: 100,
            render: (val) => {
                return (
                    <div>
                        {val && val.path ? <a href={val.path} target={'_blank'}>Download</a> : null}
                    </div>
                )
            }
        },
        {
            title: 'Other',
            dataIndex: 'otherDocument',
            key: 'otherDocument',
            width: 100,
            render: (val) => {
                return (
                    <div>
                        {val && val.path ? <a href={val.path} target={'_blank'}>Download</a> : null}
                    </div>
                )
            }
        },
        {
            title: 'Added On',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            render: (val) => {
                return (
                    <div>
                        {newStringDate(val)}
                    </div>
                )
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            // fixed: 'right',
            height: 120,
            width: 70,
            render: (text, record) => {
                return (
                    <div style={{width: 70}}>
                        <Tooltip title={'View Application'}>
                            <button className='btn' onClick={() => events.viewCourse(record)}><img
                                src='/dist/img/view.svg' alt=''/>
                            </button>
                        </Tooltip>
                    </div>

                )
            }
        }
    ]


    return (
        <div>

            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>All Students: {totalStudent || 0}</h5>

                            <div className='search-box-table round'>

                            </div>

                            <div className='sort-box-table mark-btn'>

                            </div>


                        </div>
                        <div className='card-body table-responsive'>
                            <Row gutter={16} className={'filter_box'}>
                                <Col span={6}>
                                    <InputBox title={'Search by name'}>
                                        <input type='search'
                                               placeholder='Search by name...' value={name} onChange={(e) => {
                                            events.enterName(e.target.value)
                                        }}/>
                                    </InputBox>
                                </Col>
                                <Col span={6}>
                                    <InputBox title={'Search by Email'}>
                                        <input type='search'
                                               placeholder='Search by email...' value={email} onChange={(e) => {
                                            events.enterEmail(e.target.value)
                                        }}/>
                                    </InputBox>
                                </Col>

                                <Col span={24}>
                                    <div className={'btn_group'}>
                                        <a onClick={() => events.searchData()} className={'default_btn'}>Search</a>
                                        <a onClick={() => events.clearFxn()} className={'default_btn'}>Clear</a>
                                    </div>
                                </Col>
                            </Row>
                            <TableComp columns={columns}
                                       className={'table table-striped table-valign-middle'}
                                       apiRequest={apiRequest}
                                       pagination={{
                                           position: 'top',
                                           pageSizeOptions: ['50', '100', '200', '500'],
                                           defaultPageSize: 50,
                                           current: params.page ? params.page : 1
                                       }}
                                       ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {state.viewCourseDrawer ? <ViewApplication
                visible={state.viewCourseDrawer}
                pageType={'student'}
                reloadTable={() => events.reloadTable()}
                onClose={() => {
                    events.viewCourseClose()
                    events.reloadTable()
                }}
                studentObj={state.studentObj}/> : null}

        </div>
    )
}

export default OnShoreStudentList
