import React, {useEffect, useRef, useState} from 'react'
import {
    Drawer,
    Select,
    Card,
    Avatar,
    Table, Tooltip, Popconfirm, Icon
} from '../../../components/Elements/appUtils'
import {useDispatch} from 'react-redux'
import {
    displayDate,
    statusColors,
    getStatusDate, fcmtUniversity
} from '../../../components/_utils/appUtils'
import lodash from 'lodash'
import moment from 'moment'
import {getStudentOtherApplication} from '../actions/chooseStudent'
import {
    AllowComponentRightsWise,
    AllowComponentUserWise,
    CheckManagerWiseRight, ShowEnrollmentComponent
} from "../../WebComponent/allowComponentRightsWise";
import DraftButtonComponent from "./draftButtonComponent";

const {Option} = Select
const StudentOtherApplications = (props) => {
    let {visible, applicationObj, reloadTable, onStudentOtherAppClose} = props
    const [data, setData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        loadStudentOtherApplication()
    }, [])
    let loadStudentOtherApplication = async () => {
        let resp = await dispatch(getStudentOtherApplication({
            studentId: applicationObj._id,
            applicationId: applicationObj.applications._id
        }))
        // console.log(resp)
        setData(resp)
    }


    const columns = [
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',

            render: (item, record) => {
                return (
                    <React.Fragment>
                        <label className={'label'} style={{background: statusColors(item)}}>{item}</label>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'University',
            key: 'applications.courseUniversity',
            dataIndex: 'applications.courseUniversity',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url}/>
                            : null}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName'
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </React.Fragment>
                )
            }
        },

        {
            title: 'Date',
            key: 'applications.addedOn',
            dataIndex: 'applications.addedOn',
            render: (val, record) => {
                return (
                    <div>{getStatusDate(record.applications.statusList, record.applications.status)}</div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            render: (val) => {
                return (
                    <div>{val && val.name ? val.name : ''}</div>
                )
            }
        },
        {
            title: 'Agent',
            key: 'applications.agentId',
            dataIndex: 'applications.agentId',
            render: (val) => {
                return (
                    <div>{val && val.companyName ? val.companyName : ''}</div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {applications} = record;
                return (
                    <div className={'actionBtnGroup'}>
                        {!applications.directClosed &&

                        <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                            <Tooltip title={'View Application'}>
                                <a className='btn'
                                   href={`/application/view?appId=${applications._id}&studentId=${record._id}`}
                                ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'}/></a>

                            </Tooltip>{' '}
                        </AllowComponentRightsWise>}
                        <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                            <Tooltip title={'View Application'}>
                                <a className='btn'
                                   href={`/student/application?studentId=${record._id}&appId=${applications._id}`}
                                ><img src='/dist/img/view.svg' alt=''/></a>
                            </Tooltip>

                        </AllowComponentRightsWise>
                    </div>
                )
            }
        }
    ]


    return (
        <React.Fragment>
            <Drawer visible={visible}
                    width={'75%'}
                    closable={true}
                    maskClosable={true}
                    onClose={onStudentOtherAppClose}>
                <Card bordered={true} title={`${applicationObj.name ? applicationObj.name : ''} Other Applications`}>
                    <Table columns={columns} size={'small'} bordered={true}
                           dataSource={data}
                           rowKey={(item, x) => {
                               return x
                           }}
                           pagination={false}
                    />

                </Card>
            </Drawer>
        </React.Fragment>
    )
}
export default StudentOtherApplications
