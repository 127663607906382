import React from 'react'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {LazyLoadImage} from 'react-lazy-load-image-component'

class FrontFooter extends React.Component {
    constructor(props) {
        super()
        this.state = {
            currentPath: ''
        }
    }

    componentDidMount() {
        let {pathname} = window.location
        let currentPath = pathname.replace('/', '')
        this.setState({currentPath})

    }

    openPage = (path) => {
        let {dispatch} = this.props
        window.scrollTo(0, 0)
        dispatch(push(`/${path}`))
    }

    render() {
        let {currentPath} = this.state
        return (
            <>
                {/*<Helmet>
                    <script src="../assets/jquery.min.js" type="text/javascript"></script>
                    <script src="../assets/bootstrap.bundle.min.js"></script>
                </Helmet>*/}
                <footer className={'frontFooter'}>
                    {(currentPath == '/' || currentPath == 'home') ?
                        <LazyLoadImage src="/assets/image/dott.png" className="dott" alt=""/> : null}
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-5">
                                <div className="box about">
                                    <LazyLoadImage src="/assets/image/logo-white.png" alt=""/>
                                    <p><b>Write us at</b> info@unizportal.com</p>

                                    <div className="social">
                                        <h6>Socially Connect with us</h6>
                                        <ul>
                                            <li>
                                                <a
                                                    href='https://www.linkedin.com/company/unizportalofficial'><LazyLoadImage
                                                    src='/assets/image/linkedin.svg' alt=''/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/unizportal"><LazyLoadImage
                                                    src="/assets/image/facebook.svg" alt=""/></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/unizportalofficial/"><LazyLoadImage
                                                    src="/assets/image/instagram.svg"
                                                    alt=""/></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/channel/UCfBEmsjpc1ZkHjPBgrrhJlA"><LazyLoadImage
                                                    src="/assets/image/youtube.svg" alt=""/></a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3 col-xs-6">
                                <div className="box">
                                    <ul>
                                        <li><a onClick={() => this.openPage('home')}>home</a></li>
                                        <li><a onClick={() => this.openPage('about-us')}>about</a></li>
                                        <li><a onClick={() => this.openPage('gallery')}>gallery</a></li>
                                        {/*<li><a href="/webinars">webinars</a></li>*/}
                                        {/*<li><a href="/blogs">blog</a></li>*/}
                                        <li><a onClick={() => this.openPage('contact-us')}>contact </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-6">
                                <div className="box">

                                    <ul>
                                        <h5 className={'white'}>Legal</h5>
                                        <li><a onClick={() => this.openPage('privacy-policy')}>privacy policy</a></li>
                                        <li><a onClick={() => this.openPage('terms-condition')}>terms & conditions</a>
                                        </li>
                                        <li><a onClick={() => this.openPage('terms-use')}>Terms of use</a></li>
                                        <li><a onClick={() => this.openPage('refund-policy')}>refund policy</a></li>
                                        {/*<li><a onClick={() => this.openPage('legal')}>Legal</a></li>*/}
                                        {/*<li><a onClick={() => this.openPage('share-your-experience')}>Share Your Experience</a></li>*/}
                                    </ul>

                                    <div className="line text-white">
                                        <p>© 2021 Unizportal</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
            </>
        )
    }
}


const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    pathname: router.location.pathname,
    router
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontFooter)
