import React from "react"
import {Card, Col, Icon, Row} from '../../../components/Elements/appUtils';

class AddStudentInfoComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {studentObj, universityDoc} = this.props;
        let {applications} = studentObj;
        return (
            <>
                {applications && applications._id ?
                    <div className={`commissionParentBox row`}>
                        <Card className={'commissionUniInfo'}>
                            {applications.courseUniversity && applications.courseUniversity.universityName ?
                                <div className={'studentBox'}>
                                    {applications.courseUniversity && applications.courseUniversity.logo && applications.courseUniversity.logo.url ?
                                        <div className={'studentProfile'}>
                                            <img src={applications.courseUniversity.logo.url}/>
                                        </div> : null}

                                    <div className={'studentInfo'}>
                                        <div className={'uniName'}>
                                            {applications.courseUniversity.universityName}
                                            {applications.courseUniversity && applications.universityCountry.countryName ? `, ${applications.universityCountry.countryName}` : null}
                                        </div>
                                        <div className={'courseInfo'}>
                                            {applications.courseName}
                                        </div>
                                        {applications && applications.intake && applications.intake.month ?
                                            <div className={'courseInfo sm'}>
                                                Intake : {applications.intake.month}, {applications.intake.year}
                                            </div> : null}

                                        {universityDoc && universityDoc.universityContract && universityDoc.universityContract.url ?
                                            <div className={'courseInfo sm contract'}>
                                                Download :
                                                <a href={universityDoc.universityContract.url}
                                                   target={'_blank'}> University Contract
                                                </a>
                                            </div> : null}

                                    </div>
                                </div> : null}
                        </Card>
                        <Card className={'commissionUniInfo'}>
                            <div className={'studentBox'}>
                                <div className={'studentProfile'}>
                                    <img src='../uniBtn/graduated.png'/>
                                </div>
                                <div className={'studentInfo'}>
                                    <div className={'uniName'}>{studentObj.name} (Student ID : {studentObj.studentId})
                                    </div>
                                    <div className={'courseInfo'}>
                                        (Commission :&nbsp;
                                        {universityDoc.universityCommissionType == 'Amount' ?
                                            <>{universityDoc.commissionCurrency} {universityDoc.universityCommission}</> :
                                            <>
                                                {universityDoc.universityCommission}%
                                            </>
                                        }
                                        <span> | {universityDoc.noOfUniversityCommission} Times</span>)
                                    </div>
                                    {applications.agentId && applications.agentId._id ?
                                        <div className={'courseInfo sm'}>
                                            Agent
                                            : {applications.agentId.name} ({applications.agentId.companyName})
                                        </div> : null}

                                </div>
                            </div>
                        </Card>
                    </div>
                    : null}
            </>
        )
    }
}

export default AddStudentInfoComponent
