import {apiUrl, apiUrl2} from '../../../settings'

export const getDashboardCountryWise = () => {
    return apiUrl + '/dashboard/countryWise'
}

export const getDashboardUserRecord = () => {
    return apiUrl + '/dashboard/userCount'
}

export const getDashboardUniversityCountUrl = () => {
    return apiUrl + '/dashboard/universityCount'
}

export const getDashboardUCountryCountUrl = () => {
    return apiUrl + '/dashboard/countryCount'
}

export const getUniversityPieChartCountUrl = () => {
    return apiUrl + '/dashboard/universityPieChartCount'
}
export const getDashboardAustraliaOnShoreCount = () => {
    return apiUrl + '/dashboard/australiaOnShore'
}

export const showUpdatePasswordApi = () => {
    return apiUrl + '/api/showUpdatePassword'
}
export const getDashboardCanadaOnShoreCountUrl = () => {
    return apiUrl + '/dashboard/canadaOnShore'
}
export const getUserTargetUrl = () => {
    return apiUrl + '/getUserTarget'
}
export const studentCountYearWiseUrl = () => {
    return apiUrl + '/studentCountYearWise'
}
export const studentRecordCountAgentWiseUrl = () => {
    return apiUrl + '/studentRecordCountAgentWise'
}
export const footFallRecordForBarChartUrl = () => {
    return apiUrl + '/footFallRecordForBarChart'
}
export const marketingUserForAgentsUrl = () => {
    return apiUrl + '/api/marketingUserForAgent'
}
export const directOnshoreCanadaCountUrl = () => {
    return apiUrl + '/directOnshoreCanadaCount'
}

export const visaChartYearWiseUrl = () => {
    return apiUrl + '/visaChartYearWise'
}

export const visaChartLast10dayUrl = () => {
    return apiUrl + '/visaChartLast10day'
}
export const last10dayDataReportUrl = () => {
    return apiUrl + '/last10dayDataReport'
}

export const agentCommissionGraphUrl = () => {
    return apiUrl + '/api/agent-commission-graph'
}

export const addCallBackRequestUrl = () => {
    return apiUrl2 + '/requestCallback'
}

export const requestCallBackListUrl = () => {
    return apiUrl2 + '/requestCallBackList'
}

export const updateRequestCallBackUrl = () => {
    return apiUrl2 + '/updateRequestCallBack'
}

export const gicWeeklyReportUrl = () => {
    return apiUrl2 + '/gicWeeklyReport'
}

export const agentYearWiseUrl = () => {
    return apiUrl2 + '/agentYearWise'
}

export const addCallbackFeedbackUrl = () => {
    return apiUrl2 + '/addCallbackFeedback'
}
// export const fetchingCountryUrl = () => {
//   return apiUrl2 + '/api/country-wise-app-data'
// }

export const downloadAgentProfileUrl = () => {
    return apiUrl2 + '/api/download-profile'
}

export const getCountryStatusWiseUrl = () => {
    return apiUrl2 + '/v2/country-wise'
}
