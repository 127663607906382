import {notification} from '../../../components/Elements/appUtils'
import lodash from 'lodash'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    UpdateAgentCommissionUrl,
    addStudentCommissionUrl,
    agentCommissionApprovedByManagerUrl,
    agentCommissionListUrl,
    agentCommissionReferToManagerUrl,
    agentCommissionTransactionUrl,
    agentCurrentCommissionUrl,
    allAgentCommissionListUrl,
    approvedAgentInvoiceUrl,
    calculateAgentCommissionUrl,
    cancelReceivedCommissionUrl,
    commissionClaimReceivedApi,
    commissionClaimUrl,
    commissionCountByStatusUrl,
    commissionEditReceivedApi,
    deleteCommissionUrl,
    getAgentCommissionListUrl,
    getStudentCommissionListUrl,
    getUniversityAndCourseApi,
    getUniversityCommission,
    loadExpenseTransactionsUrl,
    payAgentCommissionUrl,
    raiseAgentCommissionApi,
    receivedCommissionListUrl,
    singleAgentCommissionUrl,
    singleReceivedCommissionUrl,
    submitAgentCommissionUrl,
    universityCommissionListUrl,
    updateCommissionStatusApi,
    updateUniversityCommissionApi,
    onshoreSubmitAgentCommissionUrl,
    getServerDateUrl,
    agentCommissionTransactionForAdminUrl, getAgentCurrentCommissionUrl
} from '../apis'

export const getUniversityAndCourseFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        getUniversityAndCourseApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    return data
}

export const getCommissionDetails = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        getUniversityCommission(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateUniversityCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateUniversityCommissionApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const addStudentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        addStudentCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const universityCommissionListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    let {data} = await axios.get(universityCommissionListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const updateCommissionStatusFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateCommissionStatusApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const commissionClaimReceivedFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        commissionClaimReceivedApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const commissionEditReceivedFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        commissionEditReceivedApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const raiseAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        raiseAgentCommissionApi(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const calculateAgentCommissionFxn = async valData => {
    let {data} = await axios.post(
        calculateAgentCommissionUrl(),
        valData,
        getToken()
    )
    return data
}

export const deleteCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(deleteCommissionUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const submitAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        submitAgentCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        UpdateAgentCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const receivedCommissionListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    let {data} = await axios.get(receivedCommissionListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const agentCommissionListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    let {data} = await axios.get(agentCommissionListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const singleReceivedCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        singleReceivedCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const singleAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        singleAgentCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const payAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(payAgentCommissionUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const commissionClaimFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(commissionClaimUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const cancelReceivedCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        cancelReceivedCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const commissionCountByStatusFxn = async valData => {
    let {data} = await axios.post(
        commissionCountByStatusUrl(),
        valData,
        getToken()
    )
    return data
}
export const allAgentCommissionListFxn = valData => async dispatch => {
    // dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    console.log('run')
    let {data} = await axios.get(allAgentCommissionListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const approvedAgentInvoiceFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        approvedAgentInvoiceUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const getAgentCommissionListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    let {data} = await axios.get(getAgentCommissionListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const agentCurrentCommissionFxn = valData => async dispatch => {
    let config = {
        params: {},
        ...(await getToken())
    }
    let {data} = await axios.get(agentCurrentCommissionUrl(), config)
    return data
}

export const agentCommissionTransactionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    dispatch(hidePageLoad())
    let {data} = await axios.get(agentCommissionTransactionUrl(), config)
    return data.data
}
export const loadExpenseTransactions = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    dispatch(hidePageLoad())
    let {data} = await axios.get(loadExpenseTransactionsUrl(), config)
    return data.data
}

export const agentCommissionReferToManagerFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        agentCommissionReferToManagerUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const agentCommissionApprovedByManagerFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        agentCommissionApprovedByManagerUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const onshoreSubmitAgentCommissionFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        onshoreSubmitAgentCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const getServerDateFxn = async () => {
    let config = {}
    let {data} = await axios.get(getServerDateUrl(), config)
    return data
}

export const agentCommissionTransactionForAdminFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    dispatch(hidePageLoad())
    let {data} = await axios.get(agentCommissionTransactionForAdminUrl(), config)
    return data.data
}


export const getAgentCurrentCommissionFxn = valData => async dispatch => {
    let config = {
        params: {...valData},
        ...(await getToken())
    }
    let {data} = await axios.get(getAgentCurrentCommissionUrl(), config)
    return data
}