export default (
    state = {
        dashboardCountryData: {}
    },
    action
) => {
    switch (action.type) {
        case 'DASHBOARD_COUNTRY_WISE_DATA':
            return {...state, dashboardCountryData: action.payload}
        default:
            return state
    }
}
