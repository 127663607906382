import {apiUrl} from "../../../settings";

export const addCampusUrl = () => {
    return apiUrl + '/campus';
};

export const listAllCampusUrl = () => {
    return apiUrl + '/campus';
};
export const listCampusUrl = (id) => {
    return apiUrl + '/campus/' + id
}
export const CourseCampus = () => {
    return apiUrl + '/api/courseCampus'
}