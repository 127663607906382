import {notification} from '../../../components/Elements/appUtils'
import {getToken, customAxios as axios} from '../../../request'
import {
  addScheduleMeetingUrl,
  listScheduleMeetingUrl,
  singleScheduleMeetingUrl,
  scheduleMeetingUsersListUrl,
  updateMeetingStatusUrl,
  checkMeetingTokenUrl,
  newAddScheduleMeetingUrl, addNewAddScheduleMeetingUrl,
  addFollowupUrl, allMeetingListUrl
} from '../api/scheduleMeeting'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addScheduleMeeting = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addScheduleMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const NewAddScheduleMeeting = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let {data} = await axios.post(addNewAddScheduleMeetingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const listAllScheduleMeeting = (filters) => async (dispatch) => {
  let config = {
    params: {...filters},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }
  let {data} = await axios.get(listScheduleMeetingUrl(), config)
  // console.log(data)
  dispatch({type: 'LIST_ALL_SCHEDULE_MEETING', payload: data.data})
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data

}


export const getScheduleMeeting = id => async (dispatch) => {
  let config = {
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }
  let {data} = await axios.get(singleScheduleMeetingUrl(id), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({type: 'LIST_SCHEDULE_MEETING', payload: data})
  }
  return data.data
}


export const NewMeetingList = (filters) => async (dispatch) => {
  let config = {
    params: {...filters},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
  }
  let {data} = await axios.get(newAddScheduleMeetingUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    dispatch({type: 'LIST_SCHEDULE_MEETING', payload: data})
  }
  return data.data
}


export const updateScheduleMeeting = (valData, id) => async (dispatch) => {
  let {data} = await axios.put(singleScheduleMeetingUrl(id), valData, getToken())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const scheduleMeetingUsersList = () => async (dispatch) => {
  let { data } = await axios.post(scheduleMeetingUsersListUrl(), {})

  return data
}


export const checkMeetingToken = (valData, id) => async (dispatch) => {
  let { data } = await axios.post(checkMeetingTokenUrl(), valData)
  return data
}
export const updateMeetingStatus = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateMeetingStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}
export const addFollowupFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addFollowupUrl(), valData, await getToken())


  dispatch(hidePageLoad())
  // if (data.error) {
  //   notification.error({
  //     message: data.message || 'Error'
  //   })
  // } else {
  //   notification.success({
  //     message: data.message || 'Success'
  //   })
  // }
  return data
}
export const followupListFxn = filters => async dispatch => {
  let config = {
    params: {...filters},
    ...(await getToken())
  }
  let {data} = await axios.get(addFollowupUrl(), config)
  return data
}
export const allMeetingListFxn = (filters) => async (dispatch) => {
  let config = {
    params: {...filters},
    ...await getToken()
  }
  let {data} = await axios.get(allMeetingListUrl(), config)
  return data.data
}