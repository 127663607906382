import React, {Component} from 'react'
import {TableComp} from '../../../components/Elements/appUtils'
import {
    Card,
    Tooltip,
    Icon, Drawer, List, Popconfirm
} from '../../../components/Elements/appUtils'
import {connect} from 'react-redux'
import {listAllUsers, blockAgent} from '../actions/user'
import {ConditionOptions, DefaultTablePagination, departmentList} from '../../../components/_utils/appUtils'
import UpdatePassword from '../views/changePassword'
import EditUser from '../views/edit'
import EditSubAgent from '../views/editSubAgent'
import {CheckMyRights} from '../../WebComponent/CheckUserRights'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {push} from 'connected-react-router'
import UserRightList from './rightsList'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import LogsComponents from '../drawer/logsComponents'

const userTypeOptions = [
    {value: 'agent', text: 'Agent'},
    {value: 'subAgent', text: 'Sub Agent'}
]
const commissionType = [
    {text: 'Silver', value: 'silver'},
    {text: 'Gold', value: 'gold'},
    {text: 'Platinum', value: 'platinum'}
]

const agentTypeList = [
    {text: 'Sub Agent', value: 'subAgent'},
    {text: 'Team', value: 'team'}
]

class AllUsers extends Component {

    events = {
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleUserRightDrawer: false,
            total: ''
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadAllAgent()
    }

    async loadAllAgent() {
        let {dispatch} = this.props
        let params = {
            userType: 'agent', results: 1000,
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        this.setState({allAgents: data})
    }


    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }
    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    apiRequest = (params) => {
        let {dispatch} = this.props
        return new Promise(async (resolve) => {
            params.sortField = 'name'
            params.sortOrder = 'ascend'

            if (!params.userType || (params.userType && params.userType.length == 0)) {
                params.userType = ['subAgent', 'team']
            }
            let resp = await dispatch(listAllUsers({
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email']
            }))
            this.setState({total: resp.total})
            resolve(resp)
            // resolve(dispatch(listAllUsers({
            //   ...params,
            //   regExFilters: ['name', 'address', 'mobile', 'email']
            // })))
        })
    }

    render() {
        let {
            selectedRow,
            allAgents,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUpdateSubAgent,
            visibleUserRightDrawer
        } = this.state
        let {dispatch} = this.props
        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                searchTextName: 'name',
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Type',
                key: 'userType',
                sorter: true,
                dataIndex: 'userType',
                width: 100,
                filters: agentTypeList.map(x => ({value: x.value, text: x.text})),
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>
                            {item == 'subAgent' ? 'Sub Agent' : ''}
                            {item == 'team' ? 'Team' : ''}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 150,
                render: (item) => {
                    return (
                        <ColumnWidth width={150}>{item}</ColumnWidth>
                    )
                }
            },

            {
                title: 'Agent',
                dataIndex: 'agentId',
                key: 'agentId',
                filters: allAgents.map(x => ({value: x._id, text: `${x.name} (${x.companyName})`})),
                width: 100,
                render: (item) => {
                    return (
                        <ColumnWidth width={100}>{item && item.name ? item.name : ''}</ColumnWidth>
                    )
                }

            },

            {
                title: 'Logo',
                dataIndex: 'logo',
                key: 'logo',
                width: 100,
                render: (val) => {
                    return (
                        <ColumnWidth width={100}>  {val && val.url ?
                            <img src={val.url} className={'imgSm'}/> : null}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Block',
                dataIndex: 'block',
                width: 80,
                key: 'block',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {record && record.userType && (record.userType !== 'admin') ?
                                <div>
                                    <Popconfirm
                                        title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
                                        onConfirm={() => {
                                            this.updateAgentBlockStatus(record._id)
                                        }}
                                        okText='Yes' cancelText='No'>
                                        {item ? <div className={'label label-success labelNew'}>Yes</div> :
                                            <div className={'label label-warning labelNew'}>No</div>}
                                    </Popconfirm>
                                </div> : null}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 150,
                render: (val, record) => {
                    return (
                        <React.Fragment>
                            {record.userType !== 'master' ?
                                <Tooltip title='Edit'>
                                    <button
                                        className={'btn'}
                                        style={{marginRight: 6}}
                                        onClick={() => {
                                            {
                                                record.userType == 'subAgent' ?
                                                    this.setState({
                                                        visibleUpdateSubAgent: true,
                                                        selectedUser: record
                                                    }) :
                                                    this.setState({
                                                        visibleUpdateUser: true,
                                                        selectedUser: record
                                                    })
                                            }
                                        }}>
                                        <Icon type={'edit'}/>
                                    </button>
                                </Tooltip> : null}
                            <Tooltip title='Change Password'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        this.setState({
                                            selectedUser: record,
                                            visibleUpdatePassword: true
                                        })
                                    }}>
                                    <Icon type={'key'}/>
                                </button>
                            </Tooltip>
                            {record.userType == 'branchUser' ?
                                <Tooltip title='Universities'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.viewInfo(record)
                                        }}
                                    >
                                        <Icon type={'eye'}/>
                                    </button>
                                </Tooltip> : ''}

                            <CheckUserRight user={this.props.currentUser} rightUserType={['admin', 'branchManager']}>
                                <Tooltip title='Rights'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.events.showUserRightDrawer(record)
                                        }}
                                    ><Icon type='trophy'/>
                                    </button>
                                </Tooltip>
                            </CheckUserRight>
                            <LogsComponents userData={record} {...this.props} currentUser={this.props.currentUser}/>
                        </React.Fragment>
                    )
                }
            }
        ]
        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Sub Agents & Teams ({this.state.total})</h5>
                                <div className='search-box-table'>

                                </div>

                                <CheckMyRights rightList={['admin']}>
                                    <div className='sort-box-table mark-btn'>
                                    </div>
                                </CheckMyRights>
                            </div>
                            <div className='card-body table-responsive'>
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <Drawer visible={this.state.viewInfoDrawer}
                        width={520}
                        onClose={this.onClose}>
                    <Card title={'Universities'}>
                        {selectedRow && selectedRow.universities && selectedRow.universities.length ?
                            <List
                                size='small'
                                bordered
                                dataSource={selectedRow.universities}
                                renderItem={item =>
                                    <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
                            /> : null
                        }

                    </Card>
                </Drawer>
                {visibleUpdatePassword ? <UpdatePassword
                    visible={visibleUpdatePassword}
                    onClose={() => this.closeUpdatePassword()}
                    userData={selectedUser}/> : ''}

                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}
                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}


                {visibleUserRightDrawer ? <UserRightList
                    user={selectedUser}
                    visible={visibleUserRightDrawer}
                    onClose={() => this.events.hideUserRightDrawer()}
                    closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}/> : ''}

            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
