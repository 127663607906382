import {
    Icon,
    Tooltip
} from '../../../components/Elements/appUtils'
import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {TableComp} from '../../../components/Elements/appUtils'
import {
    DefaultTablePagination
} from '../../../components/_utils/appUtils'
import {unizHomeEnquiryFxn} from '../action'
import SingleStudentEnquiryList from './studentInformationDrawer'

const StudentList = props => {
    const [details, setDetails] = useState([])
    const [total, setTotal] = useState(0)
    const [visible, setVisible] = useState(false)
    const [studentId, setStudentId] = useState('')
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const tableRef = useRef()
    const dispatch = useDispatch()

    const openStudentInfomation = id => {
        setStudentId(id)
        setVisible(true)
    }
    const onClose = () => {
        setStudentId('')
        setVisible(false)
    }
    const apiRequest = params => {
        return new Promise(async resolve => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            let resp = await dispatch(
                unizHomeEnquiryFxn({
                    ...params,
                    regExFilters: [
                        'name',
                        'enrollmentBasic',
                        'enrollmentProcedure',
                        'fundSponsors'
                    ]
                })
            )
            setTotal(resp.total)
            console.log(resp.data[0], 'response')
            resolve(resp)
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Room',
            dataIndex: 'name',
            key: 'name',
            searchTextName: 'name'
        },
        {
            title: 'Name',
            dataIndex: 'resident_details',
            key: 'resident_details',
            width: 120,
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.resident_details &&
                            record.resident_details.first_name ?
                                record.resident_details.first_name : null
                        }
                        &nbsp;
                        {
                            record &&
                            record.resident_details &&
                            record.resident_details.last_name ?
                                record.resident_details.last_name : null
                        }
                    </p>
                )
            }
        },
        {
            title: 'Resident Email',
            dataIndex: 'email',
            key: 'email',
            render: (val, record) => {
                return (
                    <p>
                        {record && record.resident_details && record.resident_details.email ? record.resident_details.email : null}
                    </p>
                )
            }
        },

        {
            title: 'Number',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (val, record) => {
                return (
                    <p>
                        {record && record.supporting_contact_details && record.supporting_contact_details.contact_number ? record.supporting_contact_details.contact_number : null}
                    </p>
                )
            }
        },
        {
            title: 'Booking Period',
            dataIndex: 'booking_periods',
            key: 'booking_periods',
            width: 190,
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.booking_periods &&
                            record.booking_periods.start_date ?
                                record.booking_periods.start_date : null
                        }
                        &nbsp;-&nbsp;
                        {
                            record &&
                            record.booking_periods &&
                            record.booking_periods.end_date ?
                                record.booking_periods.end_date : null
                        }
                    </p>
                )
            }
        },
        {
            title: 'Booked By',
            dataIndex: 'userId',
            key: 'userId',
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.userId &&
                            record.userId.name ?
                                record.userId.name : null
                        }
                    </p>
                )
            }
        },

        // {
        //   title: 'Expected Arrival Date',
        //   dataIndex: 'expectedArrivalDate',
        //   key: 'expectedArrivalDate',
        //   searchTextName: 'expectedArrivalDate',
        //   render: val => {
        //     return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
        //   }
        // },
        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 150,
            render: (val, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title='View Details'>
                            <button
                                className={'btn'}
                                onClick={() => {
                                    openStudentInfomation(record._id)
                                    setDetails(record)
                                }}>
                                <Icon type={'eye'}/>
                            </button>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }
        //   {
        //     title: 'University',
        //     dataIndex: 'university',
        //     key: 'university',
        //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
        //     render: item => {
        //       return item
        //     }
        //   },
        //   {
        //     title: 'Additional Message',
        //     dataIndex: 'additionalMessage',
        //     key: 'additionalMessage',
        //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
        //     render: item => {
        //       return item
        //     }
        //   },
        //   {
        //     title: 'Offer Code',
        //     dataIndex: 'offerCode',
        //     key: 'offerCode',
        //     // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
        //     render: item => {
        //       return item
        //     }
        //   },
        //   {
        //     title: 'Property',
        //     dataIndex: 'property',
        //     key: 'property',
        //     render: (item, record) => {
        //       return <>{item.address}</>
        //     }
        //   },
        //   {
        //     title: 'Communication Methods',
        //     dataIndex: 'communicationMethod',
        //     key: 'communicationMethod',
        //     render: (item, record) => {
        //       return <>{item.address}</>
        //     }
        //   },
        //   {
        //     title: 'How Did You Find Us?',
        //     dataIndex: 'findUs',
        //     key: 'findUs',
        //     render: (item, record) => {
        //       return <>{item.address}</>
        //     }
        //   },
        //   {
        //     title: 'Room Details',
        //     dataIndex: 'roomDetails',
        //     key: 'roomDetails',
        //     render: (item, record) => {
        //       let { property } = record
        //       let { room } = property
        //       return (
        //         <>
        //           Room : {room.room_name}
        //           <br />
        //           Start : {item.start_date}
        //           <br />
        //           End : {item.end_date}
        //           <br />
        //           Price : {item.price}
        //           <br />
        //         </>
        //       )
        //     }
        //   }
    ]
    const columns2 = [
        {
            title: 'Property Name',
            dataIndex: 'property',
            key: 'property',
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.property &&
                            record.property.name ?
                                record.property.name : null
                        }
                    </p>
                )
            }
        },
        {
            title: 'Emergency Contact',
            dataIndex: 'supporting_contact_details',
            key: 'supporting_contact_details',
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.supporting_contact_details &&
                            record.supporting_contact_details.first_name ?
                                `Full Name - ${record.supporting_contact_details.first_name}` : null
                        }
                        &nbsp;
                        {
                            record &&
                            record.supporting_contact_details &&
                            record.supporting_contact_details.last_name ?
                                record.supporting_contact_details.last_name : null
                        }<br/>
                        {
                            record &&
                            record.supporting_contact_details &&
                            record.supporting_contact_details.contact_number ?
                                `Contact - ${record.supporting_contact_details.contact_number}` : null
                        }<br/>
                        {
                            record &&
                            record.supporting_contact_details &&
                            record.supporting_contact_details.email ?
                                `Email - ${record.supporting_contact_details.email}` : null
                        }
                    </p>
                )
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.address &&
                            record.address.firstLine ?
                                record.address.firstLine : null
                        }
                        &nbsp;
                        {
                            record &&
                            record.address &&
                            record.address.secondLine ?
                                record.address.secondLine : null
                        }&nbsp;
                        {
                            record &&
                            record.address &&
                            record.address.city ?
                                record.address.city : null
                        }&nbsp;
                        {
                            record &&
                            record.address &&
                            record.address.country ?
                                record.address.country : null
                        }
                    </p>
                )
            }
        },
        {
            title: 'Course Details',
            dataIndex: 'course_details',
            key: 'course_details',
            render: (val, record) => {
                return (
                    <p>
                        {
                            record &&
                            record.course_details &&
                            record.course_details.course_title ?
                                `Name - ${record.course_details.course_title}` : null
                        }
                        <br/>
                        {
                            record &&
                            record.course_details &&
                            record.course_details.university ?
                                `University - ${record.course_details.university}` : null
                        }<br/>
                        {
                            record &&
                            record.course_details &&
                            record.course_details.year_of_study ?
                                `Year Of Study - ${record.course_details.year_of_study}` : null
                        }
                    </p>
                )
            }
        }
    ]

    return (
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <div className='card'>
                    <div className='table-head  align-items-center all-student-search'>
                        <h6>Agent Enquiries List : {total || 0}</h6>
                    </div>
                    <div className='card-body table-responsive'>
                        <TableComp
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            pagination={DefaultTablePagination()}
                        />
                    </div>
                </div>
            </div>
            {visible ? (
                <SingleStudentEnquiryList
                    visible={visible}
                    data={details}
                    columns2={columns2}
                    setVisible={setVisible}
                    onClose={onClose}
                />
            ) : null}
        </div>
    )
}

export default StudentList
