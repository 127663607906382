import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Tooltip, Icon, Popconfirm} from '../../../components/Elements/appUtils'
import {blockAgent, otherUsersFxn} from '../actions/user' // Update with your user actions
import UpdatePassword from '../views/changePassword'
import {TableComp} from '../../../components/Elements/appUtils'
import {DefaultTablePagination, ConditionOptions} from '../../../components/_utils/appUtils'
import EditOtherUser from '../drawer/editOtherUser'
import LogsComponents from '../drawer/logsComponents'
import S from 'string'


class UserManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: [],
            userTypeArr: [],
            totalUsers: 0,
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleEditUser: false,
            visibleBlockConfirm: false,
            filters: {
                name: '',
                email: '',
                mobile: '',
                country: '',
                stateName: '',
                cityName: '',
                userType: '',
                address: '',
                pincode: ''
            }

        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {

    }


    handleFilterChange = (field, value) => {
        this.setState(
            prevState => ({
                filters: {
                    ...prevState.filters,
                    [field]: value
                }
            }),
            () => {
                this.tableRef.current.reload()
            }
        )
    }
    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.regExFilters = ['name', 'email', 'mobile', 'address']
            const data = await otherUsersFxn(params)
            this.setState({
                totalUsers: data.total || 0,
                userTypeArr: data.userTypeArr
            })
            resolve(data)
        })

    }

    reloadTable = () => {
        this.tableRef.current.reload()
    }

    blockUser = async (id) => {
        const {dispatch} = this.props
        try {
            const resp = await dispatch(blockAgent({agentId: id}))
            if (resp && resp.success) {
                this.reloadTable()
            }
        } catch (error) {
            console.error('Error blocking user:', error)
        }
    }

    render() {
        const {
            userTypeArr,
            selectedUser,
            visibleUpdatePassword,
            visibleEditUser
        } = this.state

        const columns = [
            {
                title: '#',
                dataIndex: '_id',
                key: '_id',
                width: 50,
                render: (text, record, index) => index + 1
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name',
                width: 150,
                render: text => <span>{text}</span>
            },

            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                width: 180
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 120
            },

            {
                title: 'State Name',
                dataIndex: 'stateName',
                key: 'stateName',
                searchTextName: 'stateName',
                width: 180
            },
            {
                title: 'City Name',
                dataIndex: 'cityName',
                key: 'cityName',
                searchTextName: 'cityName',
                width: 120
            },
            {
                title: 'Pincode',
                dataIndex: 'pincode',
                key: 'pincode',
                searchTextName: 'pincode',
                width: 100
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 150
            },
            {
                title: 'User Type',
                dataIndex: 'userType',
                key: 'userType',
                width: 150,
                filters: userTypeArr.map(x => ({value: x, text: x})),
                render: (item) => {
                    return item ? S(item).humanize().s : null
                }
            },
            {
                title: 'Block',
                dataIndex: 'block',
                width: 100,
                key: 'block',
                filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
                render: (item, record) => {
                    return (
                        <React.Fragment>
                            {record && record.userType && record.userType !== 'admin' ? (
                                <div>
                                    <Popconfirm
                                        title={`Are you sure you want to ${item ? 'Unblock' : 'Block'} this User?`}
                                        onConfirm={() => {
                                            this.blockUser(record._id)
                                        }}
                                        okText='Yes'
                                        cancelText='No'
                                    >
                                        {item ? (
                                            <label className='label label-success labelNew'>Yes</label>
                                        ) : (
                                            <label className='label label-warning labelNew'>No</label>
                                        )}
                                    </Popconfirm>
                                </div>
                            ) : null}
                        </React.Fragment>
                    )
                },
                onFilter: (value, record) => {
                    if (value === true) {
                        return record.block === true
                    } else if (value === false) {
                        return record.block === false || record.block === undefined
                    }
                    return true
                }
            },


            {
                title: 'Actions',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (text, record) => (
                    <span>
            <Tooltip title='Edit'>
              <button
                  className={'btn'}
                  style={{marginRight: 6}}
                  onClick={() =>
                      this.setState({
                          selectedUser: record,
                          visibleEditUser: true
                      })
                  }
              ><Icon type={'edit'}/></button>
            </Tooltip>

            <Tooltip title='Change Password'>
            <button
                className={'btn'}
                style={{marginRight: 6}}
                onClick={() => {
                    this.setState({
                        selectedUser: record,
                        visibleUpdatePassword: true
                    })
                }}>
                <Icon type={'key'}/>
            </button>
        </Tooltip>
        <LogsComponents userData={record} {...this.props} currentUser={this.props.currentUser}/>
          </span>
                )
            }
        ]

        return (
            <div>
                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Users ({this.state.totalUsers})</h5>
                            </div>
                            <div className='card-body table-responsive'>
                                <TableComp columns={columns}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {visibleEditUser ? (
                    <EditOtherUser
                        visible={visibleEditUser}
                        onClose={() => {
                            this.setState({visibleEditUser: false})
                            this.reloadTable()
                        }}
                        userData={selectedUser}
                    />
                ) : null}

                {visibleUpdatePassword && (
                    <UpdatePassword
                        visible={visibleUpdatePassword}
                        userData={selectedUser}
                        onClose={() => this.setState({visibleUpdatePassword: false})}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser
})

export default connect(mapStateToProps)(UserManagement)
