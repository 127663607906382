import React, {useEffect, useState} from 'react'
import {Card, Drawer, Popconfirm} from '../../../components/Elements/appUtils'
import {RowTable} from '../../../components/_utils/RowTable'
import {useDispatch} from 'react-redux'
import {addAgentRightFxn, addUpdateAgentRightFxn, singleUserRightFxn} from '../actions/user'

let directUniRightList = [
    {
        name: 'Cornerstone International Community College of Canada',
        field: 'allowCornerStone',
        postName: 'Cornerstone College'
    },
    {name: 'Canada International Royal Arts College', field: 'allowCira', postName: 'CIRA College'}
    // { name: 'National College', field: 'allowNationalCollege' }
]

let agentRightList = [
    {name: 'Advance Commission Agreement', field: 'showAdvanceContract'}
]

const DirectUniversityRights = (props) => {
    let dispatch = useDispatch()
    let {visible, agentData, onClose, onSubmit} = props
    let [rights, setRights] = useState([])

    useEffect(() => {
        events.loadData()
    }, [agentData])

    let events = {
        loadData: async () => {
            let {data} = await dispatch(singleUserRightFxn({userId: agentData._id}))
            setRights(data)
        },
        updateAgentRight: async (valData) => {
            let {success} = await dispatch(addAgentRightFxn({userId: agentData._id, ...valData}))
            if (success) {
                events.loadData()
            }
        },
        updateAgentRightFxn: async (valData) => {
            let {success} = await dispatch(addUpdateAgentRightFxn({userId: agentData._id, ...valData}))
            if (success) {
                events.loadData()
            }
        }
    }

    return (
        <Drawer title={'Update Agent Rights'} visible={visible} onClose={onClose} width={'45%'}>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='form-box'>
                        <div className='card unizportal'>
                            {agentData && agentData.companyName ? (
                                <div className={'tableBox striped'}>
                                    <RowTable
                                        title={'Name'}
                                        value={agentData.name}
                                    />
                                    <RowTable
                                        title={'Company Name'}
                                        value={agentData.companyName}
                                    />
                                    <RowTable
                                        title={'Email'}
                                        value={agentData.email}
                                    />
                                </div>
                            ) : null}
                            <br/>
                            <Card title={'Direct Universities'} size={'small'}>
                                <div className={'tableBox striped'}>
                                    {directUniRightList && directUniRightList.length ? directUniRightList.map((item) => {
                                        let value = rights && rights[item.field] ? true : false
                                        return (
                                            <>
                                                <RowTable
                                                    labelWidth={'65%'}
                                                    key={item.name}
                                                    title={item.name}
                                                    value={<>
                                                        <Popconfirm
                                                            title1={`Are you sure, you want to ${value ? 'Block' : 'Allow'} this agent?`}
                                                            title={`Are you Sure, you want to ${value ? 'Close' : 'Open'} this Agent for ${item.postName}?`}
                                                            onConfirm={() => {
                                                                events.updateAgentRight({[item.field]: value ? false : true})
                                                            }}>
                                                            <a className={`btn btn-xs ${value ? 'btn-success' : 'btn-danger'}`}>
                                                                {rights && rights[item.field] ? 'Opened' : 'Closed'}
                                                            </a>
                                                        </Popconfirm>
                                                    </>}
                                                />
                                            </>
                                        )
                                    }) : null}
                                </div>
                            </Card>
                            <Card title={'Agent Rights'} size={'small'}>
                                <div className={'tableBox striped'}>
                                    {agentRightList && agentRightList.length ? agentRightList.map((item) => {
                                        let value = rights && rights[item.field] ? true : false
                                        return (
                                            <>
                                                <RowTable
                                                    labelWidth={'65%'}
                                                    key={item.name}
                                                    title={item.name}
                                                    value={<>
                                                        <Popconfirm
                                                            title={`Are you sure, you want to ${value ? 'Disabled' : 'Enabled'} this agent?`}
                                                            onConfirm={() => {
                                                                events.updateAgentRightFxn({[item.field]: value ? false : true})
                                                            }}>
                                                            <a className={`btn btn-xs ${value ? 'btn-success' : 'btn-danger'}`}>
                                                                {rights && rights[item.field] ? 'Enabled' : 'Disabled'}
                                                            </a>
                                                        </Popconfirm>
                                                    </>}
                                                />
                                            </>
                                        )
                                    }) : null}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}
export default DirectUniversityRights
