import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Popconfirm, Tooltip} from '../../../components/Elements/appUtils'
import {AllowComponentRightsWise} from '../../WebComponent/allowComponentRightsWise'
import {moveToDraftApplication} from '../../student/actions/student'
import {useDispatch} from 'react-redux'
import {countryIds} from "../../../components/_utils/appUtils";

const DraftButtonComponent = (props) => {
    let {currentUser, record, callback} = props
    let dispatch = useDispatch()
    const moveToDraftApplicationFxn = async (record) => {
        let obj = {
            studentId: record._id,
            applicationId: record.applications._id
        }
        let resp = await dispatch(moveToDraftApplication(obj))
        if (callback) {
            callback()
        }
    }
    let title = 'Are your sure, you want to move to Draft?'
    if (record.applications.draftApplication) {
        title = 'Are your sure, you want to move from Draft?'
    }

    let checkCond = () => {
        let {universityCountry, status} = record.applications;
        /* if (universityCountry && universityCountry._id && universityCountry._id == countryIds.australia) {
             if (status == 'Offer Applied') {
                 return true;
             } else {
                 return false;
             }
         } else {
             if (status == 'Waiting To Apply') {
                 return true;
             } else {
                 return false;
             }
         }*/
        if (status == 'Waiting To Apply') {
            return true;
        } else {
            return false;
        }
    }
    useEffect(() => {
        checkCond()
    }, [])

    return (
        <>
            {checkCond() ?
                <Tooltip title={'Draft'}>
                    <Popconfirm title={title}
                                onConfirm={() => {
                                    moveToDraftApplicationFxn(record)
                                }}
                                okText='Yes' cancelText='No'>
                        <button className='btn'><img src='../dist/img/draft.png' alt=''/></button>
                    </Popconfirm>
                </Tooltip> : null}
        </>
    )
}

const mapStateToProps = (
    {
        global, router
    }
) => (
    {
        currentUser: global.currentUser
    }
)
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftButtonComponent)
