import React from "react"
import _ from 'lodash'
import {
    cambieUniversity, CornerStoneCollege,
    fcmtUniversity, InputBox, LesterCollege,
    marketingUniversity, NationalCollege, oxfordUniversity,
    RoyalArtsCollege
} from '../../../components/_utils/appUtils'
import {
    australiaApplicationStatusSteps,
    canadaApplicationStatusSteps,
    canadaOnShoreApplicationStatusSteps,
    cornerStoneStatusList,
    fcmtStatusList,
    germanyApplicationStatusSteps,
    lesterStatusList,
    nationalCollegeStatusList,
    oxfordStatusList,
    singaporeApplicationSteps,
    ukApplicationStatusSteps,
    usaApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'
import {Checkbox, Icon, InputNumber, Popconfirm} from '../../../components/Elements/appUtils'

class MarketingComponent extends React.Component {
    localEvents = {
        defaultField: () => {
            let {data} = this.props;
            if (data.country) {
                this.setState({
                    country: data.country,
                    countryId: data.countryId
                }, () => {
                    this.localEvents.getUniArr()
                })
            }
        },
        _updateState: (data) => {
            this.setState({
                ...data
            }, () => {
                this.localEvents.getUniArr()
            })
        },
        getUniArr: () => {
            let {data} = this.props;
            let {country} = this.state;
            if (country) {
                let uniData = marketingUniversity[`${country}List`] ? marketingUniversity[`${country}List`] : marketingUniversity.others
                let arrD = []
                _.each(uniData, (item) => {
                    let findDoc = _.find(data.universityList, (newItem) => {
                        return newItem.university == item.value
                    })
                    if (findDoc) {
                        arrD.push({
                            university: findDoc.university,
                            universityName: findDoc.universityName,
                            status: findDoc.status,
                            target: findDoc.target,
                            value: findDoc.value
                        })
                    } else {
                        arrD.push({
                            university: item.value,
                            universityName: item.label,
                            status: item.status ? item.status : "",
                        })
                    }
                })
                this.setState({
                    universityList: arrD
                })
            }
        },
        getStatusList: (university) => {
            let {country} = this.state;
            let countryName = _.clone(country);
            if (countryName == 'canada onshore') {
                countryName = 'canadaOnShore'
            }
            let obj = {
                canadaApplicationStatusSteps,
                germanyApplicationStatusSteps,
                ukApplicationStatusSteps,
                australiaApplicationStatusSteps,
                singaporeApplicationSteps,
                usaApplicationStatusSteps,
                canadaOnShoreApplicationStatusSteps
            }
            if (university == 'Public') {
                if (`${countryName}ApplicationStatusSteps`) {
                    return obj[`${countryName}ApplicationStatusSteps`]
                } else {
                    return []
                }
            } else {
                if (university == fcmtUniversity || university == cambieUniversity || university == RoyalArtsCollege) {
                    return fcmtStatusList
                }
                if (university == LesterCollege) {
                    return lesterStatusList
                } else if (university == oxfordUniversity) {
                    return oxfordStatusList
                } else if (university == CornerStoneCollege) {
                    return cornerStoneStatusList
                } else if (university == NationalCollege) {
                    return nationalCollegeStatusList
                } else {
                    return []
                }
            }
        },
        _updateUniversity: (data, index) => {
            let {universityList} = this.state;
            let cloneEdu = _.clone(universityList);
            cloneEdu[index] = {...cloneEdu[index], ...data}
            this.setState({
                universityList: cloneEdu
            }, () => {
                this.localEvents.updateRecord()
            })
        },
        updateRecord: () => {
            let {marketingEvents, marketingIndex} = this.props;
            let {countryId, country, universityList} = this.state
            marketingEvents.updateMarketingCountries({countryId, country, universityList}, marketingIndex)
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            countryId: "",
            country: "",
            universityList: []
        }
    }

    componentDidMount() {
        this.localEvents.defaultField()
    }

    render() {
        let {data, marketingIndex, marketingEvents, countryList} = this.props;
        let {country, universityList} = this.state;
        return (
            <div>
                <div className="row marketing_country_box">
                    <div className="col-md-12">
                        <InputBox title={"Country List"}>
                            <div className={'row'}>
                                <div className={"col-md-3"}>
                                    <select
                                        className={"form-control"}
                                        value={data.countryId}
                                        onChange={({target}) => {
                                            let findCountryName = _.find(countryList, (item) => {
                                                return item._id.toString() == target.value
                                            })
                                            let obj = {
                                                countryId: target.value
                                            }
                                            if (findCountryName) {
                                                let country = findCountryName.countryName.toLowerCase();
                                                obj.country = country
                                            }
                                            this.localEvents._updateState(obj)

                                        }}>
                                        <option value={""}>Choose Country</option>
                                        {countryList && countryList.length && countryList.map((item) => {
                                            return (
                                                <option key={item._id} value={item._id}>
                                                    {item.countryName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className={"col-md-7 offset-1"} style={{padding: 0}}>
                                    {country ? <>
                                        {universityList.map((item, uniIndex) => {
                                            return (
                                                <div className={'row uni_row'} key={item.label}>
                                                    <div className={'col-md-4'}>
                                                        <Checkbox
                                                            checked={item.value}
                                                            value={item.university}
                                                            onChange={({target}) => {
                                                                this.localEvents._updateUniversity({value: target.checked}, uniIndex)
                                                            }}>
                                                            {item.universityName}
                                                        </Checkbox>
                                                    </div>
                                                    <div className={'col-md-4'}>
                                                        <select
                                                            className={'form-control sm'}
                                                            value={item.status}
                                                            onChange={({target}) => {
                                                                this.localEvents._updateUniversity({status: target.value}, uniIndex)
                                                            }}>
                                                            <option value={""}>Choose Status</option>
                                                            {this.localEvents.getStatusList(item.university) &&
                                                            this.localEvents.getStatusList(item.university).length ?
                                                                this.localEvents.getStatusList(item.university).map((eachU) => {
                                                                    return (
                                                                        <option value={eachU.name}
                                                                                key={eachU.name}>{eachU.name}</option>
                                                                    )
                                                                }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={'col-md-4'}>
                                                        <InputNumber
                                                            className={'form-control sm'}
                                                            onChange={(value) => {
                                                                this.localEvents._updateUniversity({target: value}, uniIndex)
                                                            }}
                                                            value={item.target}
                                                            placeholder={'Enter target'}/>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </> : null}


                                </div>
                                <div className={'col-md-1'}>
                                    {marketingIndex > 0 ?
                                        <Popconfirm
                                            title={'Remove marketing record'}
                                            onConfirm={() => {
                                                marketingEvents.deleteItem(marketingIndex)
                                            }}>
                                            <a className={'btn btn-danger btn-sm'}>
                                                <Icon type={'delete'}/>
                                            </a>
                                        </Popconfirm> : null}
                                </div>
                            </div>


                        </InputBox>
                    </div>
                </div>
            </div>
        );
    }
}

export default MarketingComponent
