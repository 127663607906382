import {notification} from '../../../components/Elements/appUtils'
import {getToken, customAxios as axios} from '../../../request'
import {
    activateInstituteUrl,
    addInstituteEventUrl,
    addSingleSlotUrl,
    addSlotUrl,
    instituteListUrl,
    slotListUrl,
    deleteSlotUrl,
    updateSlotUrl,
    instituteEventListUrl,
    deleteInstituteEventUrl,
    updateInstituteEventUrl,
    deleteSlotByDateUrl,
    updateInstituteEventStatusUrl,
    updateInstituteEventDateUrl,
    addInstituteCommentUrl,
    instituteEventsCommentsUrl, appliedEventsListUrl, singleInstituteEventUrl, activityListUrl, instituteStudentListUrl,
    approvedEventListUrl
} from '../api'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'

export const instituteList = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(instituteListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const activateInstitute = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(activateInstituteUrl(), {...valData}, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const addInstituteEvent = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addInstituteEventUrl(), {...valData}, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const instituteListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(instituteListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const instituteEventListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(instituteEventListUrl(), config)
    dispatch(hidePageLoad())
    return data
}


export const deleteInstituteEventFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(deleteInstituteEventUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateInstituteEventFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInstituteEventUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Deleted Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const addSlotFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addSlotUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const slotListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(slotListUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const addSingleSlotFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addSingleSlotUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const deleteSlotFxn = (id) => async dispatch => {
    try {
        const requestData = {slotId: id}
        const response = await axios.post(deleteSlotUrl(), requestData, getToken())
        if (!response.data.error) {
            notification.success({
                message: response.data.message || 'Success'
            })
        } else {
            notification.error({
                message: response.data.message || 'Error'
            })
        }

        return response.data
    } catch (error) {
        console.error('Error deleting task:', error)
        throw error
    }
}

export const updateSlotFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateSlotUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Deleted Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const deleteSlotByDateFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(deleteSlotByDateUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateInstituteEventStatusFxn = (eventId, status, reason) => async dispatch => {
    const valData = {
        eventId: eventId,
        reason: reason,
        status: status,
    }
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInstituteEventStatusUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const updateInstituteEventDateFxn = (eventId, date, reason, time) => async dispatch => {
    const valData = {
        eventId: eventId,
        date: date,
        reason: reason,
        time: time,
    }
    dispatch(showPageLoad())
    let {data} = await axios.post(updateInstituteEventDateUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const addInstituteCommentFxn = (eventId, comments) => async dispatch => {
    const valData = {
        instituteEventId: eventId,
        comment: comments
    }
    dispatch(showPageLoad())
    let {data} = await axios.post(addInstituteCommentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const instituteCommentsFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(instituteEventsCommentsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const appliedEventsListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(appliedEventsListUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const singleInstituteEventFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(singleInstituteEventUrl(), valData, getToken())
    dispatch(hidePageLoad())
    /* if (!data.error) {
         notification.success({
             message: data.message || 'Success'
         })
     } else {
         notification.error({
             message: data.message || 'Error'
         })
     }*/
    return data
}

export const activityListFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(activityListUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const InstituteStudentListFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(instituteStudentListUrl(), config)
    dispatch(hidePageLoad())
    return data
}


export const approvedEventListFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(approvedEventListUrl(), valData, getToken())
    dispatch(hidePageLoad())
    /* if (!data.error) {
         notification.success({
             message: data.message || 'Success'
         })
     } else {
         notification.error({
             message: data.message || 'Error'
         })
     }*/
    return data
}

