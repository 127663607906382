export const taskRights = {
  mainUsers: [
    'admin',
    // 'branchUser',
    'canadaOnshoreAdmin',
    'userManager',
    'marketingManager',
    'eventManager',
    'counsellingManager',
    'unizhomeManager',
    'gicManager',
    'cornerStoneManager',
    'cambieManager',
    'cambieBranchUser',
    'onshoreAccountant',
    'cambieAccountant',
    'cambieDirector',
    'cambieReception'
  ],
  userTypes: [
    'agent',
    'subAgent',
    'team',
    'institute',
    'student'
  ],
  userRight: () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user && user._id) {
      let { userType } = user
      if (!taskRights.userTypes.includes(userType)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

}


export const RightComponent = (props) => {
  let { user, userTypes, children } = props
  return (
    userTypes.includes(user.userType) ? children : null
  )
}
