import React, {Suspense, useEffect} from "react";
import TopNavComponent from "./topMenu";
import SideNavComponent from "./sideMenu";
import {Navigate, Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";


const InnerLayout = (props) => {
    console.log(props);
    let {currentUser} = props;


    return (
        <>
            <Helmet>
                <link href='../../dist/css/style.css' rel='stylesheet'/>
                <link href='../../dist/css/customCss.css' rel='stylesheet'/>
                <link
                    href='../../plugins/fontawesome-free/css/all.min.css'
                    rel='stylesheet'
                />
                <link
                    href='../../plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
                    rel='stylesheet'
                />
                <link href='../../dist/css/adminlte.css' rel='stylesheet'/>
                <link href='https://fonts.gstatic.com' rel='preload'/>
                <link
                    href='https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap'
                    rel='preload'
                />
            </Helmet>
            {
                currentUser && currentUser._id ? <div>
                        <div className="layout-wrapper layout-content-navbar layout-main-inner">
                            <div className="layout-container">
                                <SideNavComponent {...props}/>
                                <div className="layout-page">
                                    <TopNavComponent {...props}/>
                                    <div class="content-wrapper">
                                        <div class="flex-grow-1 container-p-y container-fluid">
                                            <Outlet/>
                                        </div>

                                        <div class="content-backdrop fade"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <Navigate replace to="/login"/>
            }

        </>
    );
};
export default InnerLayout;
