import {apiUrl} from "../../../settings";

export const addBranchUrl = () => {
    return apiUrl + '/branch';
};

export const listAllBranchUrl = () => {
    return apiUrl + '/branch';
};

export const listBranchUrl = (id) => {
    return apiUrl + '/branch/' + id;
};