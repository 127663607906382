import _ from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
    Col,
    Row,
    Button,
    Form,
    Modal,
    notification, Input, Icon, Select
} from '../../../../components/Elements/appUtils'
import GetEachFormFields from '../../../../components/_utils/appFormUtils'
import {
    UpdateGicCommissionFxn,
    UpdateGicDocumentsFxn,
    gicCommissionListFxn, reUploadDocumentsFxn
} from '../gicCommission/action'
import FileInput from '../../../../components/_utils/fileInput'
import {
    ConditionOptions, countryIds,
    DefaultTablePagination,
    displayDate,
    filterOption,
    InputBox, Intakes
} from '../../../../components/_utils/appUtils'
import {TableComp} from '../../../../components/Elements/appUtils'
import {getUrlPushWrapper} from '../../../../routes'
import {listAllUniversities} from '../../../university/actions/university'

let {confirm} = Modal
let {Option} = Select
let actionUserType = ['admin', 'gicManager']
let reUploadActionUserType = ['admin', 'gicManager', 'agent']
let bankList = [
    {name: 'ICICI', value: 'iciciBank'},
    {name: 'CIBC', value: 'cibcBank'}
]

let statusList = [
    {name: 'Pending', value: 'Pending'},
    {name: 'Submitted', value: 'Submitted'},
    {name: 'Verified', value: 'Verified'},
    {name: 'Gic Account Opened', value: 'Gic Account Opened'},
    {name: 'GIC Account Funded', value: 'GIC Account Funded'},
    {name: 'Cancelled', value: 'Disapproved'}
]

const GicListComponent = (props) => {
    let dispatch = useDispatch()
    let tableRef = useRef()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const [gicAccountFile, setGicAccountFile] = useState('')
    const [gicCertificate, setGicCertificate] = useState('')
    const [panCard, setPanCard] = useState('')
    const [passport, setPassport] = useState('')
    const [uploadDocuments, setUploadDocuments] = useState({
        open: false,
        gicId: ''
    })
    const [reUploadDocuments, setReUploadDocuments] = useState({
        open: false,
        gicId: '',
        passport: '',
        panCard: ''
    })
    let [state, setState] = useState([])
    let {
        form: {getFieldDecorator, setFieldsValue, getFieldValue},
        title, pathName
    } = props
    const [isCredentialModalOpen, setIsCredientialModalOpen] = useState({
        open: false,
        gicId: ''
    })
    const [certificateFileModal, setUploadCertificateFileModal] = useState({
        open: false,
        gicId: ''
    })
    const [linkUploadModal, setLinkUploadModal] = useState({
        open: false,
        gicId: ''
    })
    const [record, setRecord] = useState(false)
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [verificationLink, setVerificationLink] = useState('')


    const [name, setName] = useState('')
    const [universityList, setUniversityList] = useState([])
    const [appUniversityId, setAppUniversityId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    let [intake, setIntake] = useState('')
    let [status, setStatus] = useState('')
    let [bank, setBank] = useState('')


    useEffect(() => {
        loadUniversityNameList()
        getParamsForApplicationList()
    }, [])

    const events = {
        chooseDocument: (e) => {
            let {name, files} = e.target
            if (files && files.length) {
                setState({
                    ...state,
                    [name]: files[0]
                })
            }
        },
        handleUpdateStatus: async (id, status) => {
            let valData = {}
            valData.status = status
            valData.gicId = id
            if (userName && password) {
                valData.userName = userName
                valData.password = password
            }
            if (verificationLink) {
                valData.verificationLink = verificationLink
            }
            let x = await dispatch(UpdateGicCommissionFxn(valData))
            if (x && x.success) {
                tableRef.current.reload()
                setIsCredientialModalOpen({open: false})
            }
        },

        handleAddCredential: async (id, status) => {
            let valData = {}
            valData.transactionType = 'Credit'
            valData.status = status
            valData.gicId = id
            if (!userName) {
                notification.warning({
                    message: `Please Enter Username`
                })
                return
            }
            if (!password) {
                notification.warning({
                    message: `Please Enter Password`
                })
                return
            }
            if (userName && password) {
                valData.userName = userName
                valData.password = password
            }
            let x = await dispatch(UpdateGicCommissionFxn(valData))
            if (x && x.success) {
                setIsCredientialModalOpen({open: false})
                setUserName('')
                setPassword('')
                tableRef.current.reload()
            }
        },
        handleAddVerifyLink: async (id, status) => {
            let valData = {}
            valData.status = status
            valData.gicId = id
            if (!verificationLink) {
                notification.warning({
                    message: `Please Enter Verification Link`
                })
                return
            }
            if (verificationLink) {
                valData.verificationLink = verificationLink
            }
            let x = await dispatch(UpdateGicCommissionFxn(valData))
            if (x && x.success) {
                setVerificationLink('')
                setLinkUploadModal({open: false})
                tableRef.current.reload()
            }
        },
        apiRequest: (params) => {
            return new Promise(async (resolve) => {
                let resp = await getParamsForApplicationList()
                params = {...params, ...resp}
                let {status} = props
                let obj = {
                    sortField: '_id',
                    sortOrder: 'descend',
                    ...params
                }
                if (status) {
                    obj.status = status
                } else {
                    if (!params.status) {
                        obj.status = [
                            'Pending',
                            'Submitted',
                            'Verified'
                        ]
                    }
                }
                let data = await dispatch(
                    gicCommissionListFxn({
                        ...obj,
                        regExFilters: ['type', 'name']
                    })
                )
                setTotalApplication(data.total)
                resolve(data)
            })
        },
        submitUpdate: (record) => {
            let {status} = record
            let newStatus = ''
            let statusLabel = ''
            if (status == 'Pending') {
                newStatus = 'Submitted'
                statusLabel = 'Submit'
            } else if (status == 'Submitted') {
                newStatus = 'Verified'
                statusLabel = 'Verify'
            } else if (status == 'Verified') {
                newStatus = 'Bank verification requested'
                statusLabel = 'Waiting For Agent to Upload Gic Account File'
            } else if (status == 'Bank verification requested') {
                newStatus = 'Approved'
                statusLabel = 'Approve Bank verification '
            } else if (status == 'BankVerified') {
                newStatus = 'Approved'
                statusLabel = 'Approve'
            }

            confirm({
                title: `Are you sure, you want to ${statusLabel} this Gic?`,
                onOk: () => {
                    events.handleUpdateStatus(record._id, newStatus)
                },
                onCancel: () => {
                    console.log('Cancel')
                },
                width: '450px'
            })
        },
        showModal: (e, id) => {
            e.preventDefault()
            setUploadDocuments({
                open: e,
                gicId: id
            })
        },
        showReUploadModal: (e, id, record) => {
            e.preventDefault()
            setReUploadDocuments({
                open: e,
                gicId: id,
                passport: record.passportCopy,
                panCard: record.panCard
            })
        },
        onCloseReUploadModal: () => {
            setReUploadDocuments({
                open: false,
                gicId: ''
            })
            setPassport('')
            setPanCard('')
            setFieldsValue({
                passport: ' ',
                panCard: ' '
            })
            tableRef.current.reload()
        },

        onClose: () => {
            setUploadDocuments({
                open: false,
                gicId: ''
            })
            setGicAccountFile('')
            setFieldsValue({
                gicAccountFile: ' '
            })
            tableRef.current.reload()
        },

        handleAccountFileSubmit: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFields(async (err, valData) => {
                if (!err) {
                    if (!(gicAccountFile && gicAccountFile.name)) {
                        notification.warning({
                            message: 'Choose select Gic Account file'
                        })
                        return
                    }
                    let fd = new FormData()
                    valData.gicId = uploadDocuments.gicId
                    valData.status = 'Gic Account Opened'
                    fd.append('obj', JSON.stringify(valData))
                    if (gicAccountFile && gicAccountFile.name) {
                        fd.append('gicAccountFile', gicAccountFile)
                    }
                    let x = await dispatch(UpdateGicDocumentsFxn(fd))
                    if (x && x.success) {
                        events.onClose()
                        setGicAccountFile('')
                    }
                } else {
                    notification.warning({
                        message: `Something went wrong`
                    })
                }
            })
        },

        handleCertificateFileSubmit: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFields(async (err, valData) => {
                if (!err) {
                    if (!(gicCertificate && gicCertificate.name)) {
                        notification.warning({
                            message: 'Choose Gic Certificate'
                        })
                        return
                    }
                    if (getFieldValue('agentAccountCredited') == undefined) {
                        notification.warning({
                            message: 'You Want To Add Credit In Agent Account?'
                        })
                        return
                    }
                    if (!getFieldValue('reason')) {
                        notification.warning({
                            message: 'Please Enter Reason'
                        })
                        return
                    }
                    let fd = new FormData()
                    valData.gicId = certificateFileModal.gicId
                    valData.status = 'GIC Account Funded'
                    valData.reason = getFieldValue('reason')
                    valData.agentAccountCredited = getFieldValue('agentAccountCredited')
                    fd.append('obj', JSON.stringify(valData))
                    if (gicCertificate && gicCertificate.name) {
                        fd.append('gicCertificate', gicCertificate)
                    }
                    let x = await dispatch(UpdateGicDocumentsFxn(fd))
                    if (x && x.success) {
                        setUploadCertificateFileModal({
                            open: false,
                            gicId: ''
                        })
                        tableRef.current.reload()
                    }
                } else {
                    notification.warning({
                        message: `Something went wrong`
                    })
                }
            })
        },

        handleReUploadDocuments: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFields(async (err, valData) => {
                if (!err) {
                    if (!panCard && !passport) {
                        notification.warning({
                            message: 'Please upload atleast 1 document'
                        })
                        return
                    }

                    /*   if (!(panCard && panCard.name)) {
                         notification.warning({
                           message: 'Please Upload Pan Card'
                         })
                         return
                       }
                       if (!(passport && passport.name)) {
                         notification.warning({
                           message: 'Please Upload Passport'
                         })
                         return
                       }*/
                    let fd = new FormData()
                    valData.gicId = reUploadDocuments.gicId
                    fd.append('obj', JSON.stringify(valData))
                    if (passport && passport.name) {
                        fd.append('passportCopy', passport)
                    }
                    if (panCard && panCard.name) {
                        fd.append('panCard', panCard)
                    }
                    let x = await dispatch(reUploadDocumentsFxn(fd))
                    if (x && x.success) {
                        setReUploadDocuments({
                            open: false,
                            gicId: ''
                        })
                        tableRef.current.reload()
                    }
                } else {
                    notification.warning({
                        message: `Something went wrong`
                    })
                }
            })
        },

        handleFileChange: (e) => {
            let {name, files} = e
            if (name === 'gicCertificate') {
                setGicCertificate(files[0])
            } else if (name === 'gicAccountFile') {
                setGicAccountFile(files[0])
            } else if (name === 'panCard') {
                setPanCard(files[0])
            } else if (name === 'passport') {
                setPassport(files[0])
            }
        }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 170,
            render: (item, record) => {
                let {status} = record
                return (
                    <div style={{textAlign: 'left'}}>
                        <div>
                            {item == 'Pending' ? (
                                <label className='label label-warning sm'>Pending</label>
                            ) : null}
                            {item == 'Submitted' ? (
                                <label className='label label-success-green sm'>Submitted</label>
                            ) : null}
                            {item == 'Verified' ? (
                                <label className='label label-success sm'>Verified</label>
                            ) : null}
                            {item == 'Gic Account Opened' ? (
                                <label className='label label-warning sm'>Gic Account Opened</label>
                            ) : null}
                            {item == 'GIC Account Funded' ? (
                                <label className='label label-success-green sm'>GIC Account Funded</label>
                            ) : null}

                            {item == 'Disapproved' ? (
                                <label className='label label-danger sm'>Cancelled</label>
                            ) : null}
                        </div>
                        {actionUserType.includes(user.userType) && (status == 'Pending') ? (
                            <div className={'mt5'}>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={() => {
                                       setIsCredientialModalOpen({open: true})
                                       setRecord(record)
                                   }}>
                                    Update Status
                                </a>
                            </div>
                        ) : null}
                        {actionUserType.includes(user.userType) && (status == 'Submitted') && record.verificationLink ? (
                            <div className={'mt5'}>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={() => {
                                       events.submitUpdate(record)
                                       setRecord(record)
                                   }}>
                                    Update Status
                                </a>
                            </div>
                        ) : null}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            searchTextName: 'firstName',
            filterRegex: true,
            width: 150,
            render: (item, record) => {
                return (
                    <>
                        {item} {record.lastName}
                    </>
                )
            }
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            key: 'bank',
            width: 150,
            // filters: bankList.map(x => ({ value: x.value, text: x.name })),

            render: (item, record) => {
                return (
                    <>
                        {item == 'iciciBank' ?
                            <>
                                <img src={'../dist/banks/iciciBank.jpeg'} style={{width: 100}}/>
                            </> :
                            <>
                                <img src={'../dist/banks/cibcBank.jpeg'} style={{width: 100}}/>
                            </>}
                    </>
                )
            }
        },
        /*  {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            searchTextName: 'lastName',
            filterRegex: true,
            width: 150
          },*/

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchTextName: 'email',
            filterRegex: true,
            width: 170
        },
        {
            title: 'Username',
            dataIndex: 'userName',
            key: 'userName',
            searchTextName: 'userName',
            filterRegex: true,
            width: 170,
            render: (val, record) => {
                return (<>
                    <div>{val ? `Username : ${val}` : ''}</div>
                    <div>{record && record.password ? `Password : ${record.password}` : ''}</div>
                </>)
            }
        },
        /* {
           title: 'Password',
           dataIndex: 'password',
           key: 'password'
         },*/

        {
            title: 'Security Question',
            dataIndex: 'securityQuestion',
            key: 'securityQuestion',
            render: (item, record) => {
                return (
                    <>
                        {item}
                        <br/>
                        <div>Ans : {record.securityAnswer}</div>
                    </>
                )
            }
        },
        {
            title: 'College Name',
            dataIndex: 'collegeName',
            key: 'collegeName',
            searchTextName: 'collegeName',
            filterRegex: true
        },
        {
            title: 'Passport/Pan Card',
            dataIndex: 'passportCopy',
            key: 'passportCopy',
            width: 120,
            render: (item, record) => {
                let {panCard} = record
                return (
                    <>
                        {item && item.url ?
                            <div className={'mb5'}>
                                <a href={item.url} target={'_blank'} className={'btn btn-default btn-xs'}>
                                    <Icon type={'download'}/>&nbsp;Passport</a>
                            </div>
                            : null}

                        <div>
                            {panCard && panCard.url ?
                                <a href={panCard.url} target={'_blank'} className={'btn btn-default btn-xs'}>
                                    <Icon type={'download'}/>&nbsp;Pan Card</a> : null}
                        </div>
                    </>
                )
            }
        },
        /* {
           title: 'Pan Card',
           dataIndex: 'panCard',
           key: 'panCard',
           width: 120,
           render: (item) => {
             return (
               <>
                 {item && item.url ? <a href={item.url} target={'_blank'} className={'btn btn-default btn-xs'}>
                   <Icon type={'download'} />
                   Download</a> : null}
               </>
             )
           }
         },*/
        {
            title: 'Documents',
            dataIndex: 'documents',
            key: 'documents',
            width: 150,
            render: (item, record) => {
                let {gicAccountFile, gicCertificate} = record
                return (
                    <>
                        {gicAccountFile && gicAccountFile.url ?
                            <div className={'mb5'}>
                                <a href={gicAccountFile.url} target={'_blank'} className={'btn btn-default btn-xs'}>
                                    <Icon type={'download'}/> GIC Account
                                </a>
                            </div> :
                            null}

                        <div>
                            {gicCertificate && gicCertificate.url ?
                                <a href={gicCertificate.url} target={'_blank'} className={'btn btn-default btn-xs'}>
                                    <Icon type={'download'}/> GIC Certificate
                                </a> :
                                null}
                        </div>
                    </>
                )
            }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            searchTextName: 'city',
            filterRegex: true
        },
        {
            title: 'Contact No',
            dataIndex: 'phoneNo',
            key: 'phoneNo',
            searchTextName: 'phoneNo',
            filterRegex: true
        },
        {
            title: 'Arrival Date',
            dataIndex: 'expectedDateOfArrival',
            key: 'expectedDateOfArrival',
            searchTextName: 'expectedDateOfArrival',
            filterRegex: true,
            render: (text, record) => {
                return <>{text ? displayDate(text) : null} </>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'agentId',
            key: 'agentId',
            searchTextName: 'agentId',
            filterRegex: true,
            render: (text, record) => {
                return <>
                    {text && text.name ? text.name : null}<br/>
                    {text && text.companyName ? ` (${text.companyName})` : null}
                </>
            }
        },
        {
            title: 'Verification Link',
            dataIndex: 'verificationLink',
            key: 'verificationLink',
            render: (item) => {
                return item ? <a href={item} target={'_blank'}>Verification Link</a> : null
            }
        },
        {
            title: 'Contact Detail',
            dataIndex: 'contactName',
            key: 'contactName',
            render: (item, record) => {
                let {contactNo} = record
                return (
                    <>
                        {item ? <div>
                            <Icon type={'user'}/>&nbsp; {item}
                        </div> : null}
                        {contactNo ? <div>
                            <Icon type={'mobile'}/>&nbsp; {contactNo}
                        </div> : null}
                    </>
                )
            }
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            fixed: 'right',
            render: (item, record) => {
                let {status} = record
                return (
                    <div className={'d-flex row'}>
                        {(status == 'Verified' && actionUserType.includes(user.userType)) ? (
                            <>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={(e) => events.showModal(e, record._id)}>
                                    Upload Acc. File
                                </a>
                            </>
                        ) : null}
                        {(status == 'Gic Account Opened' && actionUserType.includes(user.userType)) ? (
                            <>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={() => setUploadCertificateFileModal({open: true, gicId: record._id})}>
                                    Upload Cert. File
                                </a>
                            </>
                        ) : null}
                        {(status == 'Submitted' && !record.verificationLink && actionUserType.includes(user.userType)) ? (
                            <>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={() => {
                                       setLinkUploadModal({open: true})
                                       setRecord(record)
                                   }}
                                >
                                    Upload Verification Link
                                </a>
                            </>
                        ) : null}
                        {(status == 'Pending' && reUploadActionUserType.includes(user.userType)) ? (
                            <>
                                <a className={'btn btn-default sm roundNew'}
                                   onClick={(e) => events.showReUploadModal(e, record._id, record)}>
                                    Re-Upload Docs.
                                </a>
                            </>
                        ) : null}
                    </div>
                )
            }
        }
    ]

    let loadColumn = () => {
        let columnList = []
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item)
            }
        })
        return columnList
    }
    let inputTypes = {
        accountFile: [
            {
                key: 'gicAccountFile',
                label: 'GIC Account File',
                required: false,
                placeholder: 'GIC Account File',
                type: 'file',
                fileName: 'gicAccountFile',
                onChange: ({target}) => {
                    events.handleFileChange(target)
                },
                span: 'col-md-10'
            }
        ],
        certificateFile: [
            {
                key: 'gicCertificate',
                label: 'GIC Certificate',
                required: false,
                placeholder: 'GIC Certificate',
                type: 'file',
                fileName: 'gicCertificate',
                onChange: ({target}) => {
                    events.handleFileChange(target)
                },
                span: 'col-md-4'
            },
            {
                key: 'agentAccountCredited',
                label: 'You Want To Add Credit In Agent Account',
                type: 'select',
                options: ConditionOptions,
                allowClear: true,
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    setFieldsValue({agentAccountCredited: x})
                },
                span: 'col-md-4'
            },
            {
                key: 'reason',
                label: 'Reason',
                required: false,
                placeholder: 'Enter Reason Here..',
                span: 'col-md-10'
            }
        ],
        reUploadFiles: [
            {
                key: 'passport',
                label: 'Passport',
                required: false,
                placeholder: 'Passport',
                type: 'file',
                fileName: 'passport',
                onChange: ({target}) => {
                    events.handleFileChange(target)
                },
                span: 'col-md-4'
            },
            {
                key: 'panCard',
                label: 'Pan Card',
                required: false,
                placeholder: 'Pan Card',
                type: 'file',
                fileName: 'panCard',
                onChange: ({target}) => {
                    events.handleFileChange(target)
                },
                span: 'col-md-4'
            }
        ]

    }

    const loadUniversityNameList = async () => {
        let params = {
            results: 5000,
            select: ['universityName'],
            sortField: 'universityName',
            sortOrder: 'ascend',
            regExFilters: ['universityName'],
            showUniversity: [true, false]
        }
        params.universityCountry = countryIds.canada

        let {data} = await dispatch(listAllUniversities(params))
        setAppUniversityId('')
        if (data && data.length) {
            setUniversityList(data)
        }
    }


    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let agentId = searchParams.get('agentId')
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let intake = searchParams.get('intake')
            let status = searchParams.get('status')
            let bank = searchParams.get('bank')
            let obj = {}
            if (agentId) {
                obj.agentId = agentId
            }

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (intake) {
                obj.intake = intake
            }

            if (status) {
                obj.status = status
            }
            if (bank) {
                obj.bank = bank
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (appUniversityId) {
            obj.appUniversityId = appUniversityId
        }
        if (intake) {
            obj.intake = intake
        }
        if (status) {
            obj.status = status
        }
        if (bank) {
            obj.bank = bank
        }
        dispatch(
            getUrlPushWrapper(pathName, {
                ...obj
            })
        )
        setTimeout(() => {
            if (tableRef && tableRef.current) {
                tableRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let obj = {}
        setAppUniversityId('')
        setName('')
        setStatus('')
        setBank('')
        dispatch(
            getUrlPushWrapper(pathName, {
                ...obj
            })
        )
        setTimeout(() => {
            tableRef.current.reload()
        }, 200)
    }


    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }


    const filterBlock = (
        <Row gutter={16} className={'filter_box'}>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='search-box-table round ml0'>
                    <input
                        className='form-control form-control'
                        type='search'
                        placeholder='Name...'
                        value={name}
                        onChange={e => {
                            events.enterName(e.target.value)
                        }}
                    />
                    <img src='/dist/img/search.png' alt=''/>
                </div>
            </Col>

            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select
                        name='Search By University'
                        onChange={item => setAppUniversityId(item)}
                        filterOption={filterOption}
                        allowClear={true}
                        showSearch={true}
                        placeholder={'University'}
                        value={appUniversityId || undefined}>
                        {universityList && universityList.length
                            ? universityList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>
                                        {item.universityName}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </div>
            </Col>
            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select
                        name='Intake'
                        onChange={item => setIntake(item)}
                        filterOption={filterOption}
                        allowClear={true}
                        showSearch={true}
                        placeholder={'Intake'}
                        value={intake || undefined}>
                        {Intakes && Intakes.length
                            ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>
                                        {item}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </div>
            </Col>

            {title == 'Gic Request List' ? <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select
                        name='Status'
                        onChange={item => setStatus(item)}
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Status'}
                        value={status || undefined}>
                        {statusList && statusList.length
                            ? statusList.map((item, key) => {
                                return (
                                    <Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </div>
            </Col> : null}

            <Col md={4} sm={4} xs={8} lg={4}>
                <div className='sort-box-table custom-sort-box-new'>
                    <Select
                        name='Bank'
                        onChange={item => setBank(item)}
                        filterOption={filterOption}
                        allowClear={true}
                        placeholder={'Bank'}
                        value={bank || undefined}>
                        {bankList && bankList.length
                            ? bankList.map((item, key) => {
                                return (
                                    <Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            })
                            : null}
                    </Select>
                </div>
            </Col>

            <Col md={4} sm={4} xs={8} lg={4}>
                <Button onClick={() => searchFxn()} className={'roundBtn'}>
                    Search
                </Button>
                <Button onClick={() => clearFxn()} className={'roundBtn'}>
                    Clear
                </Button>
            </Col>
        </Row>
    )

    return (
        <>
            <div className='row  mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>{title}</h5>
                        </div>
                        <div className='card-body table-responsive'>
                            {filterBlock}

                            <TableComp
                                apiRequest={events.apiRequest}
                                columns={loadColumn()}
                                size='large'
                                pagination={DefaultTablePagination()}
                                extraProps={{scroll: {x: 2500}}}
                                ref={tableRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={uploadDocuments.open}
                onCancel={events.onClose}
                open={uploadDocuments.open}
                width={'40%'}
                placement='right'
                title={'Upload Account File'}
                footer={null}
            >
                <div>
                    <Form onSubmit={events.handleAccountFileSubmit}>
                        <Row gutter={24}>
                            {inputTypes.accountFile.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24} xs={24}
                                         key={key}>
                                        {item.type == 'file' ?
                                            <FileInput name={item.fileName}
                                                       label={item.label}
                                                       chooseDocument={item.onChange}/>
                                            : <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        }
                                    </Col>
                                )
                            })}
                        </Row>
                        <button className={'btn btn-success roundNew'}>
                            Submit
                        </button>
                    </Form>
                </div>
            </Modal>
            <Modal
                visible={reUploadDocuments.open}
                onCancel={events.onCloseReUploadModal}
                open={reUploadDocuments.open}
                width={'40%'}
                placement='right'
                title={'Re-Upload Documents'}
                footer={null}
            >
                <div>
                    <Form onSubmit={events.handleReUploadDocuments}>
                        <Row gutter={24}>
                            {inputTypes.reUploadFiles.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24} xs={24}
                                         key={key}>
                                        {item.type == 'file' ?
                                            (<div className={'rowFlex aic'}>
                                                <FileInput
                                                    name={item.fileName}
                                                    label={item.label}
                                                    chooseDocument={item.onChange}
                                                />
                                                <>
                                                    {
                                                        item.fileName === 'panCard' && reUploadDocuments.panCard ? (
                                                            <a className={'linkBtn mt18'}
                                                               onClick={() => window.open(reUploadDocuments.panCard.url, '_blank')}>
                                                                <Icon type={'eye'}/>
                                                            </a>
                                                        ) : null
                                                    }
                                                    {
                                                        item.fileName === 'passport' && reUploadDocuments.passport ? (
                                                            <a className={'linkBtn mt18'}
                                                               onClick={() => window.open(reUploadDocuments.passport.url, '_blank')}>
                                                                <Icon type={'eye'}/>
                                                            </a>
                                                        ) : null
                                                    }
                                                </>
                                            </div>)

                                            : <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        }
                                    </Col>
                                )
                            })}
                        </Row>
                        <button className={'btn btn-success roundNew'}>
                            Submit
                        </button>
                    </Form>
                </div>
            </Modal>

            {isCredentialModalOpen.open ? <Modal
                visible={isCredentialModalOpen.open}
                onCancel={() => {
                    setIsCredientialModalOpen({open: false})
                    setUserName('')
                    setPassword('')
                }}
                open={isCredentialModalOpen.open}
                width={'40%'}
                placement='right'
                title={'Add Credential'}
                footer={null}>
                <div>
                    <Form>
                        <Row gutter={24}>
                            <Col>
                                <Row span={12}>
                                    <InputBox title={'Username *'}>
                                        <Input
                                            value={userName}
                                            onChange={(e) => {
                                                setUserName(e.target.value)
                                            }}
                                            className={'form-control'}/>
                                    </InputBox>
                                </Row>
                                <Row span={12}>
                                    <InputBox title={'Password *'}>
                                        <Input
                                            type={'text'}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            className={'form-control'}/>
                                    </InputBox>
                                </Row>
                            </Col>
                        </Row>
                        <Button type={'primary'}
                                className={'btn-lg mt-2 ms-2'}
                                onClick={() => {
                                    events.handleAddCredential(record._id, 'Submitted')
                                }}
                        >
                            Submit
                        </Button>
                    </Form>
                </div>
            </Modal> : null}

            <Modal
                visible={certificateFileModal.open}
                onCancel={() => {
                    setUploadCertificateFileModal({open: false, gicId: ''})
                }}
                open={certificateFileModal.open}
                width={'40%'}
                placement='right'
                title={'Upload Certificate'}
                footer={null}
            >
                <div>
                    <Form onSubmit={events.handleCertificateFileSubmit}>
                        <Row gutter={24}>
                            {inputTypes.certificateFile.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 10} md={item.span ? item.span : 10} sm={24}
                                         xs={24}
                                         key={key}>
                                        {item.type == 'file' ?
                                            <FileInput
                                                name={item.fileName}
                                                label={item.label}
                                                chooseDocument={item.onChange}
                                            />
                                            : <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        }
                                    </Col>
                                )
                            })}
                        </Row>
                        <button
                            className={'btn btn-success roundNew mt10'}>
                            Submit
                        </button>
                    </Form>
                </div>
            </Modal>

            <Modal
                visible={linkUploadModal.open}
                onCancel={() => {
                    setLinkUploadModal({open: false})
                    setVerificationLink('')
                }}
                open={linkUploadModal.open}
                width={'40%'}
                placement='right'
                title={'Upload Verification Link'}
                footer={null}
            >
                <div>
                    <Form>
                        <Row gutter={24}>
                            <Col>
                                <Row span={12}>
                                    <InputBox title={'Verification link'}>
                                        <Input
                                            value={verificationLink}
                                            onChange={(e) => {
                                                setVerificationLink(e.target.value)
                                            }}
                                            className={'form-control'}/>
                                    </InputBox>
                                </Row>
                            </Col>
                        </Row>
                        <Button
                            type={'primary'}
                            htmlType='submit'
                            className={'btn-lg'}
                            onClick={() => {
                                events.handleAddVerifyLink(record._id, 'Submitted')
                            }}>
                            Submit
                        </Button>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default Form.create()(GicListComponent)
