import React, {useState} from "react"
import {notification} from '../../components/Elements/appUtils';
import {hidePageLoad, showPageLoad} from "../../modules/actions";
import {customAxios as axios} from "../../request";
import {useDispatch} from "react-redux";
import {LazyLoadImage} from "react-lazy-load-image-component";

let apiUrl = "https://webapi.unizportal.com"

const addStoryUrl = () => {
    return apiUrl + '/getStudentRoyalArtsCourse'
}


const WebApi = () => {
    let dispatch = useDispatch()
    let [token, setToken] = useState("")
    const submitRequest = async () => {
        if (!token) {
            notification.warning({message: "Enter token"});
            return
        }
        let headers = {
            "Access-Control-Allow-Origin": apiUrl,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
            'Access-Control-Allow-Credentials': true,
            'Authorization': 'Bearer ' + token,
            'X-Requested-With': 'XMLHttpRequest',
            'crossdomain': true,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
        dispatch(showPageLoad())
        let config = {
            params: {results: 50, count: 50, page: 1},
            headers
        }
        let {data} = await axios.get(addStoryUrl(), config)
        dispatch(hidePageLoad())
        console.log(data)
    }
    return (
        <>
            <section className="banner blog-banner about-banner">

                <div className="container">
                    <div className="col-lg-6 mr-auto">
                        <div className="content text-left">
                            <h1>We're Unizportal</h1>
                            <p>We work towards actualizing your aspirations</p>

                            <button className="btn" type="button">
                                apply online <LazyLoadImage src="/assets/image/right-arrow.svg" alt=""/>
                            </button>
                        </div>
                    </div>

                    <div className="img">
                        <LazyLoadImage src="/assets/image/about-banner.png" alt=""/>
                    </div>
                </div>

            </section>
            <section>
                <div className="container">
                    <textarea
                        className={'form-control'}
                        value={token} onChange={({target}) => {
                        setToken(target.value)
                    }}></textarea>
                    <br/>
                    <br/>

                    <a className={'btn btn-success'} onClick={() => {
                        submitRequest()
                    }}>
                        Submit
                    </a>
                    <br/>
                    <br/>

                </div>
            </section>
        </>
    )
}
export default WebApi
