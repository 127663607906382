import React, {useState, useEffect, useRef} from 'react'
import {Button, Card, Col, Icon, Row, Select, Switch} from '../../../components/Elements/appUtils'
import {userTypeWiseFxn, getAgentAppCount, subAgentAppCountByAgent} from '../../users/actions/user'
import {
    DefaultTablePagination,
    InputBox,
    defaultLogoList,
    departmentObj,
    agentTeamTypes
} from '../../../components/_utils/appUtils'
import {
    dashboardUniversityCount
} from '../actions/dashboardAction'
import {TableComp} from '../../../components/Elements/appUtils'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {LazyLoadImage} from 'react-lazy-load-image-component'


const {Option} = Select
const {Meta} = Card


const CountBlock = (props) => {
    let {item, count = 0, callback} = props
    return (
        <Col span={4} md={4} sm={6} xs={12}>
            <Card>
                <div className={'title'}>
                    {item}
                </div>
                <div className={'count'}>
                    <p onClick={callback}>
                        {count}
                    </p>
                </div>
            </Card>
        </Col>
    )
}


const SubAgentAppCount = (props) => {
    let {dispatch, user} = props
    let [subAgentCountByAgent, setSubAgentCountByAgent] = useState([])
    useEffect(() => {
        loadAgentAppCount()
    }, [])

    const loadAgentAppCount = async () => {
        let resp = await subAgentAppCountByAgent()
        setSubAgentCountByAgent(resp)
    }


    return (
        <Card className={'mt20 dashboardCard'}>
            <Meta
                title='Number of Cases with each sub agent (Current)'
                description='Date range:All time'
            />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        {subAgentCountByAgent && subAgentCountByAgent.length ? subAgentCountByAgent.map((item, key) => {
                            return (
                                item && item._id && <CountBlock key={key} item={item._id.name}
                                                                count={item.count ? item.count : 0}/>
                            )
                        }) : null}
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}


const CheckUserRight = (props) => {
    let {user: {userType}, rightUserType, children} = props
    return (
        rightUserType.includes(userType) ? children : null
    )
}
const ShowOnShoreCanadaRight = (props) => {
    let {user: {userType, showOnShoreCanada, directCanadaOnShore}, children} = props
    return (
        (userType == 'admin' || showOnShoreCanada == true || directCanadaOnShore == true) ? children : null
    )
}

const GetCountryCountRight = (props) => {
    let {
        user: {userType, department, showAustraliaUniversity, branchManagerType},
        countryName,
        children,
        userCountryName
    } = props
    let checkCond = (
        (userType == 'branchUser' && department !== 'Visa Approved Department' && department !== departmentObj.marketing) ||
        (userType == 'branchManager' && branchManagerType !== 'marketingManager' && branchManagerType !== 'studentWise')
    )
    let checkAuth = () => {
        if (userType == 'agent' || userType == 'subAgent') {
            if (countryName == 'australia_l1') {
                return showAustraliaUniversity
            } else if (countryName == 'australia') {
                return true
            } else {
                return true
            }
        } else if (checkCond) {
            if (countryName && userCountryName && countryName.toLowerCase() == userCountryName.toLowerCase()) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            {checkAuth() ? children : null}
        </React.Fragment>
    )
}

const CoreUserRight = (props) => {
    let {user: {userType, branchManagerType, department}, rightUserType, children} = props
    let departmentList = [
        'Offer Department',
        'LOA/CAS/120 Department',
        'Visa Submission Department',
        'Visa Approved Department',
        'Visa Rejected Department'
    ]

    let checkFxn = () => {
        if (userType == 'admin' || userType == 'agent') {
            return children
        } else if (userType == 'branchManager') {
            return !branchManagerType ? children : null
        } else if (userType == 'branchUser') {
            return departmentList.includes(department) ? children : null
        } else {
            return null
        }
    }
    return (
        checkFxn()
    )
}
const CheckBranchUserRight = (props) => {
    let {user: {userType, branchManagerType, department}, rightUserType, children} = props

    let checkFxn = () => {
        if (userType == 'admin') {
            return children
        } else if (userType == 'branchManager') {
            return !branchManagerType ? children : null
        } else {
            return null
        }
    }
    return (
        checkFxn()
    )
}

const ShowCountryOnDashboard = (props) => {
    let {
        user: {userType, department, showAustraliaUniversity, branchManagerType},
        countryName,
        children,
        userCountryName
    } = props
    let checkCond = (
        (userType == 'branchUser' && department !== 'Visa Approved Department' && department !== departmentObj.marketing) || (userType == 'branchManager' && branchManagerType !== 'marketingManager' && branchManagerType !== 'studentWise')
    )
    let checkAuth = () => {
        if (agentTeamTypes.includes(userType)) {
            if (countryName == 'australia_l1') {
                return showAustraliaUniversity
            } else if (countryName == 'australia') {
                return !showAustraliaUniversity
            } else {
                return true
            }
        } else if (checkCond) {
            if (countryName && userCountryName && countryName.toLowerCase() == userCountryName.toLowerCase()) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            {checkAuth() ? children : null}
        </React.Fragment>
    )
}
const ShowOnShoreApplicationDashboard = (props) => {
    let {
        user: {userType, department, showOnShoreAustralia, branchManagerType},
        children
    } = props

    let checkAuth = () => {
        if (userType == 'admin' || (userType == 'branchUser' && showOnShoreAustralia)) {
            return true
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            {checkAuth() ? children : null}
        </React.Fragment>
    )
}
const ShowCanadaOnShoreApplication = (props) => {
    let {
        user: {userType, showOnShoreCanada},
        children
    } = props
    // userType == 'admin' ||
    let checkAuth = () => {
        if (userType == 'userManager' || (userType == 'branchUser' && showOnShoreCanada)) {
            return true
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            {checkAuth() ? children : null}
        </React.Fragment>
    )
}
const ShowDirectCanadaOnShoreApplication = (props) => {
    let {
        user: {userType, directCanadaOnShore},
        children
    } = props
    let checkAuth = () => {
        if (userType == 'admin' || ((userType == 'branchUser' || userType == 'branchManager') && directCanadaOnShore)) {
            return true
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            {checkAuth() ? children : null}
        </React.Fragment>
    )
}

const ShowExportOption = (props) => {
    let {children} = props
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    let checkFxn = () => {
        let {userType, branchManagerType} = user
        if (userType == 'admin') {
            return true
        } else if (userType == 'branchManager' && !branchManagerType) {
            return true
        } else {
            return false
        }
    }
    return checkFxn() ? children : null
}

const ShowOnshoreAustraliaToUsers = (props) => {
    let {user, children} = props
    let checkFxn = () => {
        if (agentTeamTypes.includes(user.userType)) {
            if (user.countryName !== 'India') {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    return checkFxn() ? children : null
}

export {
    SubAgentAppCount,
    CheckUserRight,
    CountBlock,
    GetCountryCountRight,
    CoreUserRight,
    CheckBranchUserRight,
    ShowCountryOnDashboard,
    ShowOnShoreApplicationDashboard,
    ShowCanadaOnShoreApplication,
    ShowOnShoreCanadaRight,
    ShowExportOption,
    ShowDirectCanadaOnShoreApplication,
    ShowOnshoreAustraliaToUsers
}
