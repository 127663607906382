import {Icon, Tooltip} from '../../../components/Elements/appUtils'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {useDispatch} from 'react-redux'
import {
    CambieHCollege,
    LesterCollege,
    RoyalArtsCollege,
    fcmtUniversity,
    universityListForMarketing,
    departmentObj,
    currentUdaan,
    udaanQueryObj,
    CanadaRoyalHighSchool,
    CornerStoneCollege,
    NationalCollege, checkFcmtAdminRight
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import CambieUniversity from '../../evaluateProfile/cambie/cambieUniversity'
import FlairUniversity from '../../evaluateProfile/fcmt/flairUniversity'
import {canadaUniversityRecordsCountFxn} from '../../reporting/actions'
import DirectUniversityComponent from '../drawers/directUniversityComponent'

const ButtonLink = props => {
    let {
        searchData,
        item,
        record,
        userCountryName,
        userData,
        applyForCourse,
        openReport,
        agent = {},
        uniId
    } = props
    const btnA = (
        <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.id}>
            <div className={'innerBox'}>
                <div className={'uniCountBox'} onClick={() => searchData(item)}>
                    <LazyLoadImage src={item.image}/>
                    <h5 className={'record-count'}>{record.total ? record.total : 0}</h5>
                </div>
                {/*<div className={'dirUniAgent'}>
          {agent && agent.companyName ? agent.companyName : ''}
        </div>*/}
                {userData.userType == 'admin' ||
                userData.email == 'tarun@unizportal.com' ? (
                    <div className={'squareContainer'}>
                        <Tooltip title={udaanQueryObj.udaanNames[udaanQueryObj.prevUdaan]}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, udaanQueryObj.prevUdaan)}>
                                {record.prevUdaan ? record.prevUdaan : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={udaanQueryObj.udaanNames[currentUdaan]}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, currentUdaan)}>
                                {record.udaan ? record.udaan : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={'File pending for submission'}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, 'File pending for submission')}>
                                {record.pendingSubmission ? record.pendingSubmission : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={'File Submitted'}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, 'File Submitted')}>
                                {record.fileSubmitted ? record.fileSubmitted : 0}
                            </div>
                        </Tooltip>
                        <Tooltip title={'Last 8 Days'}>
                            <div
                                className={'squareStyle'}
                                onClick={() => openReport(item, 'last8Days')}>
                                {record.last8Days ? record.last8Days : 0}
                            </div>
                        </Tooltip>
                        {uniId == CornerStoneCollege ?
                            <>
                                <Tooltip title={'Pal Issued'}>
                                    <div
                                        className={'squareStyle'}
                                        onClick={() => openReport(item, 'pal-issued')}>
                                        {record.palCount ? record.palCount : 0}
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Loa Issued'}>
                                    <div
                                        className={'squareStyle'}
                                        onClick={() => openReport(item, 'loa-issued')}>
                                        {record.loaIssued ? record.loaIssued : 0}
                                    </div>
                                </Tooltip>
                            </>
                            : null}

                        {uniId == RoyalArtsCollege ? <>
                            <Tooltip title={'Visa With Pal'}>
                                <div
                                    className={'squareStyle'}
                                    onClick={() => openReport(item, 'visa-with-pal')}>
                                    {record.visaPalCount ? record.visaPalCount : 0}
                                </div>
                            </Tooltip>
                            <Tooltip title={'Visa Without Pal'}>
                                <div
                                    className={'squareStyle'}
                                    onClick={() => openReport(item, 'visa-without-pal')}>
                                    {record.visaWithoutPalCount ? record.visaWithoutPalCount : 0}
                                </div>
                            </Tooltip>
                        </> : null}
                    </div>
                ) : null}
                <div className={'btnBox'}>
                    <a
                        onClick={() => {
                            applyForCourse(item.id)
                        }}>
                        <Icon type={'plus-circle'} size={13}/> Add Student
                    </a>

                    {item.file ? (
                        <Tooltip title={'Download Prospectus'}>
                            <a href={item.file} download={item.file} target={'_blank'}>
                                <img
                                    src={'../assets/icons/downloadIcon.png'}
                                    style={{height: 20}}
                                />
                            </a>
                        </Tooltip>
                    ) : null}
                </div>
            </div>
        </li>
    )
    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, item1 => {
            return item1.toString() == item.id
        })
        return userData.department == 'Visa Approved Department' ||
        userData.department == departmentObj.marketing
            ? btnA
            : checkUniversityExists
                ? btnA
                : null
    }
    return userCountryName == 'Canada' ? (
        <>
            {userData.userType == 'admin' ||
            userData.userType == 'agent' ||
            userData.userType == 'subAgent'
                ? btnA
                : null}
            {userData.userType == 'branchManager' && userCountryName == 'Canada'
                ? btnA
                : null}
            {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </>
    ) : null
}

const CanadaUniversityRecordCount = props => {
    let {currentUserRights} = props
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let [countRecords, setCountRecords] = useState({})
    let [userData, setUserData] = useState({})
    let [universityListForMarketingList, setUniversityListForMarketingList] = useState(universityListForMarketing)
    let dispatch = useDispatch()

    let [fcmtApplicationDrawer, setFcmtApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })
    let [cambieApplicationDrawer, setCambieApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })

    let [directApplicationDrawer, setDirectApplicationDrawer] = useState({
        visible: false,
        universityId: ''
    })

    useEffect(() => {
        loadDirectCount()
        events.loadDirecUni()
    }, [])

    let loadDirectCount = async () => {
        let {data, user} = await dispatch(canadaUniversityRecordsCountFxn())
        setCountRecords(data)
        setUserData(user)
    }

    let searchData = (item, type = '') => {
        if (item.id == fcmtUniversity) {
            if (userData && userData.showAllFcmtList) {
                dispatch(
                    getUrlPushWrapper('allFcmtApplicationsList', {
                        appUniversityId: fcmtUniversity,
                        type
                    })
                )
            } else {
                dispatch(
                    getUrlPushWrapper('application.fcmtApplicationsList', {
                        appUniversityId: fcmtUniversity
                    })
                )
            }
        } else if (item.id == CambieHCollege) {
            dispatch(
                getUrlPushWrapper('application.cambieApplicationsList', {
                    appUniversityId: CambieHCollege
                })
            )
        } else if (item.id == LesterCollege) {
            dispatch(
                // aa dekh ure
                getUrlPushWrapper('application.lesterUniversityApplicationList', {
                    appUniversityId: LesterCollege
                })
            )
        } else if (item.id == RoyalArtsCollege) {
            dispatch(
                getUrlPushWrapper('application.royalArtsApplicationsList', {
                    appUniversityId: RoyalArtsCollege
                })
            )
        } else if (item.id == CanadaRoyalHighSchool) {
            dispatch(
                getUrlPushWrapper('application.ciraHighSchoolApplicationList', {
                    appUniversityId: CanadaRoyalHighSchool
                })
            )
        } else if (item.id == CornerStoneCollege) {
            dispatch(getUrlPushWrapper('application.cornerStoneApplicationList'))
        } else if (item.id == NationalCollege) {
            dispatch(getUrlPushWrapper('application.nationalCollegeApplicationList'))
        }
    }

    let openReport = (item, type = '') => {
        dispatch(
            getUrlPushWrapper('canadaDirectUniApp', {
                appUniversityId: item.id,
                type
            })
        )
    }

    const events = {
        showFcmtApplicationDrawer: universityId => {
            setFcmtApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideFcmtApplicationDrawer: () => {
            setFcmtApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        showCambieApplicationDrawer: universityId => {
            setCambieApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideCambieApplicationDrawer: () => {
            setCambieApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        showDirectApplicationDrawer: universityId => {
            setDirectApplicationDrawer({
                visible: true,
                universityId: universityId
            })
        },
        hideDirectApplicationDrawer: () => {
            setDirectApplicationDrawer({
                visible: false,
                universityId: ''
            })
            loadDirectCount()
        },
        applyForCourse: universityId => {
            if (universityId == fcmtUniversity) {
                events.showFcmtApplicationDrawer(universityId)
            } else if (universityId == CambieUniversity) {
                events.showCambieApplicationDrawer(universityId)
            } else if (universityId == CornerStoneCollege) {
                events.showDirectApplicationDrawer(universityId)
            } else if (universityId == NationalCollege) {
                events.showDirectApplicationDrawer(universityId)
            } else {
                events.showDirectApplicationDrawer(universityId)
            }
        },
        loadDirecUni: () => {
            let resp = checkFcmtAdminRight(user, currentUserRights)
            if (!resp) {
                let cloneD = _.clone(universityListForMarketing)
                cloneD = _.reject(cloneD, (item) => {
                    return item.id == fcmtUniversity
                })
                setUniversityListForMarketingList(cloneD)
            }
        }
    }

    return userData && userData._id ? (
        <>
            <div className='card-pane-new listing-section w-100 direct-uni-box'
                 style={{border: 'none'}}>
                <ul className={'count flex-wrap'}>
                    {universityListForMarketingList.map(item => {
                        return (
                            <ButtonLink
                                key={item.id}
                                uniId={item.id}
                                {...props}
                                item={item}
                                userData={userData}
                                record={countRecords[item.id] ? countRecords[item.id] : {}}
                                searchData={searchData}
                                openReport={openReport}
                                applyForCourse={events.applyForCourse}
                            />
                        )
                    })}


                </ul>
            </div>


            {fcmtApplicationDrawer && fcmtApplicationDrawer.visible ? (
                <FlairUniversity
                    visible={fcmtApplicationDrawer.visible}
                    selectedUniversity={fcmtApplicationDrawer.universityId}
                    onClose={events.hideFcmtApplicationDrawer}
                    {...props}
                />
            ) : null}

            {cambieApplicationDrawer && cambieApplicationDrawer.visible ? (
                <CambieUniversity
                    visible={cambieApplicationDrawer.visible}
                    selectedUniversity={cambieApplicationDrawer.universityId}
                    onClose={events.hideCambieApplicationDrawer}
                    {...props}
                />
            ) : null}

            {directApplicationDrawer &&
            directApplicationDrawer.visible &&
            directApplicationDrawer.universityId ? (
                <DirectUniversityComponent
                    visible={directApplicationDrawer.visible}
                    selectedUniversity={directApplicationDrawer.universityId}
                    onClose={events.hideDirectApplicationDrawer}
                    {...props}
                />
            ) : null}
        </>
    ) : null
}

export default CanadaUniversityRecordCount
