import {notification} from '../../../components/Elements/appUtils'
import moment from 'moment'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    addStudentUrl,
    applicationCloseDirectUrl,
    changeApplicationUniversityUrl,
    checkAddStudentInAustraliaUrl,
    checkBranchUserExistsUrl,
    checkReportExistsUrl,
    eduBoardAndUniversityUrl,
    exportStudentListUrl,
    listStudentUrl,
    moveToDraftUrl,
    moveToNextDeskUrl,
    moveToVisaRefundUrl, onshoreStudentDocumentUrl,
    quickAddStudentUrl,
    reActivateApplicationByAgentUrl,
    reActivateApplicationUrl,
    reCalculateCommissionUrl,
    rollbackDeskUrl,
    searchStudent,
    singleStudentDetailsUrl,
    singleStudentUrl,
    studentDetailsUrl, studentDiversityUrl,
    studentDuplicateRecordsUrl,
    submitCommentUrl,
    submitNoteUrl,
    transferApplicationUniversityL1Url,
    transferToOtherAgentUrl, transferToTuitionFeeDepartmentUrl,
    updateApplicationFamilyNameUrl,
    updateApplicationTuitionFeesUrl, updateCountryAndUniversityInApplicationUrl,
    updateUkApplicationTuitionFeesUrl,
    updateUniversityInApplicationUrl,
    uploadApDocumentUrl,
    uploadDocumentUrl,
    uploadLesterDocumentUrl, uploadMozaikLetterUrl,
    uploadProfileImageUrl,
    withdrawalStudentUrl,
    studentCurrentBalanceUrl,
    getWithdrawalListUrl,
    sendWithdrawalOtpUrl,
    addWithdrawalUrl,
    updateWithdrawalUrl, addAppliedEventUrl, appliedEventListUrl
} from '../api/student'
import {studentRegistrationForCanadaUrl} from '../../login/apis'

export const addStudent = (valData, showNotification) => async dispatch => {
    dispatch(showPageLoad())
    if (valData.dateOfBirth) {
        let day = moment(valData.dateOfBirth).day()
        let month = moment(valData.dateOfBirth).month()
        let year = moment(valData.dateOfBirth).year()
        valData.dateOfBirth = moment(`${month + 1}-${day}-${year}`, 'MM-DD-YYYY')
    }
    let {data} = await axios.post(addStudentUrl(), valData, getToken())
    // console.log(data)
    dispatch(hidePageLoad())
    if (!data.error) {
        if (showNotification) {
            notification.success({
                message: data.message || 'Success'
            })
        }
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const listAllStudents = filters => async dispatch => {
    dispatch({type: 'STUDENT_SHOW_LOADER'})
    dispatch({type: 'EMPTY_STUDENT_LIST'})
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listStudentUrl(), config)
    // console.log(data, "backend data");
    dispatch({type: 'STUDENT_HIDE_LOADER'})
    dispatch({type: 'LIST_ALL_STUDENT', payload: data.data})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const getStudent = id => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(singleStudentUrl(id), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const getStudentDetails = id => async dispatch => {
    dispatch({type: 'STUDENT_SHOW_LOADER'})
    let config = {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.post(studentDetailsUrl(), id, config)
    dispatch({type: 'STUDENT_HIDE_LOADER'})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        dispatch({type: 'LIST_STUDENT', payload: data})
    }
    return data.data
}

export const updateStudent = (valData, id) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.put(singleStudentUrl(id), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const searchedStudent = async filters => {
    let {data} = await axios.post(searchStudent(), filters, getToken())
    return data
}

export const moveToNextDesk = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(moveToNextDeskUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}
export const moveToVisaRefund = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(moveToVisaRefundUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const documentUpload = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const uploadApDocument = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadApDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const submitNote = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(submitNoteUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const submitComment = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(submitCommentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const checkBranchUserExists = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        checkBranchUserExistsUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    return data
}

export const uploadProfileImage = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(uploadProfileImageUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const rollBackDesk = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(rollbackDeskUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const quickAddStudent = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(quickAddStudentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const withdrawalStudent = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(withdrawalStudentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const listQuickAddedStudents = filters => async dispatch => {
    dispatch({type: 'STUDENT_SHOW_LOADER'})
    dispatch({type: 'EMPTY_STUDENT_LIST'})
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(quickAddStudentUrl(), config)
    dispatch({type: 'STUDENT_HIDE_LOADER'})
    dispatch({type: 'LIST_ALL_STUDENT', payload: data.data})
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const applicationCloseDirect = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        applicationCloseDirectUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const exportStudentList = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(exportStudentListUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const transferToOtherAgent = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        transferToOtherAgentUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const checkReportExists = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(checkReportExistsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    /*  if (!data.error) {
          notification.success({
            message: data.message || `Updated Successfully`
          })
        } else {
          notification.error({
            message: data.message || 'Error updating'
          })
        }*/
    return data
}

export const reCalculateCommission = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        reCalculateCommissionUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    /* if (!data.error) {
         notification.success({
           message: data.message || `Updated Successfully`
         })
       } else {
         notification.error({
           message: data.message || 'Error updating'
         })
       }*/
    return data
}

export const updateApplicationTuitionFees = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateApplicationTuitionFeesUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}
export const updateApplicationFamilyName = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateApplicationFamilyNameUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const reActivateApplication = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        reActivateApplicationUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const changeApplicationUniversity = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        changeApplicationUniversityUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const eduBoardAndUniversity = async valdata => {
    let resp = await axios.post(eduBoardAndUniversityUrl(), valdata)
    return resp
}

export const updateUniversityInApplication = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateUniversityInApplicationUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const moveToDraftApplication = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(moveToDraftUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateUkApplicationTuitionFeesFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateUkApplicationTuitionFeesUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}
export const transferApplicationUniversityL1Fxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        transferApplicationUniversityL1Url(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const studentDuplicateRecordsFxn = async valData => {
    let {data} = await axios.post(
        studentDuplicateRecordsUrl(),
        valData,
        getToken()
    )
    return data
}
export const singleStudentDetailsFxn = valData => async dispatch => {
    let {data} = await axios.post(
        singleStudentDetailsUrl(),
        valData,
        getToken()
    )
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
export const checkAddStudentInAustraliaFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        checkAddStudentInAustraliaUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    return data
}
export const reActivateApplicationByAgentFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        reActivateApplicationByAgentUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}
export const uploadLesterDocumentFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        uploadLesterDocumentUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Uploaded Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const studentRegistrationForCanadaFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...await getToken()
    }
    let {data} = await axios.get(studentRegistrationForCanadaUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
export const onshoreStudentDocumentFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(onshoreStudentDocumentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Uploaded Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const studentDiversityFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(studentDiversityUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const uploadMozaikLetterFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        uploadMozaikLetterUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Uploaded Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error '
        })
    }
    return data
}

export const transferToTuitionFeeDepartmentFxn = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(transferToTuitionFeeDepartmentUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}
export const updateCountryAndUniversityInApplicationFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateCountryAndUniversityInApplicationUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data
}

export const getStudentCurrentBalance = (balanceValue) => async (dispatch) => {
    const requestData = {
        balance: balanceValue
    }
    const {data} = await axios.post(studentCurrentBalanceUrl(), requestData, getToken())
    return data
}

export const getStudentWithdrawal = (filters) => async (dispatch) => {
    const config = {
        params: {...filters},
        ...await getToken()
    }
    const {data} = await axios.get(getWithdrawalListUrl(), config)
    return data.data
}

export const sendWithdrawalOtp = (otpData) => async (dispatch) => {
    dispatch(showPageLoad())
    const userData = {
        ...otpData,
        email: 'studenttest@yopmail.com'
    }

    try {
        let {data} = await axios.post(sendWithdrawalOtpUrl(), userData, getToken())
        dispatch(hidePageLoad())

        if (!data.error) {
            notification.success({
                message: data.message || 'Success'
            })
        } else {
            notification.error({
                message: data.message || 'Error'
            })
        }

        return data
    } catch (error) {
        console.error('Error sending withdrawal OTP:', error)
    }
}

export const addWithdrawal = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addWithdrawalUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const updateWithdrawal = (valData) => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateWithdrawalUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const addAppliedEventFxn = (eventId, event) => async (dispatch) => {
    const valData = {
        eventId: eventId,
        event: event
    }
    dispatch(showPageLoad())
    let {data} = await axios.post(addAppliedEventUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const appliedEventListFxn = (filters) => async (dispatch) => {
    const config = {
        params: {...filters},
        ...await getToken()
    }
    const {data} = await axios.get(appliedEventListUrl(), config)
    return data.data
}


export const uploadTuitionFeesByAgentFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(moveToNextDeskUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: 'Tuition fee receipt uploaded successfully.'
        })
    } else {
        notification.error({
            message: 'Tuition fee receipt not upload, please try again.'
        })
    }
    return data
}
