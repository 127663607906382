import React, {useState, useEffect} from 'react'
import {notification, Input, Card, Drawer, Button, Radio, Icon} from '../../../components/Elements/appUtils'
import {InputBox} from '../../../components/_utils/appUtils'
import {updatePassword, UserRating} from '../actions/user'
import {useDispatch} from 'react-redux'

const {TextArea} = Input
const ButtonGroup = Button.Group

const RatingDrawer = (props) => {
    const dispatch = useDispatch()
    let {userData, visible, onClose} = props
    let [points, setPoints] = useState('')
    let [comment, setComment] = useState('')
    let [pointsType, setPointsType] = useState('add')

    const handleSubmit = async () => {

        if (!points || points <= 0) {
            return notification.warn({message: 'Please Enter Points.'})
        }
        if (!pointsType) {
            return notification.warn({message: 'Please choose point Type'})
        }
        if (!comment) {
            return notification.warn({message: 'Please Enter Comment'})
        }
        let resp = await dispatch(UserRating({userId: userData._id, points, pointsType: pointsType, comment}))
        onClose()
    }

    const handlePointChange = (val) => {
        setPointsType(val)
    }

    let selectBefore = (
        <Radio.Group value={pointsType} onChange={handlePointChange}>
            <Radio.Button value='add'> <Icon type={'plus'}/></Radio.Button>
            <Radio.Button value='deduct'> <Icon type={'minus'}/></Radio.Button>
        </Radio.Group>
    )
    let selectBefore1 = (
        <ButtonGroup>
            <a onClick={() => handlePointChange('add')} className={pointsType == 'add' ? 'selected' : null}>
                <Icon type={'plus'}/></a>
            <a onClick={() => handlePointChange('deduct')} className={pointsType == 'deduct' ? 'selected' : null}>
                <Icon type={'minus'}/></a>
        </ButtonGroup>
    )

    return (
        <Drawer
            title={`${userData.name}'s Rating.`}
            visible={visible}
            placement='right'
            closable={true}
            width={'30%'}
            onClose={onClose}
        >
            <Card>

                <InputBox title={'Points'} style={{padding: 0}}>
                    <div className={'customGrpBtn'}>
                        <Input addonBefore={selectBefore1} value={points} onChange={(e) =>
                            setPoints(e.target.value)
                        }
                               placeholder={'Points'}/>
                    </div>
                </InputBox>

                <InputBox title={'Comment'}>

                    <TextArea rows={4} value={comment}
                              onChange={(e) => setComment(e.target.value)}
                    />
                </InputBox>
                <InputBox>
                    <Button onClick={handleSubmit}>Submit</Button>
                </InputBox>
            </Card>

        </Drawer>
    )
}

export default RatingDrawer
