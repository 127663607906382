export default (
    state = {
        loader: false,
        city: {},
        allCities: [],
        totalCities: 0,
    },
    action
) => {
    switch (action.type) {
        case 'CITY_SHOW_LOADER':
            return {...state, loader: true};
        case 'CITY_HIDE_LOADER':
            return {...state, loader: false};
        case 'LIST_CITY':
            return {...state, city: action.payload.data};
        case 'ADD_CITY':
            return {...state, addedCity: action.payload};
        case 'LIST_ALL_CITY':
            return {...state, allCities: action.payload.data, totalCities: action.payload.total};
        case 'EMPTY_CITY_LIST':
            return {...state, allCities: [], totalCities: 0}
        default:
            return state
    }
}
  