import React, {useEffect, useState} from 'react'
import {Button, Icon, Tooltip} from '../../../components/Elements/appUtils'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import UserLogsDrawer from './userLogs'

const LogsComponents = (props) => {
    let [visible, setVisible] = useState(false)
    let {userData, currentUser} = props

    return (
        <CheckUserRight user={currentUser} rightUserType={['admin']}>
            <Tooltip title="Login Logs">
                <button
                    className={'btn'}
                    onClick={() => {
                        setVisible(true)
                    }}
                >
                    <Icon type={'unordered-list'}/>
                </button>
            </Tooltip>
            {visible ?
                <UserLogsDrawer
                    visible={visible}
                    user={userData}
                    onClose={() => setVisible(false)}/>
                : null}
        </CheckUserRight>
    )
}
export default LogsComponents
