import {notification} from '../../../components/Elements/appUtils'
import {getToken, customAxios as axios} from '../../../request'
import {
  addAgentUrl,
  countryListUrl,
  addEducatorUrl,
  AddStudentWithoutAuthUrl,
  getCourseUrl,
  getUniversityByCountryUrl,
  ResetPasswordUrl,
  RecoverPasswordUrl,
  LogoutUrl,
  StudentWithoutAuthSendOtpUrl,
  getUserRightsUrl,
  registerNewStudentUrl,
  registerStudentIntakeUrl,
  registerStudentUniversityUrl,
  registerStudentUniversityCourseUrl, uploadDocumentByStudentUrl,
  checkRecoverCodeUrl, studentRegistrationForCanadaUrl, onShoreStudentRegisteredUrl, getStudentByReferCodeUrl,
  addInstituteUrl,
} from '../apis'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addAgent = (formData) => async (dispatch) => {
  let { data } = await axios.post(addAgentUrl(), formData)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const countryList = async (valData = {}) => {
  let config = {
    params: valData
  }
  let { data } = await axios.get(countryListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const addEducator = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addEducatorUrl(), valData)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const getUniversity = async (filters) => {
  let config = {
    params: { ...filters }
  }
  let { data } = await axios.get(getUniversityByCountryUrl(), config)
  return data.data
}

export const getCourse = async (filters) => {
  let config = {
    params: { ...filters }
  }
  let { data } = await axios.get(getCourseUrl(), config)
  return data.data
}

export const addStudentWithoutAuth = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AddStudentWithoutAuthUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    /*notification.success({
      message: data.message || 'Success'
    })*/
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}

export const registerNewStudent = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(registerNewStudentUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    /*notification.success({
      message: data.message || 'Success'
    })*/
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}


export const recoverPassword = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(RecoverPasswordUrl(), valData)
  if (!data.error) {
    /*  notification.success({
        message: data.message || 'Success'
      })*/
    dispatch(hidePageLoad())
  } else {
    /* notification.error({
       message: data.message || 'Error'
     })*/
    dispatch(hidePageLoad())
  }
  return data
}

export const resetPassword = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ResetPasswordUrl(), valData)
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}

export const LogoutLogs = async (valData) => {
  let { data } = await axios.post(LogoutUrl(), { logsFrom: 'Web', ...valData }, getToken())
  return data
}

export const StudentWithoutAuthSendOtp = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(StudentWithoutAuthSendOtpUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}

export const registerStudentIntake = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(registerStudentIntakeUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const registerStudentUniversity = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(registerStudentUniversityUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const registerStudentUniversityCourse = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(registerStudentUniversityCourseUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  console.log('course', data)
  if (!data.error) {
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const uploadDocumentByStudent = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(uploadDocumentByStudentUrl(), valData, getToken())
  if (!data.error) {
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}


export const getUserRights = async (valData) => {
  let { data } = await axios.post(getUserRightsUrl(), valData, getToken())
  return data
}
export const checkRecoverCodeFxn = async (valData) => {
  let { data } = await axios.post(checkRecoverCodeUrl(), valData, getToken())
  return data
}

export const studentRegistrationForCanadaFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(studentRegistrationForCanadaUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const onShoreStudentRegisteredFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(onShoreStudentRegisteredUrl(), valData, {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  })
  if (!data.error) {
    /*notification.success({
      message: data.message || 'Success'
    })*/
    dispatch(hidePageLoad())
  } else {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const getStudentByReferCodeFxn = async (valData) => {
  let config = {
    'headers': {
      'Access-Control-Allow-Origin': '*',
      'Authorization': ''
    }
  }
  let { data } = await axios.post(getStudentByReferCodeUrl(), valData, config)
  return data
}


export const addInstitute = (valData) => async (dispatch) => {
  console.log(valData, "valData")
  let { data } = await axios.post(addInstituteUrl(), valData)
  console.log(data, "data")
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

