import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import Dashboard from './containers/dashboard/views/dashboard'
import AddSubAgent from './containers/users/views/addSubAgent'
import AllSubAgent from './containers/users/views/allSubAgent'
import EditSubAgent from './containers/users/views/editSubAgent'
import {adminRightUser, gicCommissionValue} from "./components/_utils/appUtils";
import AllBranchManagers from './containers/users/list/allBranchManagers'
import AllAgents from './containers/users/list/allAgents'
import AllBranchUsers from './containers/users/list/allBranchUsers'
import AllMarketingManagers from './containers/users/list/allMarketingManagers'
import AllMasters from './containers/users/list/allMasters'
import AllSubAgents from './containers/users/list/allSubAgents'
import OtherUsers from './containers/users/list/otherUsers'
import AddTeam from './containers/users/views/addTeam'
import AllTeam from './containers/users/views/allTeam'
import AddGicPage from './containers/dashboard/gic/addGicPage'
import GicList from './containers/dashboard/gic/gicList'
import GicAccountFundedList from './containers/dashboard/gic/gicAccountFundedList'
import GicAccountOpenedList from './containers/dashboard/gic/gicAccountOpenedList'
import WithdrawalList from './containers/dashboard/gic/Withdrawals/views/list'
import AddCounsellor from './containers/users/views/counsellor/addCounsellor'
import EditCounsellor from './containers/users/views/counsellor/editCounsellor'
import CanadaOnshoreProgramList from './containers/Intakes/programList/canadaOnshoreProgramList'
import AddStudent from './containers/student/views/add'
import AllStudent from './containers/student/views/all'
import EditStudent from './containers/student/views/edit'
import OnShoreStudentList from './containers/student/components/onShoreStudentList'
import AddUsers from './containers/users/views/add'
import AddMarketingManager from './containers/users/views/addMarketingManager'
import AddMaster from './containers/users/masters/addMaster'
import EditUser from './containers/users/views/edit'
import EditMarketingUser from './containers/users/views/editMarketingUser'
import React from "react";

const Testing = () => {
    return (
        <>
            Test
        </>
    )
}

let menu = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        name: 'Dashboard',
        icon: 'dashboard',
        key: 'dashboard',
        homepage: true,
        component: Dashboard,
        authority: [
            'student',
            'admin',
            'agent',
            'institute',
            'subAgent',
            'onshoreManager',
            'branchManager',
            'branchUser',
            'master',
            'interviewer',
            'university',
            'userManager',
            'hr',
            'reception',
            'marketingManager',
            'onshoreCanada',
            'courseMaster',
            'cambieMarketingManager',
            'cambieBranchUser',
            'eventManager',
            'counsellingManager',
            'unizhomeManager',
            'gicManager',

            'cornerStoneManager',
            'nationalCollegeManager',
            'canadaOnshoreAdmin',
            'cambieManager',
            'gicMarketing',
            'onshoreAccountant',
            'team',
            'cambieAccountant',
            'cambieDirector',
            'cambieReception',
            'itTeam',
            'directHead'
        ]
    },

    /*   {
           path: '/subAgent',
           name: 'Our Users',
           title: 'Our Users',
           icon: 'user',
           key: 'subAgent',
           pathKey: 'subAgent',
           dontShowOnMenu: true,
           authority: ['agent'],
           children: [
               {
                   path: '/subAgent/add',
                   name: 'Add User',
                   title: 'Add User',
                   key: 'addSubAgent',
                   component: AddSubAgent,
                   authority: ['agent']
               },
               {
                   path: '/subAgent/all',
                   name: 'All User',
                   title: 'All User',
                   key: 'allSubAgent',
                   component: AllSubAgent,
                   authority: ['agent']
               },
               {
                   path: '/subAgent/edit/:id',
                   name: 'Edit User',
                   title: 'Edit User',
                   key: 'editSubAgent',
                   dontShowOnMenu: true,
                   component: EditSubAgent,
                   authority: ['admin', 'agent']
               }
           ]
       },*/
    {
        path: '/users',
        title: 'Users',
        name: 'Users',
        icon: 'user',
        key: 'user',
        pathKey: 'users',
        homepage: true,
        authority: [
            ...adminRightUser,
            'agent',
            'branchManager',
            'branchUser',
            'master',
            'userManager'
        ],
        children: [
            {
                path: '/users/testing',
                name: 'All Branch Manager',
                title: 'All Branch Manager',
                key: 'allBranchManagers',
                component: Testing,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-branch-manager',
                name: 'All Branch Manager',
                title: 'All Branch Manager',
                key: 'allBranchManagers',
                component: AllBranchManagers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-marketing-manager',
                name: 'All Marketing Manager',
                title: 'All Marketing Manager',
                key: 'allMarketingManagers',
                component: AllMarketingManagers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-branch-user',
                name: 'All Branch User',
                title: 'All Branch User',
                key: 'allBranchUsers',
                component: AllBranchUsers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-masters',
                name: 'All Masters',
                title: 'All Masters',
                key: 'allMasters',
                component: AllMasters,
                authority: [...adminRightUser, 'userManager']
            },

            {
                path: '/users/all-agents',
                name: 'All Agents',
                title: 'All Agents',
                key: 'allAgents',
                component: AllAgents,
                restrict: true,
                authority: [
                    ...adminRightUser,
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            },
            {
                path: '/users/all-sub-agents',
                name: 'All Sub Agents',
                title: 'All Sub Agents',
                key: 'allSubAgents',
                restrict: true,
                component: AllSubAgents,
                authority: [
                    ...adminRightUser,
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            },
            {
                path: '/users/other-users',
                name: 'Other Users',
                title: 'Other Users',
                key: 'otherUsers',
                component: OtherUsers,
                authority: [...adminRightUser, 'userManager']
            }
        ]
    },
    /*   {
           path: '/expand-your-team',
           pathKey: 'expand-your-team',
           title: 'Expand Your Team',
           name: 'Expand Your Team',
           icon: 'user',
           key: 'expandYourTeam',
           homepage: true,
           authority: ['agent'],
           children: [
               {
                   path: '/expand-your-team/allSubAgent',
                   name: 'All Sub Agents',
                   title: 'All Sub Agents',
                   key: 'allSubAgent',
                   component: AllSubAgent,
                   authority: ['agent']
               },
               {
                   path: '/expand-your-team/allTeams',
                   name: 'All Teams',
                   title: 'All Teams',
                   key: 'teams',
                   component: AllTeam,
                   authority: ['agent']
               },
               {
                   path: '/addTeam',
                   name: 'Add Team',
                   title: 'Add Team',
                   key: 'addTeam',
                   component: AddTeam,
                   authority: ['agent'],
                   dontShowOnMenu: true
               }
           ]
       },*/

    /*  {
          path: '/gic',
          title: 'Gic',
          name: `Open Gic Acc-Get ${gicCommissionValue}`,
          icon: 'gic',
          key: 'gic',
          pathKey: 'gic',
          newLink: true,
          homepage: true,
          restrict: true,
          authority: ['admin', 'agent', 'gicManager', 'branchManager'],
          children: [
              {
                  path: '/gic/add-gic',
                  name: 'Add GIC Request',
                  title: 'Add GIC Request',
                  key: 'addGicRequest',
                  component: AddGicPage,
                  authority: ['agent']
              },
              {
                  path: '/gic/gic-Request-list',
                  name: 'GIC Requested',
                  title: 'GIC list',
                  key: 'gicRequested',
                  component: GicList,
                  authority: ['admin', 'agent', 'gicManager', 'branchManager']
              },
              {
                  name: 'GIC Account Opened',
                  path: '/gic/gic-account-open-list',
                  key: 'gicAccountOpened',
                  title: 'GIC Account Opened',
                  component: GicAccountOpenedList,
                  authority: ['admin', 'agent', 'gicManager', 'branchManager']
              },
              {
                  name: 'GIC Account Funded',
                  path: '/gic/gic-account-funded-list',
                  key: 'gicApproved',
                  title: 'GIC list',
                  component: GicAccountFundedList,
                  authority: ['admin', 'agent', 'gicManager', 'branchManager']
              },
              {
                  name: 'GIC Commission Withdrawal',
                  path: '/gic/gic-withdrawal-list',
                  key: 'withdrawalList',
                  title: 'GIC list',
                  component: WithdrawalList,
                  authority: ['admin', 'agent', 'gicManager', 'branchManager']
              }
          ]
      },*/
    /*{
        dontShowOnMenu: true,
        path: '/users/add-counsellor',
        name: 'Add Counsellor',
        title: 'Add Counsellor',
        icon: 'user',
        key: 'addCounsellor',
        component: AddCounsellor,
        authority: ['admin', 'userManager']
    },*/
    {
        path: '/canada-Onshore',
        name: 'Apply for Canada Onshore',
        icon: 'user',
        key: 'canadaOnShoreCourseList',
        newLink: true,
        component: CanadaOnshoreProgramList,
        restrict: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr'
        ]
    },
    {
        homepage: true,
        icon: 'user',
        path: '/add-student',
        name: 'Add Student',
        title: 'Add Student',
        component: AddStudent,
        restrict: true,
        key: 'addStudent',
        authority: [
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'marketingManager',
            'canadaOnshoreAdmin',
            'team',
            ...adminRightUser
        ]
    },
    {
        homepage: true,
        path: '/students',
        name: 'Students',
        title: 'Students',
        component: AllStudent,
        icon: 'user',
        key: 'allStudent',
        restrict: true,
        authority: [
            ...adminRightUser,
            'agent',
            'subAgent',
            'team',
            'branchManager',
            'branchUser',
            'marketingManager',
            'userManager',
            'canadaOnshoreAdmin'
        ]
    },
    {
        homepage: true,
        path: '/direct-pending-students',
        name: 'Direct Pending Students',
        title: 'Direct Pending Students',
        component: OnShoreStudentList,
        icon: 'user',
        key: 'pendingOnShoreApplications',
        restrict: true,
        authority: ['branchManager', 'branchUser']
    },
    /* {
         path: '/user',
         name: 'Users',
         icon: 'user',
         title: 'Users',
         dontShowOnMenu: true,
         key: 'Users',
         pathKey: 'user',
         authority: ['admin', 'branchManager', 'userManager', 'hr'],
         children: [
             {
                 path: '/user/add-user',
                 name: 'Add User',
                 title: 'Add User',
                 key: 'addUser',
                 component: AddUsers,
                 authority: ['admin', 'branchManager', 'userManager', 'hr']
             },
             {
                 path: '/user/add-marking-user',
                 name: 'Add Marketing User',
                 title: 'Add Marketing User',
                 key: 'addMarketingUser',
                 component: AddMarketingManager,
                 authority: ['admin', 'userManager']
             },
             {
                 path: '/user/add-master',
                 name: 'Add Master',
                 title: 'Add Master',
                 key: 'addMaster',
                 component: AddMaster,
                 authority: ['admin', 'userManager']
             },
             {
                 path: '/edit-markingUser/:id',
                 name: 'Edit Marketing User',
                 title: 'Edit Marketing User',
                 key: 'editMarketingUser',
                 component: EditMarketingUser,
                 authority: ['admin', 'userManager']
             },

             {
                 path: '/user/edit-user/:id',
                 name: 'Edit User',
                 title: 'Edit User',
                 dontShowOnMenu: true,
                 key: 'editUser',
                 component: EditUser,
                 authority: ['admin', 'branchManager']
             },
             {
                 path: '/edit-counsellor/:id',
                 name: 'Edit Counselling',
                 title: 'Edit Counselling',
                 dontShowOnMenu: true,
                 key: 'editCounsellor',
                 component: EditCounsellor,
                 authority: ['admin', 'userManager']
             }
         ]
     },*/
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = keyString => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }
    return val
}
//</editor-fold>

export default menu
