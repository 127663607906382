import React, {useEffect, useState} from "react"
import {Card, Col, Drawer, notification, Row, Select, Tooltip} from '../../../components/Elements/appUtils';
import {DirectUniversityCheck, InputBox} from "../../../components/_utils/appUtils";
import {getMarketingUserByUniversityFxn} from "../../users/actions/user";
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {updateMarketingUserManuallyFxn} from "../actions/marketing";
import {useDispatch} from "react-redux";

let {Option} = Select;
const UpdateManuallyMarketingUserDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onSubmit, application, studentId} = props;
    let [userList, setUserList] = useState([])
    let [marketingUserId, setMarketingUserId] = useState("");
    const events = {
        loadMarketingUser: async () => {
            let universityId = application.courseUniversity && application.courseUniversity._id ? application.courseUniversity._id : null
            let obj = {
                countryId: application.universityCountry._id,
                university: DirectUniversityCheck(universityId) ? application.courseUniversity : "Public"
            }
            let {data} = await getMarketingUserByUniversityFxn(obj);
            setUserList(data);
        },
        submitMarketingUser: async () => {
            if (!marketingUserId) {
                notification.warning({message: "Please choose marketing user."})
                return
            }
            let obj = {
                marketingUserId,
                studentId,
                applicationId: application._id
            }
            let resp = await dispatch(updateMarketingUserManuallyFxn(obj))
            if (resp && resp.success) {
                onSubmit()
            }
        },
        setPreviousMarketing: () => {
            let {marketingUserId} = application;
            if (marketingUserId && marketingUserId._id) {
                setMarketingUserId(marketingUserId._id)
            }
        }
    }
    useEffect(() => {
        events.loadMarketingUser()
        events.setPreviousMarketing()
    }, [application])
    return (
        <>
            <Drawer
                title={'Update Marketing User'}
                visible={visible}
                placement="right"
                onClose={onClose}
                width={'35%'}>
                <div className={'card unizportal'}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <InputBox title={'Choose Marketing User'}>
                                <Select
                                    value={marketingUserId}
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        setMarketingUserId(value)
                                    }}>
                                    {userList && userList.length ? userList.map((item) => {
                                        return (
                                            <Option value={item._id} key={item._id}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </InputBox>
                        </Col>

                        <Col span={24} className={'mt20'}>
                            <a className={'btn btn-success mr10'} onClick={() => {
                                events.submitMarketingUser()
                            }}>
                                Update
                            </a>
                            <a className={'btn btn-default '} onClick={onClose}>
                                Cancel
                            </a>

                        </Col>
                    </Row>
                </div>
            </Drawer>

        </>
    )
}
const ShowUpdateButton = (props) => {
    let {onClick = {}} = props;
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    return (
        <>
            {user.userType == "admin" || user.userType == "userManager" ? <>
                <Tooltip title={'Update marketing user'}>
                    <a onClick={onClick}>
                        <LazyLoadImage src={"../assets/icons/marketing_icon.png"} style={{height: 25}}/>
                    </a>
                </Tooltip>
            </> : null}
        </>
    )
}

export default UpdateManuallyMarketingUserDrawer
UpdateManuallyMarketingUserDrawer.ShowUpdateButton = ShowUpdateButton;
