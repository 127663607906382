import {notification} from '../../../components/Elements/appUtils'
import {getToken, customAxios as axios} from '../../../request'
import {addCountryUrl, listCountriesUrl, listCountryUrl} from '../api/countries';


export const addCountry = (valData) => async (dispatch) => {
    dispatch({type: 'COUNTRY_SHOWLOADER'});
    let {data} = await axios.post(addCountryUrl(), valData, getToken());
    dispatch({type: 'COUNTRY_HIDELOADER'});
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data;
}


export const listAllCountries = (filters) => async (dispatch) => {
    dispatch({type: 'COUNTRY_SHOWLOADER'});
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
    }
    let {data} = await axios.get(listCountriesUrl(), config);
    // console.log("data from backend is", data);
    dispatch({type: 'COUNTRY_HIDELOADER'});
    dispatch({type: 'LIST_ALL_COUNTRY', payload: data.data});
    if (data.error) {
        notification.error({
            message: data.message || 'Error',
        })
    }
    return data.data;

}


export const getCountry = id => async (dispatch) => {
    dispatch({type: 'COUNTRY_SHOWLOADER'})
    let config = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(listCountryUrl(id), config);
    dispatch({type: 'COUNTRY_HIDELOADER'});
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        dispatch({type: 'LIST_COUNTRY', payload: data})
    }
    return data.data;
}


export const updateCountry = (valData, id) => async (dispatch) => {
    dispatch({type: 'COUNTRY_SHOWLOADER'});
    let {data} = await axios.put(listCountryUrl(id), valData, getToken());
    dispatch({type: 'COUNTRY_HIDELOADER'});
    if (!data.error) {
        notification.success({
            message: data.message || `Updated Successfully`
        })
    } else {
        notification.error({
            message: data.message || 'Error updating'
        })
    }
    return data;
};

