import {Button, DatePicker, Col, Form, Input, notification} from '../../../components/Elements/appUtils'
import {push} from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  CountryCodeWithFlag,
  CountryJson
} from '../../../components/_utils/countryUtil'
import {
  hideLoader,
  hidePageLoad
} from '../../../modules/actions'
import {
  studentRegistrationForCanadaFxn,
  countryList,
  getCourse,
  getUniversity,
  onShoreStudentRegisteredFxn, getStudentByReferCodeFxn
} from '../action'
import { RegisterButton } from '../registerButton'
import { LoadState } from '../../../components/_utils/countryUtil'
import { countryIds, onshoreReferenceName } from '../../../components/_utils/appUtils'
import debounce from 'lodash/debounce'

const canadaOptions = [
  { name: 'Inside Canada', value: true },
  { name: 'Outside Canada', value: false }
]

const referralOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false }
]
// const dateFormatList = 'DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', 'DD-MM-YYYY']

// const canadaOnshore = true;

class EducatorForm extends React.Component {
  events = {
    getUniversityByCountry: async countryId => {
      if (countryId) {
        let { data } = await getUniversity({
          results: 1000000,
          universityCountry: countryId,
          sortField: 'universityName',
          sortOrder: 'ascend',
          select: ['universityName', 'applicationFee']
        })
        this.setState({ universityList: data, courseList: [] })
      }
    },
    getCourseByUniversity: async universityId => {
      if (universityId) {
        let { data } = await getCourse({
          results: 1000000,
          courseUniversity: universityId,
          sortField: 'courseName',
          sortOrder: 'ascend',
          select: ['courseName', 'tuitionFee']
        })
        this.setState({ courseList: data })
      }
    },
    chooseDocument: e => {
      let { name, files } = e.target
      if (files && files.length) {
        this.setState({
          [name]: files[0]
        })
      }
    },
    loadReferralStudent: async () => {
      let referCode = this.props.form.getFieldValue('onshoreReferBy')
      let canadaOnshore = this.props.form.getFieldValue('canadaOnshore')
      // let canadaOnshore = canadaOnshore
      if (referCode.length >= 3) {
        let { data } = await getStudentByReferCodeFxn({ referCode, canadaOnshore })
        if (data && data._id) {
          this.setState({ referUserName: data.companyName ? data.companyName : data.name })
        } else {
          this.setState({ referUserName: '' })
        }
      } else {
        this.setState({ referUserName: '' })
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      countryList: [],
      universityList: [],
      courseList: [],
      intakesList: [],
      intake: {},
      finalObjForAddStudent: {},
      isOtpScreen: false,
      campusList: [],
      countryCode: '',
      alternateCountryCode: '',
      dateOfBirth: false,
      referUserName: ''
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
    this.loadCountry()
    this.setCountryDefault()
  }

  setCountryDefault = () => {
    setTimeout(() => {
      this.setState({
        countryCode: 'CA_+1'
      })
    }, 100)
  }



  checkIsLoggedIn() {
    let resp = localStorage.getItem('token')
    if (resp) {
      let { dispatch } = this.props
      dispatch(push('/dashboard'))
    }
  }

  async loadCountry() {
    try {
      let { data } = await countryList()
      const canadaCountry = data.find(country => country.countryName === 'Canada')
      if (canadaCountry) {
        const statesForCanada = LoadState('38')
        this.setState({
          countryList: data,
          universityList: [],
          courseList: [],
          statesForCanada: statesForCanada || []
        })
      } else {
        this.setState({ countryList: data, universityList: [], courseList: [], statesForCanada: [] })
      }
    } catch (error) {
    }
  }


  submitForOtp = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        values.countryISOCode = this.state.countryCode
        // values.registerCountry = values.countryId
        values.registerCountry = countryIds.canadaOnshore
        try {
          let resp = await dispatch(studentRegistrationForCanadaFxn(values))
          let { success, message } = resp
          if (success) {
            this.setState({ finalObjForAddStudent: values, isOtpScreen: true })
          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }

      }
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let { finalObjForAddStudent, profile, dateOfBirth } = this.state
        let fd = new FormData()
        if (dateOfBirth) {
          finalObjForAddStudent.dateOfBirth = dateOfBirth
        }
        // values.canadaOnshore = canadaOnshore

        fd.append(
          'obj',
          JSON.stringify({ ...finalObjForAddStudent, ...values })
        )

        if (profile && profile.name) {
          fd.append('profile', profile)
        }

        try {
          let resp = await dispatch(onShoreStudentRegisteredFxn(fd))
          let { success, data, message, token } = resp
          if (success) {
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(data))
            this.setState({
              finalObjForAddStudent: {},
              isOtpScreen: false
            })
            dispatch({
              type: 'SET_CURRENT_USER',
              user: data
            })
            dispatch({
              type: 'START_LOAD_CURRENT_USER'
            })
            setTimeout(() => {
              dispatch(hidePageLoad())
              dispatch(push('/dashboard'))
            }, 1000)

          }
        } catch (e) {
          if (e) {
            notification.error({ message: JSON.stringify(e) })
          }
          dispatch(hidePageLoad())
        }

      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form
    const { loading, dispatch } = this.props
    const {
      isOtpScreen,
      countryCode,
      dateOfBirth,
      referUserName,
      statesForCanada
    } = this.state
    const isInsideCanada = getFieldValue('canadaOnshore') === true
    // const isInsideCanada = canadaOnshore
    let { events } = this

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputFields = [
      {
        key: 'name',
        label: 'Name',
        required: true,
        placeholder: 'Enter Name',
        span: 6
      },
      {
        key: 'canadaOnshore',
        label: 'Current Country',
        required: true,
        placeholder: 'Select',
        type: 'select',
        options: canadaOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: (x, y) => {
          const countryCode = x ? 'CA_+1' : 'IN_+91';
          setFieldsValue({
            countryCode ,
            canadaOnshore: x,
            onshoreReferBy: '',
            isOnshoreReferral: x == false ? true : undefined
          });
          this.setState({

            countryCode,
            referUserName: ''
          });
        },
        span: 6
      },

      {
        key: 'dateOfBirth',
        type: 'date',
        label: 'Date Of Birth',
        required: true,
        placeholder: 'Enter Date of Birth',
        format: { dateFormatList },
        span: 6,
        customField: (
          <div style={{ marginTop: 7 }}>
            <label className={'label-new'}>
              *Date of Birth
              <small> (DD/MM/YYYY)</small>
            </label>
            {/* <Input
                            type={'date'}
                            className={'form-control js_datepicker'}
                            value={dateOfBirth}
                            required={true}
                            onChange={e => {
                                this.setState({
                                    dateOfBirth: e.target.value
                                })

                            }}
                        />*/}

            <DatePicker
              format={dateFormatList}
              value={dateOfBirth}
              placeholder={'Enter Date of Birth'}
              onChange={(e) => {
                this.setState({
                  dateOfBirth: e
                })
              }} />

          </div>
        )
      },
      {
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        placeholder: 'Enter Email',
        span: 6
      },

      {
        key: 'countryCode',
        span: 6,
        customField: (
          <div>
            <CountryCodeWithFlag
              countryCode={countryCode}
              chooseCode={val => {
                this.setState({
                  countryCode: val
                })
              }}
            />
          </div>
        )
      },

      {
        key: 'mobile',
        label: 'Phone Number',
        required: true,
        placeholder: 'Enter Phone No',
        span: 6
      },

      {
        key: 'nationality',
        label: 'Nationality',
        required: true,
        type: 'select',
        showSearch: true,
        options: CountryJson,
        span: 6,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            nationality: x
          })
        }
      },
      {
        key: 'stateName',
        label: 'State/Region',
        required: true,
        placeholder: 'Enter State/Region',
        span: 6,
        ...(isInsideCanada
          ? {
            type: 'select',
            options: statesForCanada ? statesForCanada : [],
            keyAccessor: x => x.name,
            valueAccessor: x => `${x.name}`,
            onChange: x => {
              setFieldsValue({
                stateName: x
              })
            }
          }
          : {})
      },

      /*{
        key: 'cityName',
        label: 'City',
        required: true,
        placeholder: 'Enter City',
        span: 6
      },*/
      /*{
          key: 'profile',
          span: 6,
          customField: (
              <div style={{marginTop: 6}}>
                  <label className={'label-new'}>Profile</label>
                  <Input
                      type={'file'}
                      name={'profile'}
                      id={'profile'}
                      className={'form-control'}
                      onChange={e => {
                          events.chooseDocument(e)
                      }}
                  />
              </div>
          )
      },*/
      {
        key: 'isOnshoreReferral',
        label: 'Do you have a referral code?',
        required: true,
        type: 'select',
        options: referralOptions,
        span: 6,
        disabled: getFieldValue('canadaOnshore') == false,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            isOnshoreReferral: x,
            onshoreReferBy: null
          })
        }
      },
      /* {
           key: 'isOnshoreReferral',
           span: 12,
           customField: (
               <div>
                   <div className="form-group lightFont">
                       <Checkbox className={'authCheckbox'} checked={isOnshoreReferral}
                                 onChange={(e) => {
                                     this.setState({isOnshoreReferral: !isOnshoreReferral})
                                     setFieldsValue({
                                         onshoreReferBy: null
                                     })
                                 }}>Do you have a referral code?</Checkbox>
                   </div>
               </div>
           )
       },*/
      {
        key: 'onshoreReferBy',
        label: isInsideCanada ? 'Referral Code' : 'Institute Referral Code',
        placeholder: isInsideCanada ? 'Enter Referral Code' : 'Enter Institute Referral Code',
        hidden: !getFieldValue('isOnshoreReferral'),
        required: true,
        span: 6,
        onChange: ({ target }) => {
          setFieldsValue({
            onshoreReferBy: target.value
          })
          events.loadReferralStudent()
        },
        extra: (
          <>
            <div style={{ marginTop: 5, color: 'black', fontSize: 13, fontWeight: 600 }}>
              {referUserName ? <>
                  {referUserName}
                </> :
                onshoreReferenceName[getFieldValue('onshoreReferBy')] ? onshoreReferenceName[getFieldValue('onshoreReferBy')] : null}
            </div>
          </>
        )
      }
    ]

    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=' w-100 navbar-fix'>
            <div className='container'>
              <div className='d-flex flex-column flex-md-row align-items-center pt-5'>
                <h5 className='my-0 mr-md-auto font-weight-normal'>
                  <a onClick={() => dispatch(push('/home'))}>
                    <img src='dist/img/AdminLTELogo.png' alt='' />
                  </a>
                </h5>
                <nav className='my-2 my-md-0 d-flex align-items-center'>
                  <a
                    className='p-2 text-dark darkBtn'
                    onClick={() => dispatch(push('/login'))}>
                    <span>Login</span>
                  </a>
                  <RegisterButton />
                </nav>
              </div>
            </div>
          </header>
          <div className='login-form w-100'>
            <div className='container containerStu'>
              <div className={'inner-container'}>
                <div className='row'>
                  <div className='col-lg-6 mr-auto'>
                    {!isOtpScreen ? (
                      <div className='login-box w-100 studentRegForm'>
                        <h5>Student Registration</h5>
                        <Form onSubmit={this.submitForOtp}>
                          <div className={'row'}>
                            {inputFields.map((item, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {item.customField
                                    ? !item.hidden && (
                                    <Col
                                      className={`form-group col-lg-${
                                        item.span
                                      }`}>
                                      {item.customField}
                                    </Col>
                                  )
                                    : !item.hidden && (
                                    <div
                                      className={`form-group col-lg-${
                                        item.span
                                      }`}
                                      key={key}>
                                      <GetEachFormFields
                                        item={item}
                                        getFieldDecorator={
                                          getFieldDecorator
                                        }
                                        formItemLayout={formItemLayout}
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            })}
                          </div>
                          <div className={'row documents'}>


                            <div className={'col-lg-9'}>
                              <div className='align-items-center'>
                                <Button
                                  className='btn ml-auto btnSub'
                                  type='button'
                                  loading={loading}
                                  htmlType='submit'>
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    ) : null}

                    {isOtpScreen && (
                      <div className='login-box w-100 studentRegForm'>
                        <h5>
                          Email : {this.state.finalObjForAddStudent.email}
                        </h5>
                        {countryCode == 'IN_+91' ?
                          <h6 style={{ marginBottom: 10 }}>
                            Please enter your verification code sent on your phone
                            & email.
                          </h6> :
                          <h6 style={{ marginBottom: 10 }}>
                            Please enter your verification code sent on your email address.
                          </h6>}
                        <Form onSubmit={this.handleSubmit}>
                          <div>
                            <div className='form-group'>
                              <Form.Item>
                                {getFieldDecorator('otp', {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please input your 4 digit otp!'
                                    }
                                  ]
                                })(
                                  <Input
                                    type='password'
                                    minLength={4}
                                    maxLength={4}
                                    autoComplete='otp'
                                    placeholder={
                                      countryCode == 'IN_+91' ? 'OTP – Check your phone & email address' : 'OTP – Check your email address'
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>

                            <div className='d-flex align-items-center'>
                              <Button
                                className='btn'
                                type='button'
                                htmlType='submit'>
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedEducatorForm = Form.create()(EducatorForm)

const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEducatorForm)
