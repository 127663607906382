import React, {useState, useEffect} from 'react'
import {Form, Select, Button, Card, notification, Spin, Row, Col, Drawer} from '../../../components/Elements/appUtils'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import {listAllCountries} from '../../countries/actions/countries'
import {listQuickSearchUniversities} from '../../university/actions/university'
import {listAllCourse, loadCourseIntakesFxn} from '../../course/actions/courseAction'
import {checkBranchUserExists} from '../actions/student'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const ChooseCourse = (props) => {
    const dispatch = useDispatch()
    const {
        form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields},
        onClose,
        studentId,
        visible,
        addCourse,
        refreshApplication
    } = props
    let [allCountry, setAllCountry] = useState([])
    let [countryUniversity, setCountryUniversity] = useState([])
    let [universityCourse, setUniversityCourse] = useState([])
    let [selectedCourse, setSelectedCourse] = useState({})
    let [intakesList, setIntakesList] = useState([])
    let [intake, setIntake] = useState({})
    useEffect(() => {
        events.loadCountry()
    }, [])

    const events = {
        loadCountry: async () => {
            let filters = {
                sortField: 'countryName', sortOrder: 'ascend'
            }
            let {data} = await dispatch(listAllCountries(filters))
            setAllCountry(data)
        },
        loadUniversity: async (item) => {
            let obj = {
                results: 1000000,
                universityCountry: item,
                sortField: 'universityName',
                sortOrder: 'ascend'
            }
            let data = await dispatch(listQuickSearchUniversities(obj))
            setCountryUniversity(data)
        },
        loadCourse: async (item) => {
            let obj = {
                results: 1000000,
                courseUniversity: item,
                sortField: 'courseName',
                sortOrder: 'ascend'
            }
            let {data} = await dispatch(listAllCourse(obj))
            setUniversityCourse(data)
        },
        loadIntakes: async (courseId) => {
            let resp = await dispatch(loadCourseIntakesFxn({courseId}))
            let data = []
            _.each(resp, (item) => {
                if (item) {
                    item.value = `${item.intake}-${item.year}`
                    item.label = `${item.intake}, ${item.year}`
                    data.push(item)
                }
            })
            setIntakesList(data)
        }
    }
    let inputTypes = {
        fields: [
            {
                key: 'countryId',
                label: 'Country',
                required: true,
                type: 'select',
                showSearch: true,
                options: allCountry,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.countryName}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        countryId: x,
                        universityId: '',
                        courseId: '',
                        intakeTemp: ''
                    })
                    events.loadUniversity(x)
                    setIntake({})
                }
            },
            {
                key: 'universityId',
                label: 'University',
                required: true,
                type: 'select',
                showSearch: true,
                options: countryUniversity,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.universityName}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        universityId: x,
                        courseId: '',
                        intakeTemp: ''
                    })
                    events.loadCourse(x)
                    setIntake({})
                }
            },
            {
                key: 'courseId',
                label: 'Course',
                required: true,
                type: 'select',
                showSearch: true,
                options: universityCourse,
                keyAccessor: x => x._id,
                valueAccessor: x => `${x.courseName}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        courseId: x,
                        intakeTemp: ''
                    })
                    let selectedCourse = _.find(universityCourse, (item) => {
                        return item._id == x
                    })
                    setSelectedCourse(selectedCourse)
                    events.loadIntakes(x)
                    setIntake({})
                }
            },
            {
                key: 'intakeTemp',
                label: 'Intake',
                type: 'select',
                showSearch: true,
                options: intakesList,
                keyAccessor: x => x.value,
                valueAccessor: x => `${x.label}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        intakeTemp: x
                    })
                    if (x) {
                        let intakeVal = x.split('-')
                        let intake = {
                            month: intakeVal[0],
                            year: intakeVal[1]
                        }
                        setIntake(intake)

                    } else {
                        setIntake({})
                    }
                }
            }
        ]
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: {span: 24, offset: 0},
            sm: {span: 10, offset: 7},
            md: {span: 12, offset: 8}
        }
    }
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const handleSubmit = (e) => {
        const {form} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData = {...valData, ...selectedCourse}
                valData.courseUniversity = valData.universityId
                valData.country = _.find(allCountry, (item) => {
                    return item._id == valData.countryId
                })
                if (valData.country && valData.country.countryName) {
                    valData.country = valData.country.countryName
                }
                valData.university = _.find(countryUniversity, (item) => {
                    return item._id == valData.universityId
                })
                if (valData.university && valData.university.universityName) {
                    valData.applicationFee = valData.university.applicationFee
                    valData.university = valData.university.universityName
                }

                let {success, message} = await dispatch(checkBranchUserExists(valData))
                if (success) {
                    valData.intake = intake
                    addCourse(valData)
                    setIntake({})
                } else {
                    notification.warning({
                        message: message
                    })
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    return (
        <React.Fragment>
            <Drawer visible={visible}
                    title={`Add New Application`}
                    width={700}
                    closable={true}
                    maskClosable={true}
                    onClose={onClose}>
                <div className="row">
                    <div className="col-lg-12">
                        <Form className={'vertical-form'} autoComplete="off" onSubmit={handleSubmit}>
                            <div className="student-form-tab card">
                                <div className="form-box mt-4">
                                    <div className="card unizportal">
                                        <div className="inner-form">
                                            <Row gutter={24}>

                                                {inputTypes.fields.map((item, key) => {
                                                    return (
                                                        <Col span={item.span ? item.span : 24}
                                                             md={item.span ? item.span : 24} sm={24} xs={24}
                                                             key={key}
                                                             className={'mb10'}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                            {/* <GetAllFormFields
                        inputSchema={inputTypes}
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}/>*/}
                                            <Form.Item style={{marginTop: 25}}>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>

            </Drawer>
        </React.Fragment>
    )
}


const WrappedApplyForApplicationDrawer = Form.create()(ChooseCourse)
export default WrappedApplyForApplicationDrawer
