import React from 'react'
import {Icon} from '../../components/Elements/appUtils'
import {useDispatch} from 'react-redux'
import { push } from 'connected-react-router'

const RegisterButton = (props) => {
  let dispatch = useDispatch()
  let { customClass = '' } = props
  let openLink = (link) => {
    dispatch(push(link))
  }
  return (
    <div className={`dropdown`}>
      <button className={`dropbtn ${customClass}`}>Register <Icon type="down"/></button>
      <div className="dropdown-content">
        <a onClick={() => openLink('/agent-registration')} className={'regLink'}>Agent</a>
        <a onClick={() => openLink('/educators')} className={'regLink'}>Educator</a>
          <a onClick={() => openLink('/student-registration')} className={'regLink'}>Student</a>
          <a onClick={() => openLink('/institute-registration')} className={'regLink'}>Institute</a>
      </div>
    </div>
  )
}

export { RegisterButton }
