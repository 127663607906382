import React, {Component} from 'react'
import {Input, Button, Icon, Tooltip} from '../Elements/appUtils'
import {InputBox} from './appUtils'

class FileInput extends Component {

  render() {
    let { name, chooseDocument, file, label, className = '', multiple = false } = this.props
    return (
      <React.Fragment>
        <InputBox title={label} className={`rowFlex ${className}`}>
          <Input type={'file'}
                 name={name}
                 id={name}
                 className={`form-control`}
                 multiple={multiple}
                 onChange={(e) => {
                   chooseDocument(e)
                 }} />
          {file && file.url ?
            <Tooltip title={file.name}> <a className={'linkBtn'} target={'_blank'} href={file.url}>
              <Icon type={'eye'}></Icon>
            </a> </Tooltip> : ''}
        </InputBox>

      </React.Fragment>
    )
  }
}

export default FileInput

