import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
    agentTeamTypes,
    BloomsburyInstitute, bloomsburyShowToUser, BloomsburyUniversityObj, cambieForMarketing, CornerStoneCollege,
    countryIds, currentUdaan,
    departmentObj,
    oxfordUniversity, RoyalArtsCollege, udaanQueryObj, ukUniversityListForMarketing
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import _ from 'lodash'
import {ukBloomsburyRecordCountFxn, ukUniversityRecordsCountFxn} from '../../reporting/actions'
import {Icon, Tooltip} from '../../../components/Elements/appUtils'
import {getUserWithoutLoader} from '../../users/actions/user'
import OxfordUniversity from '../../evaluateProfile/oxford/oxfordUniversity'
import {push} from 'connected-react-router'
import {EligibilityIcon} from '../../university/drawers/universityEligibility'

let bloomStatusList = [
    'Waiting To Apply',
    'Conditional Offer Received',
    'CAS Applied',
    'CAS Issued',
    'Visa Approved'
]
const ButtonLink = (props) => {
    let {searchData, item, record, userCountryName, userData, applyForCourse, imgCls = '', openReport} = props
    let [recordObj, setRecordObj] = useState({})
    useEffect(() => {
        setCountFxn()
    }, [record.records])

    let setCountFxn = () => {
        let aa = {}
        if (record.records && record.records.length) {
            _.each(record.records, (item) => {
                aa[item.status] = item.total
            })
            setRecordObj(aa)
        }
    }

    const btnA = (
        <li className={'canadaDirectUniCount canadaDirectUniBox'} key={item.id}>
            <div className={'innerBox'}>
                <div className={'uniCountBox'} onClick={() => searchData(item)}>
                    <LazyLoadImage src={item.image} className={imgCls}/>
                    <h5 className={'jcic'}>{record.count || 0}</h5>
                </div>

                {userData.userType == 'admin' || (bloomsburyShowToUser.includes(userData.email) && item.id == BloomsburyInstitute) ? (
                    <div className={'ukSquareContainer'}>
                        {bloomStatusList.map((item) => {
                            return (
                                <>
                                    <Tooltip title={item}>
                                        <div
                                            onClick={() => openReport(item)}
                                            className={'squareStyle'}>
                                            {recordObj[item] ? recordObj[item] : 0}
                                        </div>
                                    </Tooltip>
                                </>
                            )
                        })}


                    </div>
                ) : null}


                <div className={'btnBox'}>
                    <a onClick={() => {
                        applyForCourse(item.id)
                    }}>
                        <Icon type={'plus-circle'} size={13}/> Add Student
                    </a>
                    <EligibilityIcon btn={'text-black'}
                                     universityId={BloomsburyInstitute}
                                     countryId={countryIds.uk}
                    />
                </div>
            </div>
        </li>
    )
    let branchUserCheck = () => {
        let checkUniversityExists = _.find(userData.universities, (item1) => {
            return item1.toString() == item.id
        })
        return userData.department == 'Visa Approved Department' ? btnA : checkUniversityExists ? btnA : null
    }
    return (
        userCountryName == 'UK' ? <>
            {(userData.userType == 'admin' || userData.userType == 'agent' || userData.userType == 'subAgent' || userData.department == departmentObj.marketing) ? btnA : null}
            {(userData.userType == 'branchManager' && userCountryName == 'UK') ? btnA : null}
            {userData.userType == 'branchUser' ? branchUserCheck() : null}
        </> : null
    )
}

const UkUniversityRecordCount = (props) => {
    let {user} = props
    let [countRecords, setCountRecords] = useState({})
    let [userData, setUserData] = useState({})
    let dispatch = useDispatch()

    let [applyCourseVisibleOxford, setApplyCourseVisibleOxford] = useState({
        visible: false,
        universityId: ''
    })


    useEffect(() => {
        loadDirectCount()
        loadUserCountry()
    }, [])


    const loadUserCountry = async () => {
        let resp = await dispatch(getUserWithoutLoader(user._id))
        setUserData(resp)
    }


    let loadDirectCount = async () => {
        // let resp = await dispatch(ukUniversityRecordsCountFxn())
        let resp = await ukBloomsburyRecordCountFxn()
        setCountRecords(resp)
    }

    let searchData = (item) => {
        if (item.id == oxfordUniversity) {
            dispatch(
                getUrlPushWrapper('application.oxfordApplicationsList', {
                    appUniversityId: oxfordUniversity
                }))
        } else if (item.id == BloomsburyInstitute) {
            dispatch(getUrlPushWrapper('bloomsburyInstituteApplications'))
        }
    }

    const events = {
        showOxfordApplicationDrawer: (universityId) => {
            setApplyCourseVisibleOxford({
                visible: true, universityId: universityId
            })
        },
        hideOxfordApplicationDrawer: () => {
            setApplyCourseVisibleOxford({
                visible: false, universityId: ''
            })
            loadDirectCount()
        },

        applyForCourse: (universityId) => {
            if (universityId == oxfordUniversity) {
                events.showOxfordApplicationDrawer(universityId)
            } else if (universityId == BloomsburyInstitute) {
                dispatch(push(`/add-student?country=${countryIds.uk}&university=${universityId}`))
            }
        },
        openReport: (item) => {
            dispatch(
                getUrlPushWrapper('bloomsburyInstituteApplications', {
                    status: item
                }))
        }
    }


    return (
        userData && userData._id ? <>
            <div className='card-pane-new listing-section w-100 direct-uni-box' style={{border: 'none'}}>
                <ul className={'count flex-wrap'}>
                    {/* {ukUniversityListForMarketing.map((item) => {
            return (
              <ButtonLink
                {...props}
                item={item}
                imgCls={'md'}
                userData={userData}
                count={countRecords[item.id] ? countRecords[item.id] : 0}
                searchData={searchData}
                applyForCourse={events.applyForCourse}
                dispatch={dispatch}
              />
            )
          })}*/}
                    {user && (user.userType == 'admin' || bloomsburyShowToUser.includes(user.email) || agentTeamTypes.includes(user.userType)) ?
                        <ButtonLink
                            {...props}
                            key={BloomsburyUniversityObj.id}
                            item={BloomsburyUniversityObj}
                            imgCls={'md'}
                            userData={userData}
                            record={countRecords[BloomsburyUniversityObj.id] ? countRecords[BloomsburyUniversityObj.id] : {}}
                            searchData={searchData}
                            applyForCourse={events.applyForCourse}
                            openReport={events.openReport}
                            dispatch={dispatch}
                        /> : null}


                </ul>
            </div>


            {applyCourseVisibleOxford.visible ? (
                <OxfordUniversity
                    visible={applyCourseVisibleOxford.visible}
                    selectedUniversity={applyCourseVisibleOxford.universityId}
                    onClose={events.hideOxfordApplicationDrawer}
                    {...props}
                />
            ) : null}

        </> : null
    )

}

export default UkUniversityRecordCount
