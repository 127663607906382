import React from 'react'
import {
    Form, Input, Button, notification, Select
} from '../../components/Elements/appUtils'

import {hideLoader, showPageLoad, hidePageLoad} from '../../modules/actions'

import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import _ from 'lodash'
import {addInstitute} from './action'
import {RegisterButton} from './registerButton'
import {listAllCountries} from '../countries/actions/countries'
import {LoadCity, LoadState} from '../../components/_utils/countryUtil'
import CountryJson from '../../assets/jsonFiles/countries.json'


const Option = Select.Option

class InstituteRegisterForm extends React.Component {

    events = {
        chooseState: (name) => {
            const {setFieldsValue} = this.props.form
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            setFieldsValue({
                cityName: undefined
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: '',
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {

            this.setState({
                cityName: name ? name : ''
            })
        },
        onlyStringAllow: (e) => {
            const {setFieldsValue} = this.props.form
            const re = /[0-9\b]/ig
            if (e.target.value) {
                e.target.value = e.target.value.replace(re, '')
                if (e.target.value) {
                    setFieldsValue({name: e.target.value})
                }
            }

        }
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isUser: false,
            dontAskOtp: false,
            last2Digit: '',
            email: '',
            allCountry: [],
            allCountries: this.LoadAllCountry(),
            allStates: LoadState('101'),
            allCities: [],
            cityName: '',
            stateName: '',
            countryName: 'India',
            referCode: '',
            countryISOCode: '+91',
            ids: localStorage.getItem('dontAskOtpForIds') ? JSON.parse(localStorage.getItem('dontAskOtpForIds')) : []
        }
        props.dispatch(hidePageLoad())
        props.dispatch(hideLoader())
        this.checkIsLoggedIn()
    }

    async loadCountry() {
        const {dispatch} = this.props
        let {data} = await dispatch(listAllCountries({results: 1000000}))
        this.setState({allCountry: data})
    }

    LoadAllCountry = () => {
        return CountryJson
    }

     componentDidMount() {

    }

    checkIsLoggedIn() {
        let resp = localStorage.getItem('token')
        if (resp) {
            let {dispatch} = this.props
            dispatch(push('/dashboard'))
        }
    }

    checkNumaric(val) {
        let {companyName, name} = val
        let reg = /^-?\d+\.?\d*$/
        if (companyName.match(reg)) {
            return {checkNum: true, inValid: 'Company Name'}
        }

        if (name.match(reg)) {
            return {checkNum: true, inValid: 'Contact Person Name'}
        }
        return {checkNum: false, inValid: ''}
    }


    handleSubmit = (e) => {
        const { dispatch, form } = this.props
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                let { checkNum, inValid } = this.checkNumaric(values)
                if (checkNum) {
                    notification.warning({message: `${inValid} should not contain Number`})
                } else {
                    dispatch(showPageLoad())
                    let x = await dispatch(addInstitute(values))
                    dispatch(hidePageLoad())
                    if (x && x.success) {
                        let message = 'Thank you for registering with us! Your request has been saved.Our representative will contact you within 24 to 48 hours.'
                        alert(message)
                        form.resetFields()
                    }
                }
                console.log("recived form data-", values)
            }
        })
    }


    render() {
        const {getFieldDecorator, getFieldValue, setFieldsValue} = this.props.form
        const {loading, dispatch} = this.props
        return (
            <React.Fragment>
                <div id={'loginForm'} className={'hold-transition login-main'}>
                    <header className=' w-100 navbar-fix'>

                        <div className='container'>
                            <div className='d-flex flex-column flex-md-row align-items-center pt-5'>
                                <h5 className='my-0 mr-md-auto font-weight-normal'>
                                    <a onClick={() => dispatch(push('/home'))}>
                                        <img src='dist/img/AdminLTELogo.png' alt=''/>
                                    </a>
                                </h5>
                                <nav className='my-2 my-md-0 d-flex align-items-center'>
                                    <a className='p-2 text-dark darkBtn' onClick={() => dispatch(push('/login'))}>
                                        <span>Login</span>
                                    </a>
                                    <RegisterButton/>
                                </nav>
                            </div>
                        </div>

                    </header>
                    <div className='login-form w-100'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 mr-auto'>
                                    <div className='login-box w-100 studentRegForm'>
                                        <h5>Institute Registration</h5>
                                        <Form onSubmit={this.handleSubmit}>
                                            <div className={'row'}>

                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('companyName', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter Company name, Company name must not contain any Digit!'
                                                            }]
                                                        })(
                                                            <Input placeholder='Company Name' type={'text'}/>
                                                        )}
                                                    </Form.Item>
                                                </div>

                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('name', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter contact person name, Name must not contain any Digit!'
                                                            }]
                                                        })(
                                                            <Input placeholder='Person name'
                                                                   type={'text'}
                                                                   onChange={(e) => {
                                                                       this.events.onlyStringAllow(e)
                                                                   }}/>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('stateName', {
                                                            rules: [{required: true, message: 'Please choose State!'}]
                                                        })(
                                                            <Select placeholder='State'
                                                                    onChange={(item) => this.events.chooseState(item)}
                                                                // filterOption={filterOption}
                                                                    allowClear={true}
                                                                    showSearch={true}
                                                                    value={this.state.stateName || undefined}
                                                            >
                                                                {this.state.allStates ? this.state.allStates.map((item, key) => {
                                                                    return (
                                                                        <Option value={item.name}
                                                                                key={key}>{item.name}</Option>
                                                                    )
                                                                }) : null}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </div>

                                                <div className='form-group col-lg-6'>
                                                        <Form.Item>
                                                            {getFieldDecorator('cityName', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please choose City!'
                                                                }]
                                                            })(
                                                                <Select placeholder='City'
                                                                        onChange={(item) => this.events.chooseCity(item)}
                                                                        allowClear={true}
                                                                        showSearch={true}
                                                                        value={this.state.cityName || undefined}>
                                                                  {this.state.allCities ? this.state.allCities.map((item, key) => {
                                                                    return (
                                                                      <Option value={item.name}
                                                                              key={key}>{item.name}</Option>
                                                                    )
                                                                  }) : null}
                                                                </Select>
                                                            )}
                                                        </Form.Item>

                                                </div>

                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('pincode', {
                                                            rules: [{required: true, message: 'Please enter Postal!'}]
                                                        })(
                                                            <Input placeholder='Postal Code' type={'text'}/>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('mobile', {
                                                            rules: [{required: true, message: 'Please enter phone no!'}]
                                                        })(
                                                            <Input placeholder='Phone no' type={'number'}/>
                                                        )}
                                                    </Form.Item>
                                                </div>

                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('email', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter email address!'
                                                            }]
                                                        })(
                                                            <Input placeholder='Email address' type={'email'}/>
                                                        )}
                                                    </Form.Item>
                                                </div>


                                                <div className='form-group col-lg-6'>
                                                    <Form.Item>
                                                        {getFieldDecorator('referBy', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter referral code!'
                                                            }]
                                                        })(
                                                          <Input placeholder='Referral code'
                                                                 type={'number'} />
                                                        )}
                                                    </Form.Item>
                                                </div>


                                                <div className='form-group col-lg-12'>
                                                    <Form.Item>
                                                        {getFieldDecorator('address', {
                                                            rules: [{required: true, message: 'Please enter address!'}]
                                                        })(
                                                            <Input placeholder='Address' type={'textarea'}/>
                                                        )}
                                                    </Form.Item>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <Button className='btn ml-auto' type='button' loading={loading}
                                                            htmlType='submit'>Submit</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const WrappedInstituteRegisterForm = Form.create()(InstituteRegisterForm)


const mapStateToProps = (
    {
        counter, global
    }) => ({
    count: counter.count,
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedInstituteRegisterForm)
