export default (
    state = {
        loader: false,
        selectedCourse: {},
        studentSelectedCourse: [],
        totalUniversities: 0,
        loadNoteRedux: false,
        loadActivityRedux: false,
        loadEmailRedux: false,
        loadSmsRedux: false,
        loadDocumentRedux: false,
        loadApplicationDocumentsRedux: false,
        loadFcmtDocumentsRedux: false,
        noteId: ''
    },
    action
) => {
    switch (action.type) {
        case 'SELECTED_COURSE_SHOW_LOADER':
            return {...state, loader: true}
        case 'SELECTED_COURSE_HIDE_LOADER':
            return {...state, loader: false}
        case 'LIST_SELECTED_COURSE':
            return {...state, studentSelectedCourse: action.payload.data}
        case 'ADD_SELECTED_COURSE':
            return {...state, selectedCourse: action.payload}
        case 'ADD_PENDENCY':
            return {...state, pendency: action.payload}
        case 'APPLICATION_NOTES':
            return {...state, notes: action.payload}

        case 'START_LOAD_NOTE':
            return {...state, loadNoteRedux: true}
        case 'END_LOAD_NOTE':
            return {...state, loadNoteRedux: false}
        case 'START_LOAD_ACTIVITY':
            return {...state, loadActivityRedux: true}
        case 'END_LOAD_ACTIVITY':
            return {...state, loadActivityRedux: false}
        case 'START_LOAD_EMAIL':
            return {...state, loadEmailRedux: true}
        case 'END_LOAD_EMAIL':
            return {...state, loadEmailRedux: false}
        case 'START_LOAD_SMS':
            return {...state, loadSmsRedux: true}
        case 'END_LOAD_SMS':
            return {...state, loadSmsRedux: false}

        case 'START_LOAD_DOCUMENT':
            return {...state, loadDocumentRedux: true}
        case 'END_LOAD_DOCUMENT':
            return {...state, loadDocumentRedux: false}
        case 'START_LOAD_APP_DOCUMENT':
            return {...state, loadApplicationDocumentsRedux: true}
        case 'END_LOAD_APP_DOCUMENT':
            return {...state, loadApplicationDocumentsRedux: false}
        case 'START_LOAD_FCMT_APP_DOCUMENT':
            return {...state, loadFcmtDocumentsRedux: true}
        case 'END_LOAD_FCMT_APP_DOCUMENT':
            return {...state, loadFcmtDocumentsRedux: false}

        case 'START_PUSH_NOTE_BY_REDUX':
            return {...state, noteId: action.noteId}
        case 'END_PUSH_NOTE_BY_REDUX':
            return {...state, noteId: ''}


        default:
            return state
    }
}
