import React from 'react'
import {Form, Button} from '../../components/Elements/appUtils'
import {showLoader, hideLoader, showPageLoad, hidePageLoad} from '../../modules/actions'
import { RegisterButton } from './registerButton'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { addEducator } from './action'
import { countryList } from './action'
import GetEachFormFields from '../../components/_utils/appFormUtils'

class EducatorForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      countryList: []
    }
    props.dispatch(hidePageLoad())
    props.dispatch(hideLoader())
    this.checkIsLoggedIn()
    this.loadCountry()
  }

  checkIsLoggedIn() {
    let resp = localStorage.getItem('token')
    if (resp) {
      let { dispatch } = this.props
      dispatch(push('/dashboard'))
    }
  }

  async loadCountry() {
    let { data } = await countryList()
    this.setState({ countryList: data })
  }

  handleSubmit = (e) => {
    const { dispatch, form } = this.props
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        dispatch(showPageLoad())
        let x = await dispatch(addEducator(values))
        dispatch(hidePageLoad())

        if (x && x.success) {
          let message = 'Thank you for registering with us! Your request has been saved.Our representative will contact you within 24 to 48 hours.'
          alert(message)
          form.resetFields()
        }
      }
    })
  }


  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form
    const { loading, dispatch } = this.props
    const { isUser, dontAskOtp, dontAskOtpForIds } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputFields = [
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        options: this.state.countryList,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          setFieldsValue({
            countryId: x
          })
        },
        span: 6
      },
      {
        key: 'name',
        label: 'Name of Institution', required: true, placeholder: 'Enter Name',
        span: 6
      },
      {
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        placeholder: 'Enter Email',
        span: 6
      },
      {
        key: 'mobile', label: 'Phone Number',
        required: true,
        placeholder: 'Enter Phone No',
        span: 6
      },
      {
        key: 'website', label: 'Website (if any)',
        placeholder: 'Enter Website',
        span: 12
      }

    ]

    return (
      <React.Fragment>
        <div id={'loginForm'} className={'hold-transition login-main'}>
          <header className=" w-100 navbar-fix">

            <div className="container">
              <div className="d-flex flex-column flex-md-row align-items-center pt-5">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                  <a onClick={() => dispatch(push('/home'))}>
                    <img src="dist/img/AdminLTELogo.png" alt=""/>
                  </a>
                </h5>
                <nav className="my-2 my-md-0 d-flex align-items-center">
                  <a className="p-2 text-dark darkBtn" onClick={() => dispatch(push('/login'))}>
                    <span>Login</span>
                  </a>
                  <RegisterButton/>
                </nav>
              </div>
            </div>

          </header>
          <div className="login-form w-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mr-auto">
                  <div className="login-box w-100 studentRegForm">
                    <h5>Educator Registration</h5>
                    <Form onSubmit={this.handleSubmit}>
                      <div className={'row'}>

                        {inputFields.map((item, key) => {
                          return (
                            <div className={`form-group col-lg-${item.span}`} key={key}>
                              <GetEachFormFields
                                item={item}
                                getFieldDecorator={getFieldDecorator}
                                formItemLayout={formItemLayout}/>
                            </div>
                          )
                        })}
                        <div className={'col-lg-12'}>
                          <div className="align-items-center">
                            <Button className="btn ml-auto btnSub" type="button" loading={loading}
                                    htmlType="submit">Submit</Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const WrappedEducatorForm = Form.create()(EducatorForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEducatorForm)
