import {Drawer, Card} from '../../../components/Elements/appUtils'
import React from 'react'
import {RowTable} from '../../../components/_utils/RowTable'
import {Link} from 'react-router-dom'

const SingleStudentEnquiryList = props => {
    let {visible, onClose, data} = props
    return (
        <Drawer
            visible={visible}
            width={'80%'}
            closable={true}
            maskClosable={true}
            title={'Details'}
            onClose={onClose}>
            <div className='card'>
                <div className={'row'}>
                    {data && data.resident_details ?
                        <div className={' col-md-6 '}>
                            <Card title={'Residential Details'} size={'small'}>
                                <div className={'tableBox striped'}>
                                    <RowTable title={'Name'}
                                              value={`${data.resident_details.first_name}, ${data.resident_details.last_name}`}/>
                                    <RowTable title={'Date Of Birth'} value={data.resident_details.date_of_birth}/>
                                    <RowTable title={'Contact Number'} value={data.resident_details.contact_number}/>
                                    <RowTable title={'Email'} value={data.resident_details.email}/>
                                    <RowTable title={'City'} value={`${data.resident_details.city}`}/>
                                </div>
                            </Card>
                        </div> : null}
                    <div className={' col-md-6 '}>
                        <Card title={'Property Details'} size={'small'}>
                            <div className={'tableBox striped'}>
                                <RowTable title={'Property Name'} value={data.property.name}/>
                                <RowTable
                                    title={'Address'}
                                    value={`${data.address.firstLine}, ${data.address.secondLine}, ${data.address.city}, ${data.address.country}`}/>
                                <RowTable
                                    title={'Booking Period'}
                                    value={`${data.booking_periods.start_date} - ${data.booking_periods.end_date}`}/>
                                <RowTable title={'Price'} value={data.price}/>
                                <RowTable
                                    title={'Room'}
                                    value={
                                        <a
                                            href={`/accommodation/${data.room_id}`}
                                            className='btn btn-link btn-xs'
                                            target='_blank'
                                            rel='noopener noreferrer'>
                                            View Room
                                        </a>
                                    }
                                />
                            </div>
                        </Card>
                    </div>
                </div>
                <div className={'row mt20'}>
                    {data && data.supporting_contact_details ?
                        <div className={' col-md-6 '}>
                            <Card title={'Emergency Contact'} size={'small'}>
                                <div className={'tableBox striped'}>
                                    <RowTable title={'Name'}
                                              value={`${data.supporting_contact_details.first_name}, ${data.supporting_contact_details.last_name}`}/>
                                    <RowTable title={'Contact Number'}
                                              value={data.supporting_contact_details.contact_number}/>
                                    <RowTable title={'Email'} value={data.supporting_contact_details.email}/>
                                </div>
                            </Card>
                        </div> : null}
                    {data && data.course_details ?

                        <div className={' col-md-6 '}>
                            <Card title={'Course Details'} size={'small'}>
                                <div className={'tableBox striped'}>
                                    <RowTable title={'Course Name'} value={data.course_details.course_title}/>
                                    <RowTable title={'University'} value={data.course_details.university}/>
                                    <RowTable title={'Year Of Study '} value={data.course_details.year_of_study}/>
                                </div>
                            </Card>
                        </div> : null}
                </div>
            </div>
        </Drawer>
    )
}

export default SingleStudentEnquiryList
