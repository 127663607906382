export default (
    state = {
        courseLoader: false,
        addedCourse: {},
        allCourses: [],
        totalCourses: 0,
        course: {},
        quickSearchCourses: []
    },
    action
) => {
    switch (action.type) {
        case 'COURSE_SHOWLOADER':
            return {...state, courseLoader: true}
        case 'COURSE_HIDELOADER':
            return {...state, courseLoader: false}
        case 'COURSE_CARD_SHOWLOADER':
            return {...state, courseCardLoader: true}
        case 'COURSE_CARD_HIDELOADER':
            return {...state, courseCardLoader: false}
        case 'LIST_COURSE':
            return {...state, course: action.payload.data}
        case 'ADD_COURSE':
            return {...state, addedCourse: action.payload}
        case 'LIST_ALL_COURSE':
            return {...state, allCourses: action.payload.data, totalCourses: action.payload.total}
        case 'LIST_QUICK_SEARCH_COURSE':
            return {...state, quickSearchCourses: action.payload}
        case 'LIST_QUICK_SEARCH_MORE_COURSE':
            return {...state, quickSearchLoadMoreCourses: action.payload}
        default:
            return state
    }
}
