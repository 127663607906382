import React, {useEffect, useState} from 'react'
import {
    Input,
    Drawer,
    Select,
    Card,
    notification,
    Button,
    DatePicker,
    Row,
    Col,
    Form
} from '../../../components/Elements/appUtils'
import {DirectUniversityCheck, fcmtUniversity, filterOption, InputBox} from '../../../components/_utils/appUtils'
import {useDispatch, useSelector} from 'react-redux'
import {searchedStudent} from '../../student/actions/student'
import debounce from 'lodash/debounce'
import {addSelectedCourse, addCourseInStudent} from '../actions/chooseStudent'
import AddStudentContent from '../../student/views/addStuContent'
import {showPageLoad, hidePageLoad} from '../../../modules/actions'
import {AllowComponentRightsWise} from '../../WebComponent/allowComponentRightsWise'
import {listCourseCampus} from '../../campus/actions/campus'
import {loadCourseIntakesFxn} from '../../course/actions/courseAction'
import _ from 'lodash'
import {RowTable} from '../../../components/_utils/RowTable'

const {Option} = Select
const initialState = {
    studentList: [],
    studentId: '',
    dateOfBirth: '',
    addStuDrawer: false
}
const ChooseStudent = (props) => {
    let {visible, closeStudentDrawer, selectedCourse, eligibile, showAddStudent = true} = props
    const [state, setState] = useState(initialState)
    const [intakesList, setIntakesList] = useState([])
    const [campusList, setCampusList] = useState([])
    const [intake, setIntake] = useState({})
    const [campusId, setCampusId] = useState('')
    const [intakeTemp, setIntakeTemp] = useState('')
    const dispatch = useDispatch()
    const dateInput = React.createRef()
    useEffect(() => {
        events.loadCampus()
        setDefaultValues()
    }, [selectedCourse])

    const setDefaultValues = () => {
        if (selectedCourse && selectedCourse.campusId) {
            setCampusId(selectedCourse.campusId)
        }
        if (selectedCourse && selectedCourse.month && selectedCourse.year) {
            setIntakeTemp(`${selectedCourse.month}-${selectedCourse.year}`)
            let intake = {
                month: selectedCourse.month,
                year: selectedCourse.year
            }
            setIntake(intake)
        }
    }


    const fetchStudent = async (value) => {
        let studentList = await searchedStudent({name: value})
        setState({
            ...state,
            studentList
        })
    }


    useEffect(() => {
        events.loadIntakes(campusId)
    }, [campusId])


    const chooseStudentFxn = (value) => {
        setState({
            ...state,
            studentId: value
        })
    }


    const chooseDate = (value, stringDate) => {
        setState({
            ...state,
            dateOfBirth: value
        })
    }

    const events = {
        loadCampus: async () => {
            let {courseUniversity, courseId} = selectedCourse
            let isDirectUniversity = DirectUniversityCheck(courseUniversity)
            if (courseId) {
                let obj = {
                    results: 1000000,
                    courseId
                }
                let {data} = await dispatch(listCourseCampus(obj))
                setCampusList(data)
                if (isDirectUniversity && data && data.length) {
                    setCampusId(data[0]._id)
                }
            } else {
                setCampusId('')
                setCampusList([])
            }
        },
        loadIntakes: async (campusId) => {
            let {courseUniversity, courseId} = selectedCourse
            let isDirectUniversity = DirectUniversityCheck(courseUniversity)
            if (courseId) {
                let resp = await dispatch(loadCourseIntakesFxn({courseId, campusId}))
                let data = []
                _.each(resp, (item) => {
                    if (item) {
                        item.value = `${item.intake}-${item.year}`
                        item.label = `${item.intake}, ${item.year}`
                        data.push(item)
                    }
                })
                setIntakesList(data)
                if (isDirectUniversity && resp && resp.length) {
                    setIntakeTemp(data[0].value)
                    let intake = {
                        month: resp[0].intake,
                        year: resp[0].year
                    }
                    setIntake(intake)
                }
            } else {
                setIntakesList([])
                setIntake({})
                setIntakeTemp('')

            }
        },
        chooseIntake: (value) => {
            setIntakeTemp(value)
            let intakeVal = value.split('-')
            let intake = {
                month: intakeVal[0],
                year: intakeVal[1]
            }
            setIntake(value)
        }
    }

    const submitSelectedStudent = async (studentId, showNotification = true) => {
        let {courseUniversity} = selectedCourse
        let isDirectUniversity = DirectUniversityCheck(courseUniversity)
        if (!studentId) {
            return notification.warn({message: 'Choose student'})
        }
        let obj = {
            ...selectedCourse, studentId, showNotification, eligibile
        }
        if (isDirectUniversity) {
            if (!campusId) {
                return notification.warn({message: 'Choose Campus'})
            }
            if (!intake || (intake && !intake.month)) {
                return notification.warn({message: 'Choose Intake'})
            }
            obj.campusId = campusId
            obj.intake = intake
        } else {
            if (intake && intake.month) {
                obj.intake = intake
            }
            if (campusId) {
                obj.campusId = campusId
            }
        }

        dispatch(showPageLoad())
        let data = await dispatch(addCourseInStudent({...obj}))
        dispatch(hidePageLoad())
        if (data && !data.error) {
            closeStudentDrawer()
        }
    }

    const addNewStudentDrawerOpen = () => {
        setState({...state, addStuDrawer: true})
    }

    const addNewStudentDrawerClose = () => {
        setState({...state, addStuDrawer: false})
    }

    const studentCallback = (studentData) => {
        if (studentData && studentData._id) {
            addNewStudentDrawerClose()
        }
    }


    return (
        <React.Fragment>
            <Drawer visible={visible}
                // title={'Choose Student'}
                    width={window.innerWidth > 1200 ? 800 : '60%'}
                    closable={false}
                    onClose={closeStudentDrawer}>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='form-box'>
                            <div className='heading-form stuHeader  d-flex align-items-center mt-2 mb-3'
                                 id={'generalInformation'}>
                                <h5 className={'addStuInfo'}>
                                    <Row gutter={16} className={'row1 rowWrap'}>
                                        <Col span={12}>
                                            Select Student
                                        </Col>
                                        <Col span={12} className={'alignRight'}>
                                            {showAddStudent ? <div>
                                                <Button onClick={addNewStudentDrawerOpen} icon={'plus'}> Add New
                                                    Student</Button>
                                            </div> : null}
                                        </Col>
                                    </Row>
                                </h5>
                            </div>
                            <div className='card unizportal'>
                                <div className={'infoUni tableBox striped'}>
                                    <RowTable title={'Country'} value={<span>{selectedCourse.countryName}</span>}/>
                                    <RowTable title={'University'}
                                              value={<span>{selectedCourse.universityName}</span>}/>
                                    <RowTable title={'Course'} value={<span>{selectedCourse.courseName}</span>}/>
                                </div>
                                <br/>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <InputBox title={'Select Student'}>
                                            <Select
                                                showSearch={true}
                                                onSearch={debounce(fetchStudent, 500)}
                                                filterOption={false}
                                                autoClearSearchValue={true}
                                                allowClear={true}
                                                style={{width: '100%'}}
                                                showArrow={true}
                                                onChange={chooseStudentFxn}
                                                optionLabelProp='label'
                                                placeholder='Choose Student'>
                                                {state.studentList && state.studentList.length && state.studentList.map((item, index) => {
                                                    return (
                                                        <Option key={item._id} value={item._id} label={item.name}>
                                                            <div>
                                                                <div>
                                                                    {item.studentId} : {item.name}
                                                                </div>
                                                                <div>
                                                                    {item.email}
                                                                </div>
                                                            </div>
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </InputBox>
                                    </Col>
                                    <Col span={12}>
                                        <InputBox title={'Select Campus'}>
                                            <Select name='campusId' filterOption={filterOption}
                                                    className={'from-control'}
                                                    value={campusId}
                                                    id='campusId' onChange={(e) => {
                                                setCampusId(e)
                                            }}>
                                                {campusList && campusList.length ? campusList.map((item, key) => {
                                                    return (
                                                        <Option key={key} value={item._id}>{item.campusName}</Option>
                                                    )
                                                }) : null}
                                            </Select>
                                        </InputBox>
                                    </Col>
                                    <Col span={12}>
                                        <InputBox title={'Intake'}>
                                            <Select name='intake' id='intake'
                                                    className={'from-control'}
                                                    value={intakeTemp}
                                                    onChange={(e) => {
                                                        events.chooseIntake(e)
                                                    }}>
                                                {intakesList && intakesList.length ? intakesList.map((item, key) => {
                                                    return (
                                                        <Option key={key} value={item.value}>{item.label}</Option>
                                                    )
                                                }) : null}
                                            </Select>
                                        </InputBox>
                                    </Col>
                                    <Col span={24}>
                                        <Button onClick={() => submitSelectedStudent(state.studentId)}
                                                type={'success'}>Submit</Button>
                                    </Col>
                                </Row>


                            </div>
                        </div>
                    </div>
                </div>

                <Drawer visible={state.addStuDrawer}
                        title={'Apply for New Student'}
                        width={window.innerWidth > 1800 ? 1500 : '60%'}
                        closable={false}
                        onClose={addNewStudentDrawerClose}>
                    <AddStudentContent callBackFxn={studentCallback} pageType={'drawer'}
                                       selectedCourse={selectedCourse}/>
                </Drawer>
            </Drawer>
        </React.Fragment>
    )
}
export default ChooseStudent
