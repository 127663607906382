import React, {useEffect, useState} from 'react'
import {notification, Form, Button} from '../../components/Elements/appUtils'
import {Opportunity} from './opportunityComponent'
import {contactUsFxn} from '../users/actions/user'
import {useDispatch} from 'react-redux'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {countryList} from '../login/action'

const fields = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    country: '',
    message: ''
}
const ContactUs = (props) => {
    const dispatch = useDispatch()
    let [countries, setCountries] = useState([])

    let [state, setState] = useState(fields)
    const setData = (e) => {
        // console.log(e)
        let {value, name} = e.target
        // console.log(value, name)
        setState({...state, [name]: value})
    }
    useEffect(() => {
        loadCountry()
    }, [])
    const loadCountry = async () => {
        let {data} = await countryList()
        setCountries(data)
    }

    const sendMessage = async () => {
        let {firstName, lastName, email, mobile, country, message} = state
        if (!firstName) {
            notification.warning({message: 'Enter first name.'})
            return
        }
        if (!lastName) {
            notification.warning({message: 'Enter last name.'})
            return
        }
        if (!email) {
            notification.warning({message: 'Enter email address.'})
            return
        }
        if (!mobile) {
            notification.warning({message: 'Enter phone no.'})
            return
        }
        if (!country) {
            notification.warning({message: 'Choose country'})
            return
        }
        if (!message) {
            notification.warning({message: 'Enter message.'})
            return
        }
        let resp = await dispatch(contactUsFxn(state))
        alert('Query submitted successfully.')
        setState({...fields})
    }

    return (
        <React.Fragment>
            <section className="banner blog-banner contact-banner">

                <div className="container">
                    <div className="col-lg-12 mr-auto">
                        <div className="content text-left">
                            <LazyLoadImage src="/assets/image/contact-banner.png" alt="" className="w-100"/>
                        </div>
                    </div>
                </div>

            </section>

            <section className="contact-form" style={{position: "relative"}}>
                <div className="container">

                    <div className="row">

                        <div className="col-lg-7">
                            <div className="box">

                                <Form>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputEmail4">First Name</label>
                                            <input type="text" className="form-control"
                                                   name={'firstName'} value={state.firstName}
                                                   onChange={(e) => setData(e)}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputPassword4">last name</label>
                                            <input type="text" className="form-control"
                                                   name={'lastName'} value={state.lastName} onChange={setData}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputAddress">email Address</label>
                                        <input type="text" className="form-control"
                                               name={'email'} value={state.email} onChange={setData}/>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputCity">phone number</label>
                                            <input type="number" className="form-control"
                                                   name={'mobile'} value={state.mobile} onChange={setData}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="inputState">Country</label>
                                            <select id="inputState" className="form-control"
                                                    name={'country'} value={state.country} onChange={setData}>
                                                <option value={''}>Choose...</option>
                                                {/*<option value="United Kingdom">United Kingdom</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Canada">Canada</option>*/}
                                                {countries.map((item, key) => {
                                                    return (
                                                        <option value={item.countryName}
                                                                key={key}>{item.countryName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label htmlFor="message">your message</label>
                                            <input type="text" className="form-control"
                                                   name={'message'} value={state.message} onChange={setData}/>
                                        </div>
                                    </div>
                                    <div className={'alignRight'}>
                                        <button type="submit" className="btn btn-primary"
                                                onClick={() => sendMessage()}>Send message
                                        </button>
                                    </div>
                                </Form>

                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="content">
                                <h3>For the users, <br/> You can download the app here:</h3>
                                <div className="store">
                                    <a href="https://play.google.com/store/apps/details?id=com.unizportal"><LazyLoadImage
                                        src="/assets/image/google-play.png" alt=""/></a>
                                    <a href="https://apps.apple.com/in/app/unizportal/id1569018281"><LazyLoadImage
                                        src="/assets/image/app-store.png" alt=""/></a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="info gap-62">
                        <div className="row mb100">

                            <div className="col-lg-8">
                                <div className="content">
                                    <h3>Contact information</h3>
                                    <p>You can fill the above form and our team <br/> will get back to you within 24
                                        hours.</p>

                                    <ul>
                                        <li style={{margin: 0, padding: 0}}>
                                            <h5>Corporate Office</h5>
                                        </li>


                                        <li><LazyLoadImage src="/assets/image/location.png" alt=""/>
                                            Premises No : 32586-001, IFZA Business Park, DDP,Building A1 , Dubai Silicon
                                            Oasis - Dubai - United Arab Emirates
                                        </li>

                                    </ul>
                                    <ul>
                                        <li style={{margin: 0, padding: 0}}>
                                            <h5>Head Office</h5>
                                        </li>
                                        <li><LazyLoadImage src="/assets/image/location.png" alt=""/>
                                            3080 Yonge St. Suite 6060, Toronto, ON, M4N 3N1
                                        </li>
                                        {/*<li><LazyLoadImage src="/assets/image/phone.png" alt=""/> +91 7408.874.088</li>*/}
                                        <li><LazyLoadImage src="/assets/image/phone.png" alt=""/> +1 647 255 8857</li>
                                        <li><LazyLoadImage src="/assets/image/message.png" alt=""/> info@unizportal.com
                                        </li>
                                    </ul>
                                    <ul>
                                        <li style={{margin: 0, padding: 0}}>
                                            <h5>Branch Office</h5>
                                        </li>
                                        <li className={'addInfo'}>
                                            <div className={'box'}>
                                                <LazyLoadImage src="/assets/image/location.png" alt=""/>
                                                <span>
                                                   Office no - 812, 8th floor, Hemkunt Chambers, Nehru Place, New Delhi, 110048
                                                </span>
                                            </div>

                                        </li>
                                        <li className={'addInfo'}>
                                            <div className={'box'}>
                                                <LazyLoadImage src="/assets/image/location.png" alt=""/>
                                                <span>
                                                    Office no. SCO 388, 2nd floor mugal canal karnal, Haryana, 132001
                                                </span>
                                            </div>
                                            {/*  <div className={'box'}>
                                                <LazyLoadImage src="/assets/image/phone.png" alt=""/>
                                                <span> +91 90684 44444</span>
                                            </div>*/}
                                        </li>
                                        <li className={'addInfo'}>
                                            <div className={'box'}>
                                                <LazyLoadImage src="/assets/image/location.png" alt=""/>
                                                <span>
                                                     Office No. : B-308, 3rd floor Bestech Business Tower, sector 66, sas nagar,
                                            punjab, 160062
                                                </span>
                                            </div>
                                            {/* <div className={'box'}>
                                                <LazyLoadImage src="/assets/image/phone.png" alt=""/>
                                                <span>  +91 95608 47414</span>
                                            </div>*/}
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="social">
                                    <h5>Socially Connect with us</h5>
                                    <ul className="d-flex align-items-center">
                                        <li><a href="https://www.linkedin.com/company/unizportal"><LazyLoadImage
                                            src="/assets/image/linkedin.png" alt=""/></a></li>
                                        <li><a href="https://www.facebook.com/unizportal">
                                            <LazyLoadImage src="/assets/image/facebook.png"
                                                           alt=""/></a></li>
                                        <li><a href="https://www.instagram.com/unizportalofficial/"><LazyLoadImage
                                            src="/assets/image/instagram.png" alt=""/></a></li>
                                        <li><a
                                            href="https://www.youtube.com/channel/UCfBEmsjpc1ZkHjPBgrrhJlA"><LazyLoadImage
                                            src="/assets/image/youtube.png" alt=""/></a></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <div className={'hrFooter'} style={{marginTop: '200px'}}>
                            <LazyLoadImage src="/assets/image/info-pattern.png" alt="" className="pattern"/>
                            <hr/>
                            <hr/>
                        </div>
                    </div>


                </div>
            </section>


            <Opportunity/>
        </React.Fragment>
    )
}
export default ContactUs
