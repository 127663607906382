import React, {useState, useEffect} from 'react'
import {notification, Form, Button, Select} from '../../components/Elements/appUtils'
import {Opportunity} from './opportunityComponent'
import {
    BroadcastingFxn,
    checkBroadcastingStatusFxn,
    CheckEnquiryFeedbackFxn,
    EnquiryFeedbackFxn
} from '../users/actions/user'
import {useDispatch} from 'react-redux'

import {push} from 'connected-react-router'

const sourceList = [
    'Newspaper', 'Social Media', 'Website', 'Digital Advertising', 'Email'
]
const fields = {
    name: '',
    country: '',
    university: '',
    shareYourExperience: '',
    rating: null
}
const countryList = [
    'Canada',
    'United States',
    'Australia',
    'United Kingdom'
]
const ratingList = [
    {name: '1 Star', value: 1},
    {name: '2 Star', value: 2},
    {name: '3 Star', value: 3},
    {name: '4 Star', value: 4},
    {name: '5 Star', value: 5}
]
const {Option} = Select
const ContactUs = (props) => {
    const dispatch = useDispatch()
    let [state, setState] = useState(fields)
    let [showIcon, setShowIcon] = useState(false)
    let [message, setMessage] = useState('')

    useEffect(() => {
        checkFeedbackExists()
    }, [])

    const checkFeedbackExists = async () => {
        let {pathname} = window.location
        let token = pathname.split('/').pop()
        let {success, message, data} = await dispatch(checkBroadcastingStatusFxn({token}))
        if (success) {
            setMessage('')
            submitBroadcast(data)
        } else {
            setShowIcon(true)
            setMessage(message)
        }
    }
    const submitBroadcast = async (data) => {
        let {success, message} = await dispatch(BroadcastingFxn({_id: data._id}))
        setShowIcon(true)
        setMessage(message)
    }

    return (
        <React.Fragment>
            <section className="banner blog-banner contact-banner banner11">

                <div className="container">
                    <div className="mr-auto">

                    </div>
                </div>

            </section>

            <section className="contact-form">
                <div className="container">

                    <div className="row">

                        <div className="col-lg-12">
                            <div className="box">
                                <div className={'broadcast_info'}>
                                    {showIcon ? <img src={'../assets/icons/checked.png'}/> : null}
                                    <br/>
                                    {message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </React.Fragment>
    )
}
export default ContactUs
