import React, {Suspense} from 'react'
import {Helmet} from 'react-helmet'
import Header from '../frontLayout/header'
import Footer from '../frontLayout/footer'
import {Navigate, Outlet} from "react-router-dom";
// import "../app.less"
const Home = (props) => {
    let {children} = props
    return (
        <React.Fragment>
            <Helmet>

                <link rel="stylesheet"
                      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
                <link rel="stylesheet" href="../assets/css/responsive.css"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap"
                      rel="stylesheet"/>
            </Helmet>
            <Header/>
            <Suspense fallback={<div></div>}>
                <Outlet {...props}/>
            </Suspense>
            <Footer/>
        </React.Fragment>
    )
}

export default Home
