import React, {useState, useEffect} from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    notification,
    Row,
    Card,
    Table,
    Icon,
    Tooltip,
    Popconfirm
} from '../../../components/Elements/appUtils'
import {connect, useDispatch} from 'react-redux'
import {
    addStudentCommissionFxn, deleteCommissionFxn,
    getUniversityAndCourseFxn, universityCommissionListFxn,
    updateUniversityCommissionFxn
} from '../actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
    CommissionIntakeYear,
    CustomInput,
    displayDate,
    InvoiceMonthlyCycle,
    Intakes
} from '../../../components/_utils/appUtils'
import moment from 'moment'
import lodash from 'lodash'
import {CustomRowTable} from '../../../components/_utils/RowTable'
import {loadCourseIntakesFxn} from '../../course/actions/courseAction'
import {
    getSingleSelectedCourse
} from '../../applications/actions/chooseStudent'
import InputBox from '../../../components/_utils/InputBox'
import EditCommissionDetails from './editCommissionDetails'
import * as events from 'events'
import AddStudentInfoComponent from '../Components/addStudentInfoComponent'
import StatusComponent from '../Components/statusComponent'

class AddCommissionDrawer extends React.Component {
    intakeEvents = {
        loadIntakes: async () => {
            let {studentData, dispatch} = this.props
            let {applications} = studentData
            let resp = await dispatch(loadCourseIntakesFxn({courseId: applications.courseId._id}))
            let intakeList = []
            lodash.each(resp, (item) => {
                if (item.intake) {
                    intakeList.push({
                        value: `${item.intake}-${item.year}`,
                        name: `${item.intake}, ${item.year}`
                    })
                }
            })
            this.setState({
                intakeList
            })
        }

    }

    constructor(props) {
        super(props)
        this.state = {
            universityDoc: {},
            courseDoc: {},
            currencyValue: '',
            form1disabled: false,
            currencyArr: [],
            intakeList: [],
            studentObj: {},
            semesterFee: 0,
            studentCommissionList: [],
            semesterIndex: 1,
            lastInvoiceDate: '',
            visibleEditDrawer: false,
            intakeYearList: []
        }
        this.tableAppRef = React.createRef()
    }

    componentDidMount() {
        // this.loadStudentData()
        this.getUniversityInfo()
        // this.intakeEvents.loadIntakes()
        this.loadIntakeYears()
    }

    loadIntakeYears = () => {
        let intakeYearList = CommissionIntakeYear()
        this.setState({intakeYearList})
    }

    loadStudentData = async () => {
        let {studentData, dispatch} = this.props
        let {applications} = studentData
        let obj = {
            studentId: studentData._id,
            appId: applications._id
        }
        let studentObj = await dispatch(getSingleSelectedCourse(obj))
        this.setState({
            studentObj
        }, () => {
            this.apiRequest()
        })
    }

    getUniversityInfo = async () => {
        let {studentData, dispatch} = this.props
        let {applications} = studentData
        let obj = {
            universityId: applications.courseUniversity._id,
            courseId: applications.courseId._id
        }
        let resp = await dispatch(getUniversityAndCourseFxn(obj))

        let stuObj = {
            studentId: studentData._id,
            appId: applications._id
        }
        let studentObj = await dispatch(getSingleSelectedCourse(stuObj))
        let {success} = resp
        if (success) {
            this.setDefaultValue(resp, studentObj)
        }
    }

    setCurrency = (universityCountry) => {
        let {form: {setFieldsValue}} = this.props
        // let currencyArr = ['INR']
        let currencyArr = []
        if (universityCountry && universityCountry.currencyName) {
            currencyArr.push(universityCountry.currencyName)
            setFieldsValue({commissionCurrency: universityCountry.currencyName})

        }
        this.setState({currencyArr})
    }

    setDefaultValue = (body, studentObj) => {
        let {university, course} = body
        let {studentData, form: {setFieldsValue}} = this.props
        let {applications} = studentObj
        let objB = {
            courseDoc: course,
            universityDoc: university,
            currency: university.universityCountry
        }
        let amount = university.universityCommission
        if (university.universityCommissionType == 'Percentage') {
            amount = (parseFloat(course.tuitionFee) * parseFloat(university.universityCommission)) / 100
        }
        if (amount) {
            amount = parseFloat(amount.toFixed(2))
        }

        let intakeValue = ''
        let obj = {
            courseStartDate: course.courseStartDate ? moment(course.courseStartDate) : undefined,
            commissionCurrency: university.commissionCurrency ? university.commissionCurrency : undefined,
            tuitionFee: course.tuitionFee ? course.tuitionFee : undefined
        }
        if (applications && applications.commissionDetails && applications.commissionDetails.courseStartDate) {
            let {commissionDetails} = applications
            obj = {
                ...obj,
                courseStartDate: commissionDetails.courseStartDate ? moment(commissionDetails.courseStartDate) : undefined,
                commissionCurrency: commissionDetails.commissionCurrency ? commissionDetails.commissionCurrency : undefined,
                offerLetterStudentId: commissionDetails.offerLetterStudentId,
                invoiceMonthlyCycle: 1,
                invoiceDate: moment(commissionDetails.courseStartDate).add(1, 'month'),
                semester: 'Semester 1'
            }
            if (commissionDetails.amount) {
                amount = commissionDetails.amount
            }
            objB.form1disabled = true
            objB.semesterFee = commissionDetails.tuitionFee ? commissionDetails.tuitionFee : undefined
            objB.lastInvoiceDate = moment(commissionDetails.courseStartDate)
        } else {
            let {intake} = applications
            if (intake && intake.month) {
                intakeValue = `${intake.month}-${intake.year}`
            }
            obj.intake = intakeValue
        }
        objB.amount = amount

        objB.currencyValue = obj.commissionCurrency
        this.setState({
            ...objB,
            studentObj
        }, () => {
            this.apiRequest()
        })
        setTimeout(() => {
            setFieldsValue({...obj})
        }, 300)
        setTimeout(() => {
            this.setCurrency(university.universityCountry)
        }, 500)
    }

    handleSubmitCommissionDetails = (e) => {
        const {form, studentData, dispatch} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {intakeMonth, intakeYear} = valData
                valData.studentId = studentData._id
                valData.applicationId = studentData.applications._id
                // let intakeVal = valData.intake.split('-')
                let intake = {
                    month: intakeMonth,
                    year: intakeYear
                }
                valData.intake = intake
                let resp = await dispatch(updateUniversityCommissionFxn(valData))
                if (resp && resp.success) {
                    this.loadStudentData()
                    this.getUniversityInfo()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    handleAddCommission = (e) => {
        const {form, studentData, dispatch} = this.props
        let {studentObj, semesterFee, semesterIndex, universityDoc, amount} = this.state
        let {applications} = studentObj
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.studentId = studentObj._id
                valData.name = studentObj.name
                valData.applicationId = applications._id
                valData.appId = applications.id
                valData.intake = applications.intake
                valData.commissionDetails = applications.commissionDetails
                valData.currency = applications.commissionDetails.commissionCurrency
                // valData.offerLetterStudentId = applications.commissionDetails.offerLetterStudentId;
                valData.universityId = applications.courseUniversity
                valData.countryId = applications.universityCountry
                valData.courseId = applications.courseId
                valData.courseName = applications.courseName
                valData.agentId = applications.agentId
                valData.semesterFee = semesterFee
                valData.amount = amount
                valData.semesterIndex = semesterIndex
                valData.universityCommission = universityDoc.universityCommission
                valData.noOfUniversityCommission = universityDoc.noOfUniversityCommission
                valData.universityCommissionType = universityDoc.universityCommissionType
                let resp = await dispatch(addStudentCommissionFxn(valData))
                if (resp) {
                    this.apiRequest()
                    form.resetFields()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        })
    }

    apiRequest = async () => {
        let params = {
            results: 100,
            count: 100
        }
        let {dispatch, form: {setFieldsValue}, studentData} = this.props
        let {studentObj, universityDoc} = this.state
        let obj = {
            studentId: studentData._id,
            applicationId: studentData.applications._id
        }
        params = {...params, ...obj}
        let stateObj = {}
        let resp = await dispatch(universityCommissionListFxn({...params}))
        let {data, total} = resp
        let newObj = {
            semester: `Semester ${data.length + 1}`,
            semesterIndex: data.length + 1
        }
        if (data && data.length) {
            let lastObj = data[data.length - 1]
            newObj.invoiceMonthlyCycle = 6
            newObj.invoiceDate = moment(lastObj.invoiceDate).add(6, 'month')
            let lastInvoiceDate = moment(lastObj.invoiceDate)
            stateObj.lastInvoiceDate = lastInvoiceDate
        } else {
            let {courseDetails} = studentData.applications
            newObj.invoiceMonthlyCycle = 1
            let date = moment()
            if (courseDetails && courseDetails.courseStartDate) {
                date = courseDetails.courseStartDate
            }
            newObj.invoiceDate = moment(date).add(1, 'month')
            let lastInvoiceDate = moment(date)
            stateObj.lastInvoiceDate = lastInvoiceDate
        }
        if (studentObj && studentObj.applications && studentObj.applications.tuitionFee) {
            let semesterFee = studentObj.applications.tuitionFee ? studentObj.applications.tuitionFee : undefined
            let amount = universityDoc.universityCommission
            if (universityDoc.universityCommissionType == 'Percentage') {
                amount = (parseFloat(semesterFee) * parseFloat(universityDoc.universityCommission)) / 100
                stateObj.semesterFee = semesterFee
            }
            if (amount) {
                newObj.amount = parseFloat(amount.toFixed(2))
            }
        }
        this.setState({
            ...stateObj,
            studentCommissionList: resp.data,
            semesterIndex: newObj.semesterIndex,
            amount: newObj.amount
        })
        setTimeout(() => {
            setFieldsValue({...newObj})
        }, 300)
    }

    events = {
        reCalculateCommission: (semesterFee) => {
            let {form: {setFieldsValue}} = this.props
            let {universityDoc} = this.state
            let {universityCommissionType, universityCommission} = universityDoc
            let amount = universityCommission
            if (universityCommissionType == 'Percentage') {
                amount = (parseFloat(semesterFee) * parseFloat(universityCommission)) / 100
                if (amount) {
                    amount = parseFloat(amount.toFixed(2))
                }
                setFieldsValue({amount})
            } else {
                setFieldsValue({amount})
            }
        },
        reloadTable: () => {
            if (this.tableAppRef && this.tableAppRef.current) {
                this.tableAppRef.current.reload()
            }
        },
        chooseInvoiceMonthlyCycle: (value) => {
            let {form: {setFieldsValue}} = this.props
            let {lastInvoiceDate} = this.state
            setFieldsValue({
                invoiceDate: moment(lastInvoiceDate).add(value, 'month')
            })
        },
        showEditDrawer: () => {
            this.setState({visibleEditDrawer: true})
        },
        hideEditDrawer: () => {
            this.setState({visibleEditDrawer: false})
        },
        submitEditDrawer: () => {
            this.setState({visibleEditDrawer: false}, () => {
                this.loadStudentData()
                this.getUniversityInfo()
                this.intakeEvents.loadIntakes()
                this.apiRequest()
            })
        }
    }

    deleteCommission = async (commissionId) => {
        let {dispatch} = this.props
        let {success} = await dispatch(deleteCommissionFxn({commissionId}))
        if (success) {
            this.apiRequest()
        }
    }

    render() {
        let {onClose, visible, form: {getFieldDecorator, setFieldsValue}} = this.props
        let {
            form1disabled,
            currencyArr,
            universityDoc,
            courseDoc,
            intakeList,
            currencyValue,
            studentObj,
            studentCommissionList,
            visibleEditDrawer
        } = this.state
        let {applications} = studentObj
        let inputTypes = {
            fields: [
                {
                    key: 'courseStartDate',
                    label: 'Course Start Date',
                    placeholder: 'Course Start Date',
                    type: 'date',
                    required: true,
                    disabled: form1disabled
                },
                {
                    required: true,
                    type: 'select',
                    options: currencyArr,
                    name: 'Commission Currency',
                    label: 'Commission Currency',
                    placeholder: 'Commission Currency',
                    key: 'commissionCurrency',
                    // disabled: form1disabled,
                    disabled: true,
                    onChange: (e) => {
                        setFieldsValue({commissionCurrency: e})
                    }
                },
                {
                    key: 'tuitionFee',
                    label: 'Tuition Fees',
                    placeholder: 'Tuition Fees',
                    type: 'number',
                    required: true,
                    disabled: form1disabled
                },
                {
                    key: 'offerLetterStudentId',
                    label: 'Offer Student ID',
                    placeholder: 'Offer Student ID',
                    required: true,
                    disabled: form1disabled
                },
                {
                    required: true,
                    type: 'select',
                    options: Intakes,
                    name: 'Intake Month',
                    label: 'Intake Month',
                    placeholder: 'Intake Month',
                    keyAccessor: x => x,
                    valueAccessor: x => x,
                    key: 'intakeMonth',
                    onChange: (e) => {
                        setFieldsValue({intakeMonth: e})
                    }
                },
                {
                    required: true,
                    type: 'select',
                    options: this.state.intakeYearList,
                    name: 'Intake Year',
                    label: 'Intake Year',
                    placeholder: 'Intake Year',
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    key: 'intakeYear',
                    onChange: (e) => {
                        setFieldsValue({intakeYear: e})
                    }
                }
            ]
        }
        let inputTypes1 = {
            fields: [
                {
                    key: 'semester',
                    label: 'Semester/Term',
                    placeholder: 'Semester/Term',
                    required: true,
                    disabled: true
                },
                {
                    key: 'semesterFee',
                    label: 'Semester Fees',
                    placeholder: 'Semester Fees',
                    type: 'number',
                    required: true,
                    hidden: universityDoc.universityCommissionType == 'Amount',
                    customField: (
                        <>
                            <CustomInput
                                title={'Semester Fees'}
                                leftContent={currencyValue}
                                rightContent={universityDoc.universityCommission ? `${universityDoc.universityCommission}%` : ''}
                                value={this.state.semesterFee}
                                onChange={(value) => {
                                    this.setState({semesterFee: value}, () => {
                                        this.events.reCalculateCommission(value)
                                    })
                                }}
                            />
                        </>
                    )
                },
                {
                    key: 'amount',
                    label: 'Commission',
                    placeholder: 'Commission',
                    type: 'number',
                    customField: (
                        <>
                            <CustomInput
                                title={'Commission'}
                                leftContent={currencyValue}
                                value={this.state.amount}
                                onChange={(value) => {
                                    this.setState({amount: value})
                                }}
                            />
                        </>
                    )
                },
                {
                    required: true,
                    type: 'select',
                    options: InvoiceMonthlyCycle(),
                    name: 'Invoice monthly cycle',
                    label: 'Invoice monthly cycle',
                    placeholder: 'Invoice monthly cycle',
                    keyAccessor: x => x.value,
                    valueAccessor: x => `${x.name}`,
                    key: 'invoiceMonthlyCycle',
                    onChange: (e) => {
                        setFieldsValue({
                            invoiceMonthlyCycle: e
                        })
                        this.events.chooseInvoiceMonthlyCycle(e)
                    }
                },
                {
                    key: 'invoiceDate',
                    label: 'Invoice Date',
                    placeholder: 'Invoice Date',
                    type: 'date',
                    required: true
                },
                {
                    key: 'semesterName',
                    label: 'Semester Name',
                    placeholder: 'Semester Name'
                }
            ]
        }
        let columns = [
            {
                title: '#',
                dataIndex: 'index',
                key: 'index',
                render: (item, record, index) => {
                    return (
                        <>{index + 1}</>
                    )
                }
            },
            {
                title: 'Semester',
                dataIndex: 'semester',
                key: 'semester',
                render: (item, record) => {
                    return (
                        <>
                            {item}
                            <div>
                                {record.semesterName ? `(Sem Name : ${record.semesterName})` : ''}
                            </div>
                        </>
                    )
                }
            },
            /*{
                title: 'Student ID',
                dataIndex: 'offerLetterStudentId',
                key: 'offerLetterStudentId',
                render: (item, record) => {
                    let {commissionDetails} = record;
                    return (
                        <>
                            {commissionDetails.offerLetterStudentId ? commissionDetails.offerLetterStudentId : ""}
                        </>
                    )
                }
            },*/
            {
                title: 'Semester Fees',
                dataIndex: 'semesterFee',
                key: 'semesterFee',
                render: (item, record) => {
                    let {commissionDetails, currency} = record
                    return (
                        <>
                            {item}
                            {currency ? ` ${currency}` : ''}
                        </>
                    )
                }
            },
            {
                title: 'Commission',
                dataIndex: 'amount',
                key: 'amount',
                render: (item, record) => {
                    let {commissionDetails, currency} = record
                    return (
                        <>
                            {item}
                            {currency ? ` ${currency}` : ''}
                        </>
                    )
                }
            },

            {
                title: 'Invoice Date',
                dataIndex: 'invoiceDate',
                key: 'invoiceDate',
                render: (item, record) => {
                    return (
                        <>
                            {item ? displayDate(item) : ''}
                        </>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item, record) => {
                    return (
                        <>
                            <StatusComponent record={record}/>
                        </>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (item, record, index) => {
                    return (
                        <div className={'comBtnGroup'}>
                            {record && record.status == 'Pending' && index == studentCommissionList.length - 1 ?
                                <>
                                    <Popconfirm title={'Are your sure, you want to delete this Commission?'}
                                                onConfirm={() => this.deleteCommission(record._id)}
                                                okText='Yes' cancelText='No'>
                                        <a className={'btn btn-danger btn-sm'}>
                                            <Icon type={'delete'}/> Delete
                                        </a>
                                    </Popconfirm>
                                </> : null}
                        </div>
                    )
                }
            }
        ]

        const formItemLayout = {
            labelCol: {
                xs: {span: 0},
                sm: {span: 0},
                md: {span: 0}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }
        return (
            <>
                <Drawer
                    visible={visible}
                    onClose={onClose}
                    title={'Add Commission'}
                    width={'80%'}>
                    <div className='form-box commissionBox'>
                        <div className='card unizportal'>
                            {studentObj && studentObj._id ? <>
                                <AddStudentInfoComponent studentObj={studentObj} universityDoc={universityDoc}/>
                                <Card className={'mt-2'}>
                                    {form1disabled ?
                                        <div className={'infoUni tableBox striped'}>
                                            {applications.commissionDetails && applications.commissionDetails.courseStartDate ? <>
                                                <Row>
                                                    <Col span={12}>
                                                        <CustomRowTable title={'Course Start Date'}
                                                                        value={
                                                                            <>
                                                                                {moment(applications.commissionDetails.courseStartDate).format('DD-MMM-YYYY')} &nbsp;
                                                                                <Tooltip
                                                                                    title={'Edit Commission Details'}>
                                                                                    <a onClick={() => this.events.showEditDrawer()}>
                                                                                        <Icon type={'edit'}/>
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </>
                                                                        }/>
                                                    </Col>
                                                    <Col span={12}>
                                                        <CustomRowTable title={'Tuition Fees'}
                                                                        value={<>
                                                                            {applications.commissionDetails.commissionCurrency}
                                                                            {` ${applications.commissionDetails.tuitionFee}`}
                                                                        </>}/>
                                                    </Col>
                                                    <Col span={12}>
                                                        <CustomRowTable title={'Offer Letter Student ID'}
                                                                        value={applications.commissionDetails.offerLetterStudentId}/>
                                                    </Col>
                                                    {applications.intake && applications.intake.month ? <Col span={12}>
                                                        <CustomRowTable title={'Intake'}
                                                                        value={
                                                                            <>
                                                                                {applications.intake.month}-{applications.intake.year}
                                                                            </>}/>
                                                    </Col> : null}
                                                </Row>

                                            </> : null}
                                        </div>
                                        : <Form onSubmit={this.handleSubmitCommissionDetails}>
                                            <Row gutter={16} className={'wrapBox'}>
                                                {inputTypes.fields.map((item, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <Col span={4} key={key}>
                                                                <GetEachFormFields
                                                                    item={item}
                                                                    getFieldDecorator={getFieldDecorator}
                                                                    formItemLayout={formItemLayout}/>
                                                            </Col>
                                                        </React.Fragment>
                                                    )
                                                })}
                                                <Col span={4}>
                                                    <Form.Item>
                                                        <Button
                                                            type='primary' htmlType='submit' className={'btn mt40'}
                                                            disabled={form1disabled}>
                                                            Save
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </Form>}
                                </Card>

                                {form1disabled && <>
                                    <Card className={'mt-4'}>
                                        <Form onSubmit={this.handleAddCommission}>
                                            <Row gutter={16} className={'wrapBox'}>
                                                {inputTypes1.fields.map((item, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            {!item.hidden && <Col span={4} key={key}>
                                                                {item.customField ? item.customField :
                                                                    <GetEachFormFields
                                                                        item={item}
                                                                        getFieldDecorator={getFieldDecorator}
                                                                        formItemLayout={formItemLayout}/>}
                                                            </Col>}
                                                        </React.Fragment>
                                                    )
                                                })}
                                                <Col span={4}>
                                                    <Form.Item>
                                                        <Button type='primary' htmlType='submit' className={'btn mt10'}>
                                                            Add Commission
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                    <Card>
                                        <div className='card'>
                                            <div className='card-body table-responsive'>
                                                <Table
                                                    rowKey={(item, index) => {
                                                        return index
                                                    }}
                                                    columns={columns}
                                                    dataSource={studentCommissionList}
                                                    pagination={false}>
                                                </Table>
                                            </div>
                                        </div>
                                    </Card>
                                </>}
                            </> : null}

                        </div>
                    </div>
                </Drawer>

                {visibleEditDrawer ?
                    <EditCommissionDetails
                        visible={visibleEditDrawer}
                        studentData={this.props.studentData}
                        onSubmit={this.events.submitEditDrawer}
                        onClose={this.events.hideEditDrawer}/>
                    : null}
            </>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCommissionDrawer)
