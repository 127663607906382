import {singleUserEnquiryUrl, unizHomeEnquiryUrl} from '../api'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {notification} from '../../../components/Elements/appUtils'


export const unizHomeEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(unizHomeEnquiryUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
export const singleUserEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(singleUserEnquiryUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
