import {apiUrl, apiUrl2} from '../../../settings'

export const getUniversityCommission = () => {
    return apiUrl + '/getUniversityCommission'
}
export const getUniversityAndCourseApi = () => {
    return apiUrl + '/getUniversityAndCourse'
}
export const updateUniversityCommissionApi = () => {
    return apiUrl + '/updateUniversityCommission'
}
export const addStudentCommissionUrl = () => {
    return apiUrl + '/addStudentCommission'
}

export const universityCommissionListUrl = () => {
    return apiUrl + '/universityCommissionList'
}
export const updateCommissionStatusApi = () => {
    return apiUrl + '/updateCommissionStatus'
}

export const commissionClaimReceivedApi = () => {
    return apiUrl + '/commissionClaimReceived'
}

export const commissionEditReceivedApi = () => {
    return apiUrl + '/commissionClaimReceived'
}

export const raiseAgentCommissionApi = () => {
    return apiUrl + '/raiseAgentCommission'
}

export const deleteCommissionUrl = () => {
    return apiUrl + '/deleteCommission'
}

export const calculateAgentCommissionUrl = () => {
    return apiUrl + '/calculateAgentCommission'
}

export const submitAgentCommissionUrl = () => {
    return apiUrl + '/submitAgentCommission'
}

export const UpdateAgentCommissionUrl = () => {
    return apiUrl + '/updateAgentCommission'
}

export const receivedCommissionListUrl = () => {
    return apiUrl + '/receivedCommissionList'
}

export const agentCommissionListUrl = () => {
    return apiUrl + '/agentCommissionList'
}

export const singleReceivedCommissionUrl = () => {
    return apiUrl + '/singleReceivedCommission'
}

export const singleAgentCommissionUrl = () => {
    return apiUrl + '/singleAgentCommission'
}

export const payAgentCommissionUrl = () => {
    return apiUrl + '/payAgentCommission'
}

export const commissionClaimUrl = () => {
    return apiUrl + '/commissionClaim'
}

export const cancelReceivedCommissionUrl = () => {
    return apiUrl + '/cancelReceivedCommission'
}

export const commissionCountByStatusUrl = () => {
    return apiUrl + '/commissionCountByStatus'
}
export const allAgentCommissionListUrl = () => {
    return apiUrl + '/all-agent-commission-list'
}
export const approvedAgentInvoiceUrl = () => {
    return apiUrl + '/approvedAgentInvoice'
}
export const getAgentCommissionListUrl = () => {
    return apiUrl + '/getAgentCommissionList'
}

export const agentCurrentCommissionUrl = () => {
    return apiUrl + '/agentCurrentCommission'
}
export const agentCommissionTransactionUrl = () => {
    return apiUrl + '/agentCommissionTransaction'
}

export const agentCommissionReferToManagerUrl = () => {
    return apiUrl + '/agentCommissionReferToManager'
}

export const agentCommissionApprovedByManagerUrl = () => {
    return apiUrl + '/agentCommissionApprovedByManager'
}
export const loadExpenseTransactionsUrl = () => {
    return apiUrl + '/expenseTransactions'
}
export const onshoreSubmitAgentCommissionUrl = () => {
    return apiUrl + '/onshore/submitAgentCommission'
}

export const getServerDateUrl = () => {
    return apiUrl + '/api/get-server-date'
}
export const agentCommissionTransactionForAdminUrl = () => {
    return apiUrl2 + '/agentCommissionTransaction'
}
export const getAgentCurrentCommissionUrl = () => {
    return apiUrl2 + '/getAgentCurrentCommission'
}
