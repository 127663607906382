import {useDispatch} from 'react-redux'
import React, {useState} from 'react'
import {Button, Input, Modal, notification, Select} from '../../../components/Elements/appUtils'
import {verifyAgentFxn} from '../actions/user'

let {Option} = Select
const AgentVerifiedModal = (props) => {
    const {visible, onClose, agentId, onSubmit, agentVerified} = props
    const dispatch = useDispatch()
    const [verified, setVerified] = useState(agentVerified)

    const handleStatusChange = (value) => {
        setVerified(value)
    }

    const handleSubmit = async () => {
        let resp = await dispatch(verifyAgentFxn({agentId: agentId, agentVerified: verified}))
        if (resp && resp.success) {
            onSubmit()
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            title='Update Agent Verification Status'>
            <Select onChange={handleStatusChange} value={verified} placeholder={'Verified Status'}>
                <Option value={true}>Verified</Option>
                <Option value={false}>Not Verified</Option>
            </Select>

            <Button type='primary' onClick={handleSubmit} className={'mt20'}>
                Submit
            </Button>
        </Modal>
    )
}
export default AgentVerifiedModal
