import React, {useState, useEffect, useRef} from 'react'
import {CustomRowTable} from '../../../components/_utils/RowTable'
import {Drawer, Card, Skeleton, Tooltip, Icon} from '../../../components/Elements/appUtils'
import {getUniversity, listAllUniversities} from '../actions/university'
import {useDispatch} from 'react-redux'
import {listAllCampus} from '../../campus/actions/campus'
import {
    DefaultTablePagination,
    countryIds,
    departmentObj
} from '../../../components/_utils/appUtils'
import {getUser, singleUserProfileFxn} from '../../users/actions/user'
import {listAllCountries} from '../../countries/actions/countries'
import lodash from 'lodash'
import {TableComp} from '../../../components/Elements/appUtils'

const CanadaOnshoreListDetails = props => {
    let dispatch = useDispatch()
    let {visible, onClose} = props
    const [itemNo, setItemNo] = useState(1)
    const [count, setCount] = useState(20)

    let [user, setUser] = useState({})
    const tableRef = useRef()
    const apiUkRequest = params => {
        return new Promise(async (resolve) => {
            params = {
                ...params,
                sortField: 'universityName',
                sortOrder: 'ascend',
                universityCountry: countryIds.canadaOnshore
            }

            const respData = await dispatch(
                listAllUniversities({
                    ...params,
                    select: [
                        'universityName',
                        'universityCity',
                        'universityState',
                        'ugCommission',
                        'pgCommission',
                        'universityCountry',
                        'commissionType'
                    ],
                    regExFilters: ['universityName']
                })
            )
            setItemNo(params.page ? params.page : 1)
            setCount(params.count)
            resolve(respData)
        })
    }

    const loadUser = async () => {
        let user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null
        if (user && user._id) {
            let resp = await dispatch(getUser(user._id))
            setUser(resp)
        }
    }
    useEffect(() => {
        loadUser()
    }, [])

    const CommissionComponent = props => {
        let {universityCountry, user, commissionObj, commissionType} = props
        let {currency, countryName} = universityCountry
        let newField = null
        if (countryName) {
            countryName = countryName.trim()
            countryName = countryName.replace(/ /gi, '_')
            newField = countryName.toLowerCase() + 'Level'
        }
        let packageList = {
            1: 'silver',
            2: 'gold',
            3: 'platinum'
        }
        return (
            <>
                {user &&
                newField &&
                user[newField] &&
                !lodash.isEqual(commissionObj) ? (
                    <label className={'label label-success sm'}>
                        {currency && commissionType == 'Amount' ? currency : ''}
                        {commissionObj[packageList[user[newField]]]}
                        {commissionType == 'Percentage' ? '%' : ''}
                    </label>
                ) : null}
            </>
        )
    }


    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (item, record, index) => {
                return (<>
                    {(itemNo - 1) * (count) + index + 1}
                </>)
            }
        },
        {
            title: 'University Name',
            dataIndex: 'universityName',
            key: 'universityName',
            searchTextName: 'universityName',
            render: (item, record) => {
                return <div>{item}</div>
            }
        },
        {
            title: 'City Name',
            dataIndex: 'universityCity.cityName',
            key: 'universityCity.cityName',
            render: (item, record) => {
                return <div>{item}</div>
            }
        },
        {
            title: 'State Name',
            dataIndex: 'universityState.stateName',
            key: 'universityState.stateName',
            render: (item, record) => {
                return <div>{item}</div>
            }
        },
        {
            title: 'PG commission',
            dataIndex: 'pgCommission',
            key: 'pgCommission',
            render: (item, record) => {
                console.log(item, record, "item, record")
                let {universityCountry, commissionType} = record
                let {currency} = universityCountry
                return (

                    <div>
                        <CommissionComponent
                            user={user}
                            commissionType={commissionType}
                            universityCountry={universityCountry}
                            commissionObj={item}
                        />

                    </div>
                )
            }
        },
        {
            title: 'UG commission',
            dataIndex: 'ugCommission',
            key: 'ugCommission',
            render: (item, record) => {
                let {universityCountry, commissionType} = record
                let {currency} = universityCountry
                return (
                    <div>

                        <CommissionComponent
                            user={user}
                            commissionType={commissionType}
                            universityCountry={universityCountry}
                            commissionObj={item}
                        />
                    </div>
                )
            }
        }
    ]
    return (
        <Drawer
            visible={visible}
            title={`Canada Onshore Commission`}
            width={'75%'}
            onClose={onClose}>
            <div className='card-body table-responsive'>

                <TableComp
                    columns={columns}
                    apiRequest={apiUkRequest}
                    pagination={DefaultTablePagination({defaultPageSize: 20, position: 'bottom'})}
                    ref={tableRef}
                />
            </div>
        </Drawer>
    )
}

export {CanadaOnshoreListDetails}
