import React from 'react'
import {Button, Checkbox, Form, Input} from '../../components/Elements/appUtils'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import Footer from '../footer'
import {Opportunity} from './opportunityComponent'

const RefundPolicy = () => {
    let dispatch = useDispatch()

    return (
        <React.Fragment>

            <section className="banner blog-banner blog-inner">

                <div className="container">
                    <div className="head">
                        <h2>Refund Policy</h2>
                    </div>
                </div>

            </section>

            <section className="gap-62 policy blog-inner pt-0">
                <div className="container">


                    <div className="box">
                        <p>Last Updated: June 13, 2021</p>

                        <p>Being the user of Uniz Portal, one should require to be responsive that you are signing the
                            contract with
                            Uniz Portal as well as you accept and agree to put up with the terms and condition of Uniz
                            Portal and our
                            services like Admission Counselling, Admission, Guidance, Travel, VISA, and others. Once you
                            fill the
                            Registration or give your consent to the executives of Uniz Portal for any specific
                            services, then you
                            need to pay the amount specified for that services.</p>

                        <p>Once you complete the payment as signified in the form, you would be eligible for the
                            services of Uniz
                            Portal. Normally, Uniz Portal provides one time services to its users and the cost of the
                            services are
                            charged accordingly. Once you get registration with Uniz Portal, the university application
                            fees are not
                            refundable. But, for other reasons, we are mentioning our refund policies here that would
                            also be
                            mentioned in the form that should be signed by both parties.</p>

                        <p>The arrears should be cleared without any delay. Once you make the payments and avail your
                            documents to
                            us, we shall start the application procedure that cannot be discontinued under any
                            circumstances. The
                            services availed by Uniz Portal would be completed once the candidate arrives at the
                            university abroad.
                            Uniz Portal provides the pre-departure tutoring to the students and their parents to ensure
                            the travel
                            processes and understandings. We don’t guarantee the Visa issuance by the officials or delay
                            by the
                            embassies to issue the visa.</p>

                        <div className="content">
                            <h5>Uniz Portal is not responsible for the Visa delay or Visa rejection.</h5>

                            <ul className="mb-3">
                                <li>• The students who wish to withdraw their application are required to inform the
                                    organization in
                                    writing.
                                </li>
                                <li>• We shall not reimburse if the application is withdrawn after the deadline.</li>
                                <li>• The university application fee is not refundable.</li>
                                <li>• We are not responsible for the payment made to other institutions.</li>
                                <li>• Uniz Portal suggests you clear the refund policy to our experts before making
                                    payments.
                                </li>
                            </ul>

                            <p>Students are required to pay in advance for university application form to register the
                                admission in
                                the foreign university. After payment, if the candidate cancels the application, the
                                service charges and
                                other charges will be deducted from the fee. Consultation fee and the charges paid to
                                the Ministry are
                                not refundable. The candidate may request a refund if their visa is rejected. The refund
                                would be
                                initiated within 45 to 90 working days. The refundable amount would be paid through bank
                                transfer in
                                student’s account.</p>

                        </div>

                        <div className="content">
                            <h5>The amount will not be refunded if</h5>

                            <ul className="mb-3">
                                <li>• The student doesn’t fulfil the embassy requirements.</li>
                                <li>• Student fails in the visa interview.</li>
                                <li>• Embassy is not satisfied with the documents of the student.</li>
                                <li>• The student submitted the documents after the deadline.</li>
                                <li>• The student submitted tampered documents.</li>
                                <li>• The student gets deported from the destination country.</li>
                            </ul>

                            <p><i>The refund request would not be entertained after the deadline. Uniz Portal would not
                                be responsible
                                for any delay caused by third parties, i.e. courier services, embassy, and others.</i>
                            </p>

                            <p><i>* All disputes are subject to jurisdiction of courts in Haryana only.</i></p>

                        </div>

                    </div>

                </div>
            </section>


            <Opportunity/>
        </React.Fragment>
    )
}

export default RefundPolicy
