import {Select, Drawer, Row, Col, Card, notification} from '../../../components/Elements/appUtils'
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    InputBox, marketingUniversity, getDirectUniversityName
} from '../../../components/_utils/appUtils'
import _ from 'lodash'
import {
    getMarketingUserByUniversityFxn,
    listAllUsers,
    singleAgentForMarketingFxn, updateMarketingInAgentsFxn,
    updateMarketingUserInAgentFxn
} from '../actions/user';

const {Option} = Select;

const marketingStateInit = {
    countryId: "",
    university: "",
    marketingUserId: "",
};

const MarketingComponent = (props) => {
    let {data, index, marketingEvents, countryList} = props;
    let [uniArr, setUniArr] = useState([])
    let [marketingUserList, setMarketingUserList] = useState([])
    let chooseCountry = () => {
        let findCountryName = _.find(countryList, (item) => {
            return item._id.toString() == data.countryId
        })
        if (findCountryName) {
            let countryName = findCountryName.countryName.toLowerCase();
            const universityLst = marketingUniversity[`${countryName}List`] ?
                marketingUniversity[`${countryName}List`] : marketingUniversity.others
            setUniArr(universityLst)
        }
    }
    let loadMarketingUserData = async () => {
        if (data.countryId && data.university) {
            let {data: respData} = await getMarketingUserByUniversityFxn(data);
            setMarketingUserList(respData)
        } else {
            setMarketingUserList([])
        }
    }
    useEffect(() => {
        chooseCountry()
    }, [data.countryId])

    useEffect(() => {
        loadMarketingUserData()
    }, [data])

    return (
        <>
            <div className="row new-border">
                <div className="col-md-12">
                    <div className={"row"} key={index}>
                        <div className={"col-md-4"}>
                            <InputBox title={"Country List"}>
                                <select
                                    className={"form-control"}
                                    value={data.countryId}
                                    onChange={({target}) => {
                                        let obj = {
                                            countryId: target.value
                                        }
                                        marketingEvents.updateState({...obj}, index);
                                    }}>
                                    <option value={""}>Choose Country</option>
                                    {countryList && countryList.length && countryList.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>
                                                {item.countryName}
                                            </option>
                                        );
                                    })}
                                </select>

                            </InputBox>

                        </div>
                        <div className={"col-md-4"}>
                            <InputBox title={"University List"}>
                                <select
                                    className={"form-control"}
                                    value={data.university}
                                    onChange={({target}) => {
                                        marketingEvents.updateState({university: target.value}, index);
                                    }}>
                                    <option value={""}>Choose University</option>
                                    {data.countryId && uniArr && uniArr.length ? uniArr.map((item) => {
                                        return (
                                            <>
                                                <option value={item.value}>
                                                    {item.label}
                                                </option>
                                            </>
                                        )
                                    }) : null}
                                </select>
                            </InputBox>
                        </div>

                        <div className='col-md-4'>
                            <InputBox title={"Marketing List"}>
                                <select
                                    className={"form-control"}
                                    value={data.marketingUserId}
                                    onChange={({target}) => {
                                        marketingEvents.updateState({marketingUserId: target.value}, index);
                                    }}>
                                    <option value={""}>Choose Marketing User</option>
                                    {marketingUserList && marketingUserList.length && marketingUserList.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>

                            </InputBox>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
const MarketingComponentRow = (props) => {
    let dispatch = useDispatch()
    let {data, index, agentId, onSubmit} = props;
    let [isEdit, setIsEdit] = useState(false)
    let [marketingUserList, setMarketingUserList] = useState([])
    let [marketingUserId, setMarketingUserId] = useState("")

    let loadMarketingUserData = async () => {
        if (data.countryId && data.university) {
            let obj = {
                countryId: data.countryId._id,
                university: data.university
            }
            if (data && data.marketingUserId && data.marketingUserId._id) {
                setMarketingUserId(data.marketingUserId._id)
            }
            let {data: respData} = await getMarketingUserByUniversityFxn(obj);
            setMarketingUserList(respData)
        } else {
            setMarketingUserList([])
        }
    }

    useEffect(() => {
        loadMarketingUserData()
    }, [data])


    let updateMarketingUserInList = async (recordId) => {
        if (!marketingUserId) {
            notification.warning({message: "Please choose marketing user."})
            return
        }
        if (data && data.marketingUserId && data.marketingUserId._id && data.marketingUserId._id == marketingUserId) {
            setIsEdit(false)
        } else {
            let {success} = await dispatch(updateMarketingUserInAgentFxn({agentId, marketingUserId, recordId}));
            if (success) {
                onSubmit();
            }
        }
    }


    return (
        <>
            <tr key={data._id}>
                <td>{index + 1}</td>
                <td>{data.countryId && data.countryId.countryName ? data.countryId.countryName : ""}</td>
                <td>{getDirectUniversityName(data.university)}</td>
                <td style={{width: 250}}>
                    {isEdit ?
                        <>
                            <select
                                className={"form-control sm"}
                                value={marketingUserId}
                                onChange={({target}) => {
                                    setMarketingUserId(target.value)
                                }}>
                                <option value={""}>Choose Marketing User</option>
                                {marketingUserList && marketingUserList.length && marketingUserList.map((item) => {
                                    return (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </> :
                        <>
                            {data.marketingUserId && data.marketingUserId.name ? data.marketingUserId.name : ''}
                            <br/>
                            {data.marketingUserId && data.marketingUserId.countryName ?
                                <small>({data.marketingUserId.countryName})</small>
                                : ''}
                        </>
                    }
                </td>
                <td>
                    {isEdit ?
                        <>
                            <a className={'btn btn-primary'} onClick={() => {
                                updateMarketingUserInList(data._id)
                            }}>Update</a>
                            <a className={'btn btn-danger ml10'} onClick={() => setIsEdit(false)}>Cancel</a>
                        </> :
                        <>
                            <a className={'btn btn-default'} onClick={() => setIsEdit(true)}>Edit</a>
                        </>
                    }
                </td>
            </tr>
        </>
    )
}

const MarketingDrawer = (props) => {
    let {visible, onclose, agentId, onSubmit, countryList, countryName, companyName} = props
    let [marketingState, setMarketingState] = useState([marketingStateInit])
    let [oldMarketingState, setOldMarketingState] = useState([]);
    let [armUserList, setArmUserList] = useState([])
    let [armUsers, setArmUsers] = useState([])
    const dispatch = useDispatch();
    let setDefaultValues = (userData) => {
        let {agentMarketing, armUsers: armUsersData} = userData;
        if (agentMarketing && agentMarketing.length) {
            // setMarketingState(agentMarketing)
            setOldMarketingState(agentMarketing)
        }
        if (armUsersData && armUsersData.length) {
            setArmUsers(armUsersData)
        }
    }

    useEffect(() => {
        loadArmManagers()
        loadSingleUserData()
    }, [])

    let loadSingleUserData = async () => {
        let {data} = await dispatch(singleAgentForMarketingFxn({userId: agentId}))
        setDefaultValues(data)
    }

    const loadArmManagers = async () => {
        let {data} = await dispatch(listAllUsers({
            userType: 'branchManager',
            branchManagerType: "agentWise",
            select: ['name'],
            results: 100
        }))
        setArmUserList(data)
    }

    const marketingEvents = {
        updateState: (data, index) => {
            let cloneState = _.clone(marketingState);
            cloneState[index] = {...cloneState[index], ...data}
            setMarketingState(cloneState)
        },

        allData: () => {
            setMarketingState([...marketingState, marketingStateInit]);
        },

        addEdu: () => {
            setMarketingState((preState) => {
                return [...preState, marketingStateInit];
            });
        },
        updateAgent: async () => {
            let marketingStateNew = _.reject(marketingState, (item) => {
                return !item.countryId || !item.marketingUserId
            })
            let resp = await dispatch(updateMarketingInAgentsFxn({
                agentMarketing: marketingStateNew,
                _id: agentId,
                armUsers
            }));
            if (resp && resp.success) {
                onSubmit()
            }
        }
    };

    return (
        <>
            <Drawer
                title={`Assign Marketing Users - ${companyName} (Country : ${countryName})`}
                visible={visible}
                placement="right"
                onClose={onclose}
                width={'60%'}>
                <div className={'card unizportal'}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InputBox title={'Choose ARM Managers'}>
                                <Select
                                    mode="multiple"
                                    value={armUsers}
                                    placeholder="Please select"
                                    onChange={(value) => {
                                        setArmUsers(value)
                                    }}>
                                    {armUserList && armUserList.length ? armUserList.map((item) => {
                                        return (
                                            <Option value={item._id} key={item._id}>{item.name}</Option>
                                        )
                                    }) : null}
                                </Select>
                            </InputBox>
                        </Col>
                        {oldMarketingState && oldMarketingState.length ? <Col span={24} className={'mb10 mt10'}>
                            <Card>
                                <table className={'table table-bordered'}>
                                    <tr>
                                        <th>#</th>
                                        <th>Country</th>
                                        <th>University</th>
                                        <th>Marketing User</th>
                                        <th>Action</th>
                                    </tr>
                                    {oldMarketingState.map((item, index) => {
                                        return (
                                            <MarketingComponentRow data={item} index={index} {...props}/>
                                        )
                                    })}
                                </table>
                            </Card>
                        </Col> : null}
                        <Col span={24}>
                            {marketingState.map((data, index) => {
                                return (
                                    <MarketingComponent
                                        data={data}
                                        key={index}
                                        index={index}
                                        marketingState={marketingState}
                                        countryList={countryList}
                                        marketingEvents={marketingEvents}
                                    />
                                )
                            })}

                            <a className={'btn btn-link '} style={{float: 'right'}} onClick={marketingEvents.allData}>
                                Add More</a>

                            <a className={'btn btn-success mt20'} onClick={() => {
                                marketingEvents.updateAgent()
                            }}>
                                Submit
                            </a>
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </>
    )
}

export default MarketingDrawer
