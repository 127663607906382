import React, {useEffect, useState} from 'react'
import {getUser} from '../../users/actions/user'
import {connect, useDispatch} from 'react-redux'
import {Drawer, Row, Col, Select} from '../../../components/Elements/appUtils'
import _ from 'lodash'
import {fcmtUniversity} from '../../../components/_utils/appUtils'
import {listAllCourse} from '../../course/actions/courseAction'
import {getEvaluateLoadMoreCourse} from "../../evaluateProfile/action";
import DirectUniversityForm from "./directUniversityForm"

const {Option} = Select

const DirectUniversity = (props) => {
    let {visible, onClose, dispatch, selectedUniversity} = props
    let [currentUser, setCurrentUser] = useState({})
    let [coursesList, setCoursesList] = useState([])

    let events = {
        loadCourse: async () => {
            let params = {
                courseUniversity: fcmtUniversity,
                results: 1000
            }
            let {data} = await dispatch(listAllCourse(params))
            setCoursesList(data)
            // setCourseCount(courseCount)
        },
        loadMoreSearchCourse: async () => {
            let skip = coursesList.length
            let params = {
                universityId: fcmtUniversity,
                limit: 9,
                skip
            }
            let data = await dispatch(getEvaluateLoadMoreCourse(params))
            let cloneCourseList = _.clone(coursesList)
            cloneCourseList = [...cloneCourseList, ...data]
            setCoursesList(cloneCourseList)
        }
    }


    useEffect(() => {
        loadCurrentUser()
        // events.loadCourse()
    }, [])

    const loadCurrentUser = async () => {
        let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
        let resp = await dispatch(getUser(user._id))
        setCurrentUser(resp)
    }
    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width={'85%'}
        >
            <DirectUniversityForm
                selectedUniversity={selectedUniversity}
                onClose={onClose}/>
        </Drawer>
    )

}


const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectUniversity)
