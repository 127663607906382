import React, {useState, useEffect} from 'react'
import {
    Drawer,
    Input,
    Card,
    Button,
    Table,
    notification,
    Select,
    Icon,
    Tooltip,
    List,
    Tag
} from '../../../components/Elements/appUtils'
import {InputBox} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import {submitApplicationsPendency, updateAppPendency} from '../actions/chooseStudent'
import {listAllPendency} from '../../pendency/actions/pendency'
import AddPendency from '../../pendency/views/add'
import _ from 'lodash'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'

const {TextArea} = Input
const {Option} = Select

const SelectPendency = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const dispatch = useDispatch()
    let {visible, applicationObj, onAddPendencyClose, reloadFxn, onClose} = props
    let {pendencyArr} = applicationObj.applications
    let [state, setState] = useState({
        allPendency: [],
        pendency: '',
        otherPendency: '',
        visiblePendencyDrawer: false
    })
    let [pendency, setPendency] = useState([])
    let [document, setDocument] = useState({})

    useEffect(() => {
        loadPendency()
    }, [])

    const submitPendency = async () => {
        if (!pendency || (pendency && !pendency.length)) {
            return notification.warn({message: 'Choose Add atleast 1 Pendency'})
        }

        let obj = {
            studentId: applicationObj._id,
            applicationId: applicationObj.applications._id,
            pendency: pendency
        }

        let data = await dispatch(submitApplicationsPendency(obj))
        if (data && !data.error) {
            setPendency([])
            setState({
                ...state,
                pendency: '',
                otherPendency: ''
            })
            onClose()

        }
    }

    const submitDocument = async () => {
        if (_.isEmpty(document)) {
            return notification.warn({message: 'Please choose atleast 1 Pendency document.'})
        }
        let aa = []
        let obj = {
            studentId: applicationObj._id,
            applicationId: applicationObj.applications._id
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify(obj))
        _.each(document, (item, key) => {
            console.log(item)
            let extension = item.name.split('.')
                .pop()
            let newFileName = `${item.pendencyName}.${extension}`
            fd.append('document', item, newFileName)
        })
        let data = await dispatch(updateAppPendency(fd))
        if (data && !data.error) {
            setPendency([])
            setState({
                ...state,
                pendency: '',
                otherPendency: ''
            })
            onClose()

        }
    }

    const addPendency = () => {
        if (!state.pendency) {
            return notification.warn({message: 'Please enter pendency.'})
        }
        let cloneD = _.clone(pendency)
        let fieldPen = state.pendency
        if (state.pendency == 'other') {
            if (!state.otherPendency) {
                return notification.warn({message: 'Please enter other pendency.'})
            }
            fieldPen = state.otherPendency
        }
        let findE = _.find(cloneD, (item) => {
            return item == fieldPen
        })
        if (findE) {
            setState({...state, pendency: ''})
            return notification.warn({message: 'Pendency already exists.'})
        } else {
            let findO = _.find(pendencyArr, (item) => {
                return item.pendency == fieldPen
            })
            if (findO) {
                setState({...state, pendency: ''})
                return notification.warn({message: 'Pendency already exists.'})
            } else {
                cloneD.push(fieldPen)
                setPendency(cloneD)
                setState({...state, pendency: ''})
            }
        }
    }

    const loadPendency = async () => {
        let {data} = await dispatch(listAllPendency())
        let allPendency = []
        _.each(data, (item) => {
            if (item && item.name) {
                allPendency.push(item.name)
            }
        })
        setState({
            ...state,
            allPendency: [...allPendency, 'other'],
            visiblePendencyDrawer: false
        })
    }


    const events = {
        hideDrawer: () => {
            loadPendency()
        },
        chooseDocument: (e, pendency) => {
            let {name, files} = e.target
            if (files && files.length) {
                let file = files[0]
                file.pendencyName = pendency.pendency
                file.pendencyId = pendency._id
                let cloneDoc = _.clone(document)
                cloneDoc[pendency._id] = files[0]
                setDocument(cloneDoc)
            }
        },
        removeImg: (pendencyId) => {
            let cloneDoc = _.clone(document)
            delete cloneDoc[pendencyId]
            setDocument(cloneDoc)
        }
    }

    let columns = [
        {
            title: 'Pendency',
            dataIndex: 'pendency',
            key: 'pendency',
            render: (item) => {
                return (
                    <label className={'label label-tag'}> {item}</label>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (item) => {
                return (
                    <label className={`label ${item == 'Complete' ? 'label-success-green' : 'label-warning'}`}>
                        {item}
                    </label>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            width: 300,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {record.status == 'Pending' ?
                            <Tooltip title={'Upload Pendency'}>
                                <div className="image-upload documentBox">
                                    <label htmlFor={item}>
                                        <img src={'/assets/icons/cloud-computing-upload.png'}/>
                                    </label>
                                    <input type={'file'} name={item} id={item} className={'form-control'}
                                           onChange={(e) => {
                                               events.chooseDocument(e, record)
                                           }}/>

                                    {document[item] && document[item].name ?
                                        <Tag onClick={() => events.removeImg(record._id)}>
                                            <span className={'emailTag'}> {document[item].name} </span>
                                            <Icon type={'close'} className={'emailIcon'}/>
                                        </Tag>
                                        : 'Upload Document'}
                                </div>
                            </Tooltip> : ''}
                        {record.status == 'Complete' ?
                            <Tooltip title={'Download'}>
                                <a download={record.document.name} href={record.document.url}>
                                    <img src={'/assets/icons/cloud-computing-download.png'}
                                         style={{height: 30, marginLeft: 5, marginRight: 13}}/>
                                    Download File
                                </a>
                            </Tooltip>
                            : null}

                    </React.Fragment>
                )
            }
        }
    ]

    let {allPendency} = state
    return (
        <React.Fragment>
            <Drawer
                visible={visible}
                title={`Select Pendency`}
                width={'60%'}
                closable={true}
                maskClosable={true}
                onClose={onAddPendencyClose}>
                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Card>
                        <InputBox title={'Pendency'} className={'rowFlex'}>
                            <Select
                                placeholder={'Pendency'}
                                value={state.pendency}
                                style={{width: '100%'}}
                                onChange={ei => {
                                    setState({...state, pendency: ei})
                                }}>
                                {state.allPendency && state.allPendency.length ? state.allPendency.map((item, key) => {
                                    return (
                                        <Option value={item}>{item}</Option>
                                    )
                                }) : null}
                            </Select>
                            <button className={'bs_btn bs-sm bs-success'}
                                    onClick={() => addPendency()}>
                                Add
                            </button>
                            <button className={'bs_btn bs-sm bs-info'}
                                    onClick={() => setState({...state, visiblePendencyDrawer: true})}>
                                <Icon type={'plus'}/>
                            </button>
                        </InputBox>
                        {state.pendency && state.pendency == 'other' ?
                            <InputBox title={'Other Pendency'}>
                                <Input value={state.otherPendency} onChange={(e) => {
                                    setState({
                                        ...state,
                                        otherPendency: e.target.value
                                    })
                                }}/>
                            </InputBox> : ''}

                        {pendency && pendency.length ?
                            <InputBox title={'Pendency List'}>
                                <List
                                    size="small"
                                    bordered
                                    dataSource={pendency}
                                    renderItem={item => <List.Item>{item ? item : null}</List.Item>}
                                />
                            </InputBox> : null}

                        <InputBox title={''}>
                            <Button type="primary" onClick={() => submitPendency()}>
                                Submit
                            </Button>
                        </InputBox>
                    </Card>
                </CheckUserRight>
                <Card>
                    <Table pagination={false} className={'pendencyTab'} bordered={true}
                           columns={columns} dataSource={pendencyArr}>
                    </Table>
                    <InputBox title={''}>
                        <Button type="primary" onClick={() => submitDocument()}>
                            Upload Document
                        </Button>
                    </InputBox>
                </Card>

                {state.visiblePendencyDrawer ?
                    <AddPendency visible={state.visiblePendencyDrawer} events={events}/> : ''}
            </Drawer>
        </React.Fragment>
    )
}

export default SelectPendency
