import React, {Suspense} from 'react'
import {
    ukApplicationSteps,
    canadaApplicationSteps,
    australiaApplicationSteps,
    StatusNameObj,
    usaApplicationSteps,
    StatusLabel,
    countryIds
} from '../../../components/_utils/appUtils'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import CanadaUniversityRecordCount from '../canadaUniversityRecordCount'
import UkUniversityRecordCount from '../ukUniversityRecordCount'

const OSHCInsuranceApplicationsBtn = React.lazy(
    () => import('./adminDashboard').then(module => ({default: module.OSHCInsuranceApplicationsBtn}))
)


const UkCountryCount = (props) => {
    let {data, callback} = props

    return (
        <React.Fragment>
            <div className='card-pane-right listing-section'>
                <label><LazyLoadImage src='/dist/img/united-kingdomFlag.png' alt=''/> United kingdom</label>

                <InnerBlock data={data} callback={callback} statusList={ukApplicationSteps}/>
            </div>
        </React.Fragment>
    )
}

const CanadaCountryCount = (props) => {
    let {data, callback} = props

    return (
        <React.Fragment>
            <div className='card-pane-right listing-section'>
                <label><LazyLoadImage src='/dist/img/canadaFlag.png' alt=''/> Canada</label>

                <InnerBlock data={data} callback={callback} statusList={canadaApplicationSteps}/>

            </div>
        </React.Fragment>
    )
}

const AustraliaCountryCount = (props) => {
    let {data, callback} = props

    return (
        <React.Fragment>
            <div className='card-pane-right listing-section'>
                <label><LazyLoadImage src='/dist/img/australiaFlag.png' alt=''/> Australia</label>

                <InnerBlock data={data} callback={callback} statusList={australiaApplicationSteps}/>

            </div>
        </React.Fragment>
    )
}


const InnerBlock = ({statusList, callback, data}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <ul>
            {statusList.map((item, key) => {
                return (
                    item.name !== 'New Student' &&
                    <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
                        <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                        <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name} </p>
                    </li>
                )
            })}
        </ul>
    )
}

const InnerBlockNew = ({statusList, callback, data}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none', padding: '30px 0'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        item.name !== 'New Student' ?
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls pointerCls-pdf'}
                                key={key}

                            >
                                <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                            </li> : null
                    )
                })}
            </ul>
        </div>
    )
}

const CanadaInnerBlock = ({statusList, callback, data, userCountryName, userData, currentUserRights}) => {
    let {countryId = '', countArr = {}} = data

    return (
        <>
            <div className='card-pane-right listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' ?
                                <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}
                                >
                                    <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                    <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                                </li> : null
                        )
                    })}
                </ul>
            </div>

            <CanadaUniversityRecordCount
                user={userData} userCountryName={userCountryName}
                currentUserRights={currentUserRights}/>
        </>
    )
}

const AustraliaInnerBlock = ({statusList, callback, data, userCountryName, userData}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        item.name !== 'New Student' ?
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
                                <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                            </li> : null
                    )
                })}
                {countryId == countryIds.australia ?
                    <Suspense fallback={<></>}>
                        <OSHCInsuranceApplicationsBtn userData={userData} userCountryName={userCountryName}/>
                    </Suspense> : null}
            </ul>
        </div>
    )
}

/*const AustraliaOnShoreInnerBlock = ({ statusList, callback, data }) => {
  let { countryId = '', countArr = {} } = data
  return (
    <div className='card-pane-right listing-section w-100' style={{ border: 'none' }}>
      <ul className={'count'}>
        {statusList.map((item, key) => {
          return (
            <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
              <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
              <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}*/
const CanadaOnShoreInnerBlock = ({statusList, callback, data}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
                            <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                            <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const UkInnerBlock = ({statusList, callback, data, userCountryName, userData, currentUserRights}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <>
            <div className='card-pane-right listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' ?
                                <li onClick={() => callback(countryId, item.name)} className={'pointerCls'} key={key}>
                                    <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                    <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                                </li> : null
                        )
                    })}
                </ul>
            </div>
            <UkUniversityRecordCount user={userData} userCountryName={userCountryName}/>

        </>
    )
}
const CanadaInnerBlockNew = ({statusList, callback, data, userCountryName, userData}) => {
    let {countryId = '', countArr = {}} = data

    return (
        <>
            <div className='card-pane-right listing-section w-100' style={{border: 'none', padding: '30px 0'}}>
                <ul className={'count'}>
                    {statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' ?
                                <li onClick={() => callback(countryId, item.name)}
                                    className={'pointerCls pointerCls-pdf'} key={key}

                                >
                                    <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                    <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                                </li> : null
                        )
                    })}
                </ul>
            </div>

            <CanadaUniversityRecordCount user={userData} userCountryName={userCountryName}/>
        </>
    )
}

const AustraliaInnerBlockNew = ({statusList, callback, data, userCountryName, userData}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none', padding: '30px 0'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        item.name !== 'New Student' ?
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls pointerCls-pdf'}
                                key={key}

                            >
                                <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                            </li> : null
                    )
                })}
                {countryId == countryIds.australia ?
                    <Suspense fallback={<></>}>
                        <OSHCInsuranceApplicationsBtn userData={userData} userCountryName={userCountryName}/>
                    </Suspense> : null}
            </ul>
        </div>
    )
}

const CanadaOnShoreInnerBlockNew = ({statusList, callback, data}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none', padding: '30px 0'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        <li onClick={() => callback(countryId, item.name)} className={'pointerCls pointerCls-pdf'}
                            key={key}

                        >
                            <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                            <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const UkInnerBlockNew = ({statusList, callback, data, userCountryName, userData}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right listing-section w-100' style={{border: 'none', padding: '30px 0'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        item.name !== 'New Student' ?
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls pointerCls-pdf'}
                                key={key}

                            >
                                <h5>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h5>
                                <p>{StatusLabel[item.name] ? StatusLabel[item.name] : StatusNameObj[item.name] ? StatusNameObj[item.name] : item.name}</p>
                            </li> : null
                    )
                })}
                <UkUniversityRecordCount user={userData} userCountryName={userCountryName}/>

            </ul>
        </div>
    )
}


export {
    UkCountryCount,
    CanadaCountryCount,
    AustraliaCountryCount,
    InnerBlock,
    InnerBlockNew,
    CanadaInnerBlock,
    AustraliaInnerBlock,
    UkInnerBlock,
    CanadaOnShoreInnerBlock,
    CanadaInnerBlockNew,
    AustraliaInnerBlockNew,
    UkInnerBlockNew,
    CanadaOnShoreInnerBlockNew
}
